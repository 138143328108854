import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  searchInput: Yup.string().required("الرجاء كتابة عنوان المكان"),
});

const initialValues = {
  searchInput: "",
};

const ModalSaveLocation = ({
  showModalSaveLocation,
  handleCloseModalSaveLocation,
}) => {
  // FORM SUBMIT
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm({ values: "" });
  };

  return (
    <>
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModalSaveLocation}
        onHide={handleCloseModalSaveLocation}
        title=""
        newClass={"modal-account"}
      >
        <div className="set-location">
          {/* ========= START FORM INFO  ========== */}
          <div className="form-info">
            <FormField
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <div className="row g-3">
                {/* ========= START COL ======== */}
                <div className="col-12 col-md-12">
                  <InputFiled
                    label="عنوان المكان"
                    name="searchInput"
                    type="search"
                    placeholder=""
                    success
                  />
                </div>
                {/* ========= END COL ======== */}

                {/* ========= START COL ======== */}
                <div className="col-12 col-md-12">
                  {/* ========= START MAP LOCATION ======== */}
                  <div className="map-location">
                    <iframe
                      src="https://www.google.com/maps/d/embed?mid=1n-v4_hQ062poAWrPdnRdfPoo5l4&hl=en&ehbc=2E312F"
                      frameBorder="0"
                      style={{ border: 0 }}
                      aria-hidden="false"
                      title="egypt cairo"
                    />
                  </div>
                  {/* ========= END MAP LOCATION ======== */}
                </div>
                {/* ========= END COL ======== */}
              </div>

              <button
                onClick={handleCloseModalSaveLocation}
                className="btn-main w-100"
              >
                حفظ
              </button>
            </FormField>
          </div>
          {/* ========= END FORM INFO ========== */}
        </div>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ModalSaveLocation;
