import DolloarSignIcon from "./DolloarSign";
import Finance from "./Finance";
import classes from "./SingleUserPage.module.scss";
import * as Yup from "yup";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import imgIcon from "../../assets/images/icons/diamond.svg";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import HeaderPages from "Components/HeaderPages/HeaderPages";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import moment from "moment";
import { Link } from "react-router-dom";
import CustomModal from "Components/CustomModal/CustomModal";
import CheckoutForm from "./Stripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import InputFiled from "Components/Forms/InputForm/InputField";
import FormField from "Components/Forms/FormComponent/FormFiled";
import { toast } from "react-toastify";
import PackagesCard from "Components/PackagesCard/PackagesCard";
import arrowUp from "../../assets/images/icons/increase.png";
import arrowDown from "../../assets/images/icons/decrease.png";
const Profits = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [firstModal, setfirstModal] = useState(false);
  const validationSchema = Yup.object().shape({
    // Translated validation message
    numQuantity: Yup.string().required(t("expectedValueRequired")), // Translated validation message
  });
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(0);
  const [ClientLoading, setClientLoading] = useState(false);
  const [data, setData] = useState([]);
  const [info, setInfo] = useState();
  const [percntage, setPercntage] = useState(0);
  const stripePromise = loadStripe(
    "pk_live_51P5XBHRozbqwhoPVH68Crl9g2kO2lLn8dWnD7zAtlDt05lqA2Why33vUdZdG85z4C6mz4rWz02Puo3vQZ1R4m2Xm00JT6fxYqC"
  );
  const [clientSecret, setclientSecret] = useState("");
  const [popUpImagestate, setpopUpImagestate] = useState(false);
  const [popUpImageValue, setPopUpImageValue] = useState("");
  const popUpImage = (value) => {
    console.log(popUpImagestate);
  };
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setpopUpImagestate(false);
      setPopUpImageValue("");
    }
  });
  const columnDefs = [
    {
      headerName: t("id"),
      field: "id",
      valueFormatter: (params) => {
        return i18n.language === "en"
          ? `${params.value} #`
          : `# ${params.value}`;
      },
      flex: 0.5,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      headerName: t("Image"),
      field: "image",
      cellRenderer: ({ value }) =>
        value === "https://dashboard.buildin.ae/images/default.png" ? (
          t("noImage")
        ) : (
          <img
            src={value}
            width={32}
            height={32}
            onClick={() => {
              popUpImage(value);
              setpopUpImagestate(true);
              setPopUpImageValue(value);
            }}
          />
        ),
      cellStyle: { textAlign: "center" },
      flex: 2,
    },
    {
      headerName: t("Date"),
      field: "created_at",
      cellRenderer: ({ value }) => (
        <div>{moment(value).format("YYYY/MM/DD")}</div>
      ),

      cellStyle: { textAlign: "center" },
      flex: 1,
    },

    {
      headerName: t("Amount"),
      field: "amount",
      valueFormatter: (pramas) => {
        return `${pramas.value} ${i18n.language === "en" ? "AED" : "درهم"}`;
      },
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: t("content"),
      field: "content",
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: t("status"),
      field: "status",
      cellRenderer: ({ value }) => {
        return value === "plus" ? (
          <>
            plus <img src={arrowUp} alt="" />
          </>
        ) : (
          <>
            minus <img src={arrowDown} alt="" />
          </>
        );
      },
      flex: 1,
      cellStyle: { textAlign: "center" },
    },
  ];
  const [amountError, setAmountError] = useState(false);
  useEffect(() => {
    axiosInstance
      .get("/customerfinance")
      .then((res) => {
        setData(res.data.data);
        console.log(res.data);

        setInfo(res.data);
        if (res.data?.current_subscription[0]) {
          const startDate = moment(
            res.data?.current_subscription[0].start_date
          );
          const endDate = moment(res.data?.current_subscription[0].end_date);

          // Define the current date (or any other specific date)
          const currentDate = moment();

          // Ensure the current date is within the range
          if (currentDate.isBefore(startDate) || currentDate.isAfter(endDate)) {
            console.log("Current date is outside the range.");
          } else {
            // Calculate the total duration in milliseconds
            const totalDuration = endDate.diff(startDate);

            // Calculate the duration from the start date to the current date in milliseconds
            const spentDuration = currentDate.diff(startDate);

            // Calculate the percentage of time spent
            const percentageSpent = (spentDuration / totalDuration) * 100;
            setPercntage(percentageSpent);
          }
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {}, [amount]);

  const HandleAmount = (values) => {
    let cleanedStr = [...values.numQuantity]
      .filter((char) => char !== ",")
      .join(""); // Remove commas
    let num = +cleanedStr;
    if (num < 2) {
      setAmountError(true);
      return;
    }
    setAmount(num);
    if (num) {
      console.log("hello");
      setClientLoading(true);
      axios
        .post(
          "https://api.stripe.com/v1/payment_intents",
          {
            amount: num * 100,
            currency: "aed",
            "payment_method_types[]": "card",
          },
          {
            headers: {
              Authorization:
                "Bearer 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",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          console.log(res);
          setclientSecret(res.data.client_secret);
          console.log(res.data.client_secret);
          setTimeout(() => {
            setClientLoading(false);
            setShowModal(true);
            setfirstModal(false);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // This should be your secret key
  const options = {
    clientSecret: clientSecret,
  };
  const handleAfterPay = (id) => {
    console.log("Payment Done");
    axiosInstance
      .post("/rechargeWallet", { amount: amount, transaction_id: id })
      .then((res) => {
        setShowModal(false);
        setAmount(null);
        toast.success(t("Recharge is Done"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {popUpImagestate === true ? (
        <div className={classes.popUpImageParent}>
          <button onClick={() => setpopUpImagestate(false)}>x</button>
          <div className={classes.popUpImagex}>
            <img src={popUpImageValue} alt="" />
          </div>
        </div>
      ) : null}
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <HeaderPages
          titlePage={t("Wallet")}
          routePage={false}
          mainTitlePage={false}
          showMainTitlePage={false}
        />
        <div
          onClick={() => setfirstModal(true)}
          to={"workProject"}
          className="btn-main button-home"
        >
          {t("Charge Wallet")}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className={classes.MainContainer}>
            <div className="row">
              <div className="col-6">
                <div className="TotalFinance">
                  <div>
                    {t("Wallet Balance")}
                    <span>{info?.wallet}</span>
                  </div>
                  <Finance />
                </div>
              </div>
              <div className="col-6">
                <div className="ReadyToPull">
                  <div>
                    {i18n.language === "en"
                      ? info?.current?.package?.translations[0].title
                      : info?.current?.package?.translations[1].title}
                    <div className="d-flex justify-content-between gap-4">
                      <div>
                        {" "}
                        {moment(info?.current?.end_date).diff(
                          info?.current?.start_date,
                          "days"
                        )}{" "}
                        {t("Days left until the subscription expires")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12">
          <div className={classes.MainContainer} style={{ minHeight: "400px" }}>
            <div>
              <h2>{t("past transactions")}</h2>
            </div>
            <div
              className="ag-theme-quartz"
              style={{
                height: "400px",
                minWidth: "1300px",
                overflowX: "auto",
              }}
            >
              <AgGridReact
                enableRtl={i18n.language === "ar"}
                paginationPageSize={10}
                columnDefs={columnDefs}
                rowData={data}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomModal show={showModal} onHide={() => setShowModal(false)}>
        {ClientLoading ? (
          <div>Loading</div>
        ) : (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              appearance: { theme: "flat" },
            }}
          >
            <CheckoutForm amount={amount} handleAfterPay={handleAfterPay} />
          </Elements>
        )}
      </CustomModal>
      <CustomModal
        title={t("Recharge Wallet")}
        show={firstModal}
        onHide={() => setfirstModal(false)}
      >
        <FormField
          initialValues={{
            numQuantity: "",
          }}
          validationSchema={validationSchema}
          onSubmit={HandleAmount}
        >
          <div className="col-12 ">
            <InputFiled
              label={t("Amount")} // Translated label
              name="numQuantity"
              price
              type="text"
              placeholder=""
              success
            />
            {amountError && (
              <div className="text-danger">
                {t("Amount should be more than 2")}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button className="SaveBtnn">{t("Next")}</button>
          </div>
        </FormField>
      </CustomModal>
    </>
  );
};

export default Profits;
