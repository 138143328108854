import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

const customIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
function MapPicker({ onLocationChange, lat, lng }) {
  const [position, setPosition] = useState([lat || 25.2048, lng || 55.2708]); // Default position

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setPosition([lat, lng]);

    onLocationChange({ lat, lng }); // Callback to parent component with new location
  };

  return (
    <MapContainer
      center={position}
      zoom={13}
      
      style={{ height: "400px",maxWidth:'666px' }}
    >
      <ChangeMapView center={position} zoom={13} onMapClick={handleMapClick} />
      <TileLayer

        url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"

      />
      <Marker position={position} icon={customIcon}>
        <Popup>You are here</Popup>
      </Marker>
    </MapContainer>
  );
}

function ChangeMapView({ center, zoom, onMapClick }) {
  const map = useMapEvents({
    click: (e) => {
      onMapClick(e);
    },
    resize:(e)=>{
      console.log(e)
    }
    
  });

  map.setView(center, zoom);
  return null;
}

export default MapPicker;
