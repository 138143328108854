import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PackagesCard.css";
import { ProgressBar } from "react-bootstrap";
import ModalStopOffer from "Components/ModalStopOffer/ModalStopOffer";
import { useState } from "react";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const PackagesCard = ({
  newClass,
  ribbonText,
  isActiveRibbon,
  imgIcon,
  titlePackage,
  textPackage,
  numPricing,
  dateType,
  moneyType,
  iconTypeCheck,
  textIconPricing,
  textButton,
  isActiveProgress,
  textProgressDate,
  textManyDay,
  isActiveBottomInfo,
  iconOne,
  dateInfoBottom,
  textContentBottom,
  iconOne2,
  dateInfoBottom2,
  textContentBottom2,
  iconOne3,
  dateInfoBottom3,
  textContentBottom3,
  isActiveButtonSubscription,
  isActiveButtonCancel,
  isActiveButtonReload,
  handleClick,
  show_in_home,
  show_first,
  projects_apply,
  work_count,
  offers_first,SubscribeAction,
  progressCount=70,
  forWallet
}) => {


  // OEPN ANDCLOSE MODAL STOP OFFER
  const { t } = useTranslation();
  const [showModalStopOffer, setShowModalStopOffer] = useState(false);
  // CLOSE MODAL
  const handleCloseStopOffer = () => {
    setShowModalStopOffer(false);
  };
  // OPEN MODAL
  const handleShowModalStop = (e) => {
    setShowModalStopOffer(true);
  };

  return (
    <>
      <ModalStopOffer
        showModalStopOffer={showModalStopOffer}
        handleCloseStopOffer={handleCloseStopOffer}
        titleHeaderModal={"هل أنت متأكد من إلغاء الإشتراك في هذه الباقة ؟"}
        textModalStop={
          "إلغاء الإشتراك سيمكنك من الإستفادة من المزايا لهذه الباقة خلال فترة الباقة المحددة ولكن لن يتم تجديدها.. الإستمرار ؟"
        }
        titleButtonCancel={"نعم , إلغي"}
        titleButtonBack={"الرجوع"}
      />
      {/* ============ START INFO PACKAGE ========== */}
      <div className={`info-package position-relative ${newClass}`}>
        {isActiveRibbon && (
          <div className="ribbon">
            <span className="title">{ribbonText}</span>
          </div>
        )}

        {/* ========= START HEADER PACKAGE ========== */}
        <div className="header-package d-flex align-items-center gap-2">
          <div className="icon">
            <img src={imgIcon} alt="img icon" />
          </div>
          <h2 className="title">{titlePackage}</h2>
        </div>
        {/* ========= END HEADER PACKAGE ========== */}
        {/* ========= START INFO DETAILS PACKAGE =========== */}
        <div className="info-details-package">
   {
    !forWallet &&     <>
      <p className="text">{textPackage}</p>
           <div className="price-package">
             <h2 className="num-price">{numPricing}</h2>
             {moneyType}  {dateType}
           </div>
           <div className="list-details-package">
             <ul className="list-package p-0 m-0">
               <li className="list-li-one active">
                 <span className="text">
                   {t("numberOfProjectsOffers")}: { projects_apply}
                    {/* {t("infitite")} */}
                 </span>
               </li>
               {/* <li className="list-li-one active">
                 <span className="text">
                   {t("numberOfProjectsWork")} :   
  { work_count}
               
                 </span>
               </li> */}
 
               <li className={`list-li-one ${ "active"}`}>
                 <div className="icon-check-package">
                   {" "}
                   <FontAwesomeIcon icon= { show_first? iconTypeCheck : faXmark} />
                 </div>
                 <span className="text">{t("ShowProjectsfirst")}</span>
               </li>
               <li className={`list-li-one ${ "active"}`}>
                 <div className="icon-check-package">
                   {" "}
                   <FontAwesomeIcon icon={offers_first ?  iconTypeCheck : faXmark } />
                 </div>
                 <span className="text">{t("ShowOffersFirst")}</span>
               </li>
               <li className={`list-li-one ${ "active"}`}>
                 <div className="icon-check-package ">
                   {" "}
                   <FontAwesomeIcon icon={ show_in_home ?iconTypeCheck : faXmark} />
                 </div>
                 <span className="text">{t("homePageShow")}</span>
               </li>
             </ul>
           </div>
           </>
   }
  


          {isActiveProgress && (
            <div className="info-progress">
              <ProgressBar striped variant="success" now={progressCount} />
              <div className="info-text-progress d-flex justify-content-between align-itmes-center">
                <h2 className="title">{textProgressDate}</h2>
                <h2 className="title">{textManyDay}</h2>
              </div>
            </div>
          )}

          {isActiveBottomInfo && (
            <>
              {/* =========== START CENTER INFO =========== */}
              <div className="center-info d-flex align-items-center justify-content-between flex-wrap  mt-3 gap-4">
                <div className="info-content-one d-flex align-items-center gap-3">
                  <img src={iconOne} alt="icon dollar" />
                  <div className="text-info">
                    <h2 className="date-num">{dateInfoBottom}</h2>
                    <p className="text-cont">{textContentBottom}</p>
                  </div>
                </div>
                <div className="info-content-one d-flex align-items-center gap-3">
                  <img src={iconOne2} alt="icon dollar" />
                  <div className="text-info">
                    <h2 className="date-num">{dateInfoBottom2}</h2>
                    <p className="text-cont">{textContentBottom2}</p>
                  </div>
                </div>
                <div className="info-content-one d-flex align-items-center gap-3">
                  <img src={iconOne3} alt="icon dollar" />
                  <div className="text-info">
                    <h2 className="date-num">{dateInfoBottom3}</h2>
                    <p className="text-cont">{textContentBottom3}</p>
                  </div>
                </div>
              </div>
              {/* =========== START CENTER INFO =========== */}
            </>
          )}
          {isActiveButtonSubscription && (
            <button  className="btn-main w-100" onClick={SubscribeAction}>
              {textButton}
            </button>
          )}
          {isActiveButtonCancel && (
            <button
              onClick={handleShowModalStop}
              className="btn-main btn-danger-cancel w-100"
            >
              {textButton}
            </button>
          )}
          {isActiveButtonReload && (
            <button className="btn-main button-home w-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M12.5019 22.7501C17.7019 22.7501 21.9219 18.5201 21.9219 13.3301C21.9219 11.4601 21.3719 9.65014 20.3319 8.09014C20.1019 7.75014 19.6319 7.65014 19.2919 7.88014C18.9519 8.11014 18.8519 8.58014 19.0819 8.92014C19.9519 10.2201 20.4119 11.7501 20.4119 13.3201C20.4119 17.6901 16.8619 21.2401 12.4919 21.2401C8.12187 21.2401 4.57187 17.6901 4.57187 13.3201C4.57187 8.95014 8.13188 5.40014 12.5019 5.40014C13.4219 5.40014 14.3219 5.53014 15.1719 5.79014C15.5719 5.91014 15.9919 5.69014 16.1119 5.29014C16.2319 4.89014 16.0119 4.47014 15.6119 4.35014C14.6119 4.05014 13.5719 3.89014 12.5019 3.89014C7.30188 3.89014 3.08187 8.12014 3.08187 13.3101C3.08187 18.5001 7.30188 22.7501 12.5019 22.7501Z"
                  fill="#002C63"
                />
                <path
                  d="M16.6296 6.07012C16.7996 6.07012 16.9796 6.01012 17.1196 5.89012C17.4396 5.61012 17.4696 5.14012 17.1996 4.83012L14.3096 1.51012C14.0396 1.20012 13.5596 1.16012 13.2496 1.44012C12.9396 1.71012 12.9096 2.19012 13.1796 2.50012L16.0696 5.81012C16.2196 5.98012 16.4296 6.07012 16.6296 6.07012Z"
                  fill="#002C63"
                />
                <path
                  d="M13.2604 8.53013C13.4104 8.53013 13.5704 8.48013 13.7004 8.39013L17.0804 5.92013C17.4104 5.68013 17.4804 5.21013 17.2404 4.88013C17.0004 4.54013 16.5304 4.47013 16.1904 4.71013L12.8204 7.17013C12.4904 7.41013 12.4104 7.88013 12.6604 8.22013C12.8004 8.43013 13.0304 8.53013 13.2604 8.53013Z"
                  fill="#002C63"
                />
              </svg>
              {textButton}
            </button>
          )}
        </div>
        {/* ========= END INFO DETAILS PACKAGE =========== */}
      </div>
      {/* ============ END INFO PACKAGE ========== */}
    </>
  );
};

export default PackagesCard;
