import About from "../About/About";
import CardsHeaderBanner from "../CardsHeaderBanner/CardsHeaderBanner";
import CustomerReviews from "../CustomerReviews/CustomerReviews";
import DownloadApp from "../DownloadApp/DownloadApp";
import FeatureApp from "../FeatureApp/FeatureApp";
import LatestAdditions from "../LatestAdditions/LatestAdditions";
import Supporters from "../Supporters/Supporters";
import VideoBannerCounter from "../VideoBannerCounter/VideoBannerCounter";

const Main = () => {
  return (
    <main>
      <CardsHeaderBanner />
      <About />
      <VideoBannerCounter />
      <FeatureApp />
      <DownloadApp />
      <Supporters />
      <CustomerReviews />
      <LatestAdditions />
    </main>
  );
};

export default Main;
