import HeaderPages from "Components/HeaderPages/HeaderPages";
import RecentCardProject from "Pages/Home/RecentProjects/RecentCardProject";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import "./AllProjects.css";
import SideBarFilter from "Components/SideBarFilter/SideBarFilter";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import { set } from "rsuite/esm/utils/dateUtils";
import NoResults from "Components/NotFound/noResults";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const AllProjects = () => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [city, setCity] = useState();
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    axiosInstance
      .get("/allprojects", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data.projects);
        setData(res.data.projects);
      });
  }, []);

  useEffect(() => {
    let newFilteredData = [];
    if (data) {
      if (data) {
        newFilteredData = [...data];
      }
      if (searchText != "") {
        newFilteredData = data.filter((item) => {
          return item.title.toLowerCase().includes(searchText.toLowerCase());
        });
      }
      console.log(city);
      if (city) {
        newFilteredData = newFilteredData.filter((item) => {
          return item.city.toLowerCase() == city.label.toLowerCase();
        });
        console.log(newFilteredData);
      }

      setFilteredData(newFilteredData);
    }
  }, [searchText, city, data]);
  return (
    <>
      <HelmetInfo titlePage={t("allProjectsTitle")} />

      <div className="all-projects-page">
        <HeaderPages
          titlePage={t("allProjectsTitle")}
          routePage={false}
          mainTitlePage={false}
          showMainTitlePage={false}
        />
        <div className="main-projects-page">
          <div className="row g-3">
            <div className="col-12  col-md-4">
              <SideBarFilter
                setSelectedCityes={setCity}
                searchContractorsByName={setSearchText}
                isActive={false}
              />
            </div>
            <div className="col-12   col-md-8">
              <div className="all-projects-content">
                <div className="row g-3">
                  {filteredData?.length > 0 ? (
                    filteredData?.map((item) => {
                      return (
                        <div className="col-12" key={item.id}>
                          <RecentCardProject
                            img={item.images || img}
                            projectId={item.id}
                            altImg={t("imgAlt")}
                            badgeText={item.category}
                            titleCard={item.title}
                            titleAddress={`${item.city} ,${item.region}`}
                            iconDollar={iconDollar}
                            priceCard={item.price}
                            typePrice={t("currency")}
                            textPrice={t("expectedValue")}
                            iconCalendar={iconCalendar}
                            dateCard={item.end_date}
                            textInfoDate={t("expectedDeliveryDate")}
                            numWatch={item.views}
                            textWatch={t("views")}
                            numOffer={item.offers_count}
                            textOffer={t("offers")}
                            numDay={item.since}
                            routeCard={`/productDetailsPage/${item.id}`}
                            Favorite={item.is_favorite}
                            jopType={item.type}
                            typeofsupply={item.category}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="recent-project-one card-one-tab">
                      <NoResults text={t("noProjects")} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjects;
