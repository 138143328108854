import img1 from "../../../assets/LandingPageImages/icon2/01.png";
import img2 from "../../../assets/LandingPageImages/icon2/02.png";
import img3 from "../../../assets/LandingPageImages/icon2/03.png";
import img4 from "../../../assets/LandingPageImages/icon2/04.png";
import img5 from "../../../assets/LandingPageImages/icon2/05.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./Supporters.css";
const Supporters = () => {
  const imgSupporters = [
    { id: 0, img: img1 },
    { id: 1, img: img2 },
    { id: 2, img: img3 },
    { id: 3, img: img4 },
    { id: 4, img: img5 }
  ];

  const options = {
    nav: false,
    dots: false,
    loop: true,
    margin: 12,
    stagePadding: 0,
    smartSpeed: 2500,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 6
      }
    }
  };
  return (
    <>
      {/* ============= START SECTION SUPPORTERS  =============== */}
      <section className="supporters" id="supporters">
        {/* ============ START CONTAINER ============ */}
        <div className="container">
          {/* ========= START ALL SUPPORTERS ========== */}
          <div className="all-supporters" data-aos="fade-down">
            <OwlCarousel className="owl-theme" {...options}>
              {imgSupporters.map((itemCard) => {
                return (
                  <div className="item" key={itemCard.id}>
                    {/* ============ START SUPPORT ONE ========== */}
                    <div className="support-one">
                      <img
                        src={itemCard.img}
                        alt="img icon"
                        loading="lazy"
                        width="120px"
                        height="81px"
                      />
                    </div>
                    {/* ============ END SUPPORT ONE ========== */}
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
          {/* ========= END ALL SUPPORTERS ========== */}
        </div>
        {/* ============ END CONTAINER ============ */}
      </section>
      {/* ============= END SECTION SUPPORTERS =============== */}
    </>
  );
};
export default Supporters;
