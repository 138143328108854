import IconHeaderForm from "Components/Forms/IconHeaderFormRoute/IconHeaderForm";
import { faArrowRight, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import HeaderSteps from "../HeaderSteps/HeaderSteps";
import LogoImageForm from "Components/Forms/LogoForm/LogoImageForm";
import ImageComponent from "Components/Forms/ImageLogin/ImageComponent";
import imageUser_2 from "../../../assets/images/main/02.png";
import imageUser_1 from "../../../assets/images/main/03.png";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginBottomInfo from "../Login/LoginBottomInfo";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useNavigate } from "react-router-dom";

// Import i18n library and translations
import { useTranslation } from "react-i18next";


const LoginStepOne = () => {
  const [checkedUser, setCheckedUser] = useState(false);
  const [checkedUser_2, setCheckedUser_2] = useState(false);
  const [error, setError] = useState(false);
  const router = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <>
      <HelmetInfo titlePage={t("loginTitle")} />

      <div className="login-form padding-xy">
        {/* ========= START LOGO LOGIN ======== */}
        <div className="logo-top">
          <LogoImageForm />
        </div>
        {/* ========= END LOGO LOGIN ======== */}
        {/* ========= START CONTAINER =========== */}
        <div className="container-fluid">
          {/* ======== START ALL LOGIN FORM =========== */}
          <div className="all-Login-form">
            {/* ========== START ROW =========== */}
            <div className="row g-3 align-items-center">
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO FORM LOGIN =========== */}
                <div className="info-form-login position-relative">
                  {/* ========= START ICON CLOSE ========= */}
                  <IconHeaderForm
                    routerPage={"/LoginForm"}
                    iconHeader={faArrowRight}
                  />
                  {/* ========= END ICON CLOSE ========= */}
                  {/* ========= START ALL FORM LOG ======== */}
                  <div className="all-form-log">
                    {/* ========== START HEADER FORM LOG ========= */}
                    <HeaderSteps />
                    {error ? (
                      <div className="alert alert-danger" role="alert">
                        {t("selectAccountType")}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="all-users">
                      <div className="row g-3">
                        <div className="col-12 col-sm-6">
                          {/* ======= START USER ONE ======== */}
                          <div
                            className={`user-one ${
                              checkedUser === true ? "active" : ""
                            }`}
                            onClick={() => {
                              setCheckedUser_2(false);
                              setCheckedUser(true);
                            }}
                          >
                            <img
                              src={imageUser_1}
                              alt="user image1"
                              className="object-fit-cover"
                            />
                            <h2 className="title">{t("client")}</h2>
                            {checkedUser === true ? (
                              <div className="icon-check">
                                <FontAwesomeIcon icon={faCheckDouble} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* ======= END USER ONE ======== */}
                        </div>
                        <div className="col-12 col-sm-6">
                          {/* ======= START USER ONE ======== */}
                          <div
                            className={`user-one ${
                              checkedUser_2 === true ? "active" : ""
                            }`}
                            onClick={() => {
                              setCheckedUser(false);
                              setCheckedUser_2(true);
                            }}
                          >
                            <img
                              src={imageUser_2}
                              alt="user image1"
                              className="object-fit-cover"
                            />
                            <h2 className="title">{t("company")}</h2>
                            {checkedUser_2 === true ? (
                              <div className="icon-check">
                                <FontAwesomeIcon icon={faCheckDouble} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {/* ======= END USER ONE ======== */}
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        if (checkedUser === true) {
                          router("/register");
                        } else if (checkedUser_2 === true) {
                          router("/chooseSpecialty");
                        } else {
                          setError(true);
                        }
                      }}
                      type="submit"
                      className="btn btn-main btn-submit w-100 mt-5"
                    >
                      {t("continue")}
                    </button>
                    {/* ========== START BOTTOM FORM ========= */}
                    <LoginBottomInfo />
                    {/* ========== END BOTTOM FORM ========= */}
                  </div>
                  {/* ========= END ALL FORM LOG ======== */}
                </div>
                {/* ========== END INFO FORM LOGIN =========== */}
              </div>
              {/* =========== END COL ======== */}
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO LOGIN ========== */}
                <div className="info-login position-relative">
                  {/* ========= START IMAGE LOGIN ======= */}
                  <ImageComponent />
                  {/* ========= END IMAGE LOGIN ======= */}
                </div>
                {/* ========== END INFO LOGIN ========== */}
              </div>
              {/* =========== END COL ======== */}
            </div>
            {/* ========== END ROW =========== */}
          </div>
          {/* ======== END ALL LOGIN FORM =========== */}
        </div>
        {/* ========= END CONTAINER =========== */}
      </div>
    </>
  );
};

export default LoginStepOne;
