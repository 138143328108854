import logo from "../../../assets/LandingPageImages/logo/logoWhite.png";
import langAr from "../../../assets/LandingPageImages/lang/ar.png";
import iconDownload from "../../../assets/LandingPageImages/icons/import.svg";
import "./NavBar.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LanguageSwitcher from "Components/Languages/LanguageSwitcher";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const NavBar = () => {
  // FIXED TOP NAVBAR
  const {t}=useTranslation()
  const [isMenuFixed, setMenuFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      const scrollTop = window.scrollY;
      const shouldBeFixed = scrollTop > 100; // Change 100 to the scroll position where you want the menu to become fixed
      // Update state to trigger the fixed or unfixed state of the menu
      setMenuFixed(shouldBeFixed);
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
  }, []); // Runs only once on mount
  const token = useSelector((state) => state.auth.token);
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg position-fixed HomeNav top-0   z-3 w-100  ${
          isMenuFixed ? "menu-fixed" : ""
        }`}
      >
        <div className="nav-menu-bar container-fluid container-xl">
          <a className="navbar-brand" data-aos="fade-left" href="/">
            <img alt="logo" height="57px" src={logo} width="160px" />
          </a>
          <div className="main-left-nav-toggle d-flex align-items-center gap-3">
            <button
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-aos="fade-right"
              data-bs-target="#navbarSupportedContent"
              data-bs-toggle="collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="lang">
              <LanguageSwitcher />
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav gap-3 mb-lg-0 me-auto"
              data-aos="fade-down"
            >
              <li className="nav-item">
                <a
                  aria-current="page"
                  className="nav-link active font-18 text-font-500-white"
                  href="#about-app"
                >
                 {t("about_app")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link font-18 text-font-500-white"
                  href="#app-feature"
                >
                 {t("app_features")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link font-18 text-font-500-white"
                  href="#customer-reviews"
                >
          {t("customer_reviews")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link font-18 text-font-500-white"
                  href="#contact-us"
                >
             {t("contact_us")}
                </a>
              </li>
            </ul>
            <div className="main-nav-left d-flex align-items-center gap-3 btn-nav">
              <a
                className="btn-main-01"
                data-aos="fade-right"
                href="#download-app"
              >
          {t("download app")}
                <img alt="icon" height="24px" src={iconDownload} width="24px" />
              </a>
              <Link
                className="btn-main-01 btn-transparent-01"
                data-aos="fade-right"
                to={token?"/home":"/loginForm"}
              >
                {token ? t("control_panel") : t("login")}
              </Link>
              <div className="lang">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
