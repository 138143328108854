import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useLanguage } from "./LanguageContext";
import flagAr from "../../assets/images/lang/ar.svg";
import flagEn from "../../assets/images/lang/en.svg";

const LanguageSwitcher = () => {
  const { currentLanguage, setCurrentLanguage } = useLanguage();
  const { i18n } = useTranslation(); // Initialize useTranslation hook

  const handleClick = () => {
    // Toggle language between 'ar' and 'en'
    const newLanguage = currentLanguage === "ar" ? "en" : "ar";
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Change language using i18n library
  };

  return (
    <div className="language-switcher" onClick={handleClick}>
      {currentLanguage === "en" ? (
        <div className="lang-user text-decoration-none  d-flex align-items-center gap-2">
          <img src={flagAr} alt="userImage" width={"30px"} height={"30px"} />
          <span className="text-lang">العربية</span>
        </div>
      ) : (
        <div className="lang-user text-decoration-none  d-flex align-items-center gap-2">
          <img src={flagEn} alt="userImage" width={"30px"} height={"30px"} />
          <span className="text-lang">En</span>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
