import React from "react";
import "./NotFound.css";
import Lottie from "lottie-react";
import notFoundPage from "../../assets/images/404/404.json";
import { Link } from "react-router-dom";
import iconHome from "../../assets/images/iconsSideBar/home-2.svg";

const NotFound = () => {
  return (
    <div className="not-found-page d-flex align-items-center  justify-content-center vh-100 flex-column">
      <div className="animtion-lottie">
        <Lottie animationData={notFoundPage} />
      </div>
      <Link to={"/"} className="btn-main">
        <img src={iconHome} alt="icon Home" />
        الصفحة الرئيسية
      </Link>
    </div>
  );
};

export default NotFound;
