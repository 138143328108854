import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import ModalSuccess from "Components/Success/ModalSuccess";
import { useState } from "react";
import * as Yup from "yup";
import successIcon from "../../assets/images/gif/success.gif";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios";
const validationSchema = Yup.object().shape({
  textAreaDescription: Yup.string()
    .min(10, "المحتوى يجب ان يكون على الاقل 10 كلمات")
    .max(200, "المحتوى يجب ان يكون اقل من 200 كلمة")
    .required("الرجاء إدخال تفاصيل الرسالة."),

  inputTitleMessage: Yup.string().required("الرجاء إدخال عنوان الرسالة"),
});

const ContactModal = ({ showModal, handleCloseModal }) => {
  const { t } = useTranslation();
  const initialValues = {
    inputTitleMessage: "",
    textAreaDescription: "",
  };

  // OEPN ANDCLOSE MODAL
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  // CLOSE MODAL
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
    handleCloseModal();
  };
  // OPEN MODAL
  const handleOpenModalSuccess = () => {
    setShowModalSuccess(true);
  };
  const handleSubmit = (values, { resetForm }) => {
    const isEmpty = !values.inputTitleMessage && !values.textAreaDescription;
    if (resetForm) {
      resetForm();
    } else {
      console.error("resetForm is not defined in the formik context");
    }
    if (isEmpty) {
      console.log("PLEASW ADD VALUE INUPUT");
    } else {
      handleCloseModal();

      axiosInstance
        .post("/contact", {
          title: values.inputTitleMessage,
          message: values.textAreaDescription,
        })
        .then((res) => {
          handleOpenModalSuccess();
        });
    }
  };
  return (
    <>
      <ModalSuccess
        showModalSuccess={showModalSuccess}
        handleCloseModalSuccess={handleCloseModalSuccess}
        titleInfo={t("The message has been sent successfully!")}
        isAddText={true}
        textInfo={t(
          "The message has been sent and we will contact you as soon as possible."
        )}
        titleButton={t("done")}
        buttonRoute={false}
        routeDir={false}
        successIcon={successIcon}
        isActiveMainButton={true}
      />
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={t("contact Us")}
        newClass={"modal-contact"}
      >
        <div className="info-contact">
          <p className="text">
            {t(
              "If you have any questions or encounter any problem, contact the BUILD IN team We will respond to your inquiry as soon as possible!"
            )}
          </p>
          {/* ========= START FORM INFO  ========== */}
          <div className="form-info-contact mt-4">
            <FormField
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <div className="row g-3">
                {/* ========= START COL ======== */}
                <div className="col-12 col-md-12">
                  <InputFiled
                    label={t("Message Title")}
                    name="inputTitleMessage"
                    type="text"
                    placeholder=""
                    success
                  />
                </div>
                {/* ========= END COL ======== */}
                {/* ========= START COL ======== */}
                <div className="col-12 col-md-12">
                  <TextAreaInput
                    label=""
                    name="textAreaDescription"
                    type="text"
                    placeholder={t("message Detials")}
                    success
                  />
                </div>
                {/* ========= END COL ======== */}
              </div>
              <button
                className="btn-main w-100"
                type={"submit"}
                onChange={handleSubmit}
              >
                {t("Send")}
              </button>
            </FormField>
          </div>
          {/* ========= END FORM INFO ========== */}
        </div>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ContactModal;
