import SectionTitle from "../SectionTitle/SectionTitle";
import imgMobileApp from "../../../assets/LandingPageImages/main/02.png";
import appStore from "../../../assets/LandingPageImages/icons/appstore.svg";
import googlePlay from "../../../assets/LandingPageImages/icons/googleplay.svg";
import { useTranslation } from "react-i18next";

import "./DownloadApp.css";
import ModalDownloadApp from "../ModalDownloadApp/ModalDownloadApp";

const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <>
      <ModalDownloadApp />
      <section
        className="download-app padding position-relative"
        id="download-app"
      >
        <div className="container">
          <div className="all-download-app">
            <div className="row g-3">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="info-download-app mb-4" data-aos="fade-down">
                  <SectionTitle
                    titleSection={t("Download the App")}
                    text={t(
                      "Download the app now and take a step towards realizing your dream project"
                    )}
                    dataAnimationType={false}
                  />
                  <p className="text">
                    {t(
                      "Download the app now and benefit from all the advantages and services it offers. You will have the opportunity to access a wide range of professional contractors, experienced consultants, and trusted suppliers in the field of contracting and construction."
                    )}
                  </p>
                  <p className="text">
                    {t(
                      "Downloading the app will allow you to take full advantage of a wide range of services and features available easily on your mobile phone. Download the app now and get ready to achieve successful construction projects and contract with the best expertise in the field."
                    )}
                  </p>
                  <div className="download-app-images d-flex align-items-center gap-2 flex-wrap justify-content-center justify-content-md-start mt-3">
                    <a
                      target="_blank"
                      className="btn-image-app"
                      href="https://apps.apple.com/us/app/build-in/id6499343075"
                    >
                      <img src={appStore} alt="img app" loading="lazy" />
                    </a>
                    <a
                      target="_blank"
                      className="btn-image-app"
                      href="https://play.google.com/store/apps/details?id=com.syntech.buildin"
                    >
                      <img src={googlePlay} alt="img app" loading="lazy" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="image-app" data-aos="fade-up">
                  <img
                    src={imgMobileApp}
                    className="w-100 h-100 object-fit-cover"
                    alt="img app"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DownloadApp;
