import FormField from "Components/Forms/FormComponent/FormFiled";
import WorkSteps from "./WorkSteps/WorkSteps";
import WorkComponent from "./WorkComponent/WorkComponent";
import * as Yup from "yup";
import InputFiled from "Components/Forms/InputForm/InputField";
import iconLocation from "../../assets/images/icons/location.svg";
import HeaderTop from "./HeaderTopNav/HeaderTop";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useNavigate } from "react-router-dom";
const validationSchema = Yup.object().shape({
  location: Yup.string().required("حدد موقعك"),
  email: Yup.string().required("ادخل البريد الالكترونى الخاص  بالعمل"),
  phone: Yup.string().required("رقم الموبايل الخاص بالعمل")
});

const initialValues = {
  location: "",
  email: "",
  phone: ""
};

const ContactInformation = () => {
  // FORM SUBMIT
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm({ values: "" });
  };

  const navigate = useNavigate();

  return (
    <>
      <HelmetInfo titlePage={"معلومات التواصل"} />

      <div className="work-information">
        {/* ========= START CONTAINER ========= */}
        <div className="container-fluid container-md">
          {/* ========= START ALL WORK INFORATION ========= */}
          <div className="all-work-information">
            <HeaderTop />

            {/* ============ START MAIN ========== */}
            <main>
              {/* ============ START ALL MAIN ========== */}
              <div className="all-main">
                {/* ========== START HEADER TOP ======= */}
                <WorkComponent />
                {/* ========== END HEADER TOP ======= */}
                <WorkSteps
                  addActiveStep1={"active"}
                  addActiveStep2={"active"}
                  addActiveStep3={"active"}
                  activeText={"activeText"}
                />

                {/* ========= START FORM INFO  ========== */}
                <div className="form-info">
                  <FormField
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <div className="row g-3">
                      {/* ========= START COL ======== */}
                      <div className="col-12">
                        <div className="input-pass position-relative">
                          <InputFiled
                            label="عنوان المكان"
                            name="location"
                            type="text"
                            placeholder=""
                            success
                          />
                          <div className="icon-svg-local">
                            <img src={iconLocation} alt="icon location" />
                          </div>
                        </div>
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-6">
                        <InputFiled
                          label="البريد الالكترونى الخاص بالعمل *"
                          name="email"
                          type="email"
                          placeholder=""
                          success
                        />
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-6">
                        <InputFiled
                          label="رقم الموبايل الخاص بالعمل *"
                          name="phone"
                          type="tel"
                          placeholder=""
                          success
                        />
                      </div>
                      {/* ========= END COL ======== */}
                    </div>
                    <button
                      className="btn-main w-100"
                      onClick={() => {
                        navigate("/locateCompany");
                      }}
                    >
                      حفظ
                    </button>
                  </FormField>
                </div>
                {/* ========= END FORM INFO ========== */}
              </div>
              {/* ============ START ALL MAIN ========== */}
            </main>
            {/* ============ END MAIN ========== */}
          </div>
          {/* ========= END ALL WORK INFORATION ========= */}
        </div>
        {/* ========= END CONTAINER ========= */}
      </div>
    </>
  );
};

export default ContactInformation;
