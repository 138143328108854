import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { productApi } from "../Redux/productsApi";
import authSlice from "./auth";
export const store = configureStore({
  reducer: {
    [productApi.reducerPath]: productApi.reducer,
    auth:authSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productApi.middleware)
});

setupListeners(store.dispatch);
