import { useLottie } from "lottie-react";
import NoData from "../../assets/NoDataFound.json";
import { useTranslation } from "react-i18next";
const NoResults = ({ children, text }) => {
  const { t } = useTranslation();
  const options = {
    animationData: NoData,
    loop: true,
    style: { width: "300px", height: "300px" },
  };
  const { View } = useLottie(options);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {View}
      <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <p
          style={{
            fontSize: "22px",
            margin: "0px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {" "}
          {t(text)}
        </p>

        {children}
      </div>
    </div>
  );
};

export default NoResults;
