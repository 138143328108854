import CardsInfo from "Pages/Home/CardsInfo/CardsInfo";
import CenterContent from "./CenterContent";
import EndContent from "./EndContent";
import "./Reports.css";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    axiosInstance
      .get("/web/reports", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <HelmetInfo titlePage={"التقارير"} />

      <main>
        {/* ========= START ALL REPORTS ========== */}
        <div className="all-reports">
          {/* ======== START CARDS INFO ========= */}
          <CardsInfo
            projectNumb={data?.projects_chart?.all}
            offersnum={data?.offers}
            views={data?.views}
          />
          {/* ======== END CARDS INFO ========= */}
          {/* ======== START CENTER CONTENT INFO ========= */}
          <CenterContent
            charts={data?.projects_chart}
            latest_chats={data?.latest_chats}
          />
          {/* ======== END CENTER CONTENT INFO ========= */}
          <EndContent projects={data?.projects} />
        </div>
        {/* ========= END ALL REPORTS ========== */}
      </main>
    </>
  );
};

export default Reports;
