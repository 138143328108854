import { Helmet } from "react-helmet-async";
import "./Projects.css";
import { Outlet } from "react-router-dom";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const MyProjects = () => {
  return (
    <>
      <HelmetInfo titlePage={"مشاريعي"} />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default MyProjects;
