import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./Archives.css";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import RecentCardProject from "Pages/Home/RecentProjects/RecentCardProject";
import CardTab from "Components/CardTab/CardTab";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
import CardSkelaton from "Components/Skelaton/CardSkelaton";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import { useSelector } from "react-redux";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const Archives = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const [providers, setProviders] = useState([]);
  const [badgeView, setBadgeView] = useState(3);
  const userType = useSelector((state) => state.auth.type);

  useEffect(() => {
    axiosInstance
      .get("/favourite", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setLoading(false);
        setProjects(res.data.projects);
        setContractor(res.data.fav_contractor);
        setAdvisor(res.data.fav_advisor);
        setProviders(res.data.fav_providers);
      });
  }, []);

  return (
    <>
      <HelmetInfo titlePage={t("archivesTitle")} />

      <main>
        <div className="all-archives margin-top-content">
          <div className="tabs-content">
            <Tabs
              defaultActiveKey= {userType!="customer" ?"projects" : "profile"}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {
                userType!="customer" &&(
                  <Tab eventKey="projects" title={t("projectsTab")}>
                  <div className="all-recent-projects mt-4">
                    <div className="row g-3">
                      {loading ? (
                        [1, 2, 3].map((item) => <CardSkelaton key={item} />)
                      ) : projects.length ? (
                        projects.slice(badgeView - 3, badgeView).map((item) => (
                          <div
                            className="col-12 col-lg-12 col-xl-6"
                            key={item.id}
                          >
                            <div className="card-details-pro">
                              <RecentCardProject
                                img={img}
                                projectId={item.id}
                                altImg={t("imgAlt")}
                                badgeText={item?.category}
                                titleCard={item?.title}
                                titleAddress={`${item.city} , ${item.region}`}
                                iconDollar={iconDollar}
                                priceCard={item.price}
                                typePrice={t("currency")}
                                textPrice={t("expectedValue")}
                                iconCalendar={iconCalendar}
                                dateCard={item.end_date}
                                textInfoDate={t("expectedDeliveryDate")}
                                numWatch={item.views}
                                textWatch={t("watch")}
                                numOffer={item.offers_count}
                                textOffer={t("offers")}
                                numDay={t("remainingDays")}
                                routeCard={`/productDetailsPage/${item.id}`}
                                is_favorite={item.is_favorite}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoResults text={"noProjects"} />
                      )}
                    </div>
                  </div>
                </Tab>
                )
              }
          
              <Tab eventKey="profile" title={t("contractorsTab")}>
                <div className="tab-cards tabs-content-cards">
                  <div className="all-cards-experts">
                    <div className="row g-3">
                      {loading ? (
                        [1, 2, 3].map((item) => <CardSkelaton key={item} />)
                      ) : contractor.length ? (
                        contractor
                          .slice(badgeView - 3, badgeView)
                          .map((item) => (
                            <div
                              className="col-12 col-lg-12 col-xl-6"
                              key={item.id}
                            >
                              <div className="card-details-pro">
                                <CardTab
                                  imgCard={item.image}
                                  titleCard={item.name}
                                  productId={item.id}
                                  numRate={item?.rate}
                                  textInfoBottom={t("activity")}
                                  titleInfoBottom={t("activityTitle")}
                                  textInfoBottom2={t("emirate")}
                                  titleInfoBottom2={item.city}
                                  textInfoBottom3={t("region")}
                                  titleInfoBottom3={item.region}
                                  routeCard={`/theExpert/${item.id}`}
                                  is_favorite={item.is_favorite}
                                />
                              </div>
                            </div>
                          ))
                      ) : (
                        <NoResults text={"noContractors"} />
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile-2" title={t("advisorsTab")}>
                <div className="tab-cards tabs-content-cards">
                  <div className="all-cards-experts">
                    <div className="row g-3">
                      {loading ? (
                        [1, 2, 3].map((item) => <CardSkelaton key={item} />)
                      ) : advisor.length ? (
                        advisor.slice(badgeView - 3, badgeView).map((item) => (
                          <div
                            className="col-12 col-lg-12 col-xl-6"
                            key={item.id}
                          >
                            <div className="card-details-pro">
                              <CardTab
                                productId={item.id}
                                imgCard={item.image}
                                titleCard={item.name}
                                numRate={item?.rate}
                                textInfoBottom={t("activity")}
                                titleInfoBottom={t("activityTitle")}
                                textInfoBottom2={t("emirate")}
                                titleInfoBottom2={item.city}
                                textInfoBottom3={t("region")}
                                titleInfoBottom3={item.region}
                                routeCard={`/theExpert/${item.id}`}
                                is_favorite={item.is_favorite}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoResults text={"noAdvisors"} />
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile-3" title={t("providersTab")}>
                <div className="tab-cards tabs-content-cards">
                  <div className="all-cards-experts">
                    <div className="row g-3">
                      {loading ? (
                        [1, 2, 3].map((item) => <CardSkelaton key={item} />)
                      ) : providers.length ? (
                        providers
                          .slice(badgeView - 3, badgeView)
                          .map((item) => (
                            <div
                              className="col-12 col-lg-12 col-xl-6"
                              key={item.id}
                            >
                              <div className="card-details-pro">
                                <CardTab
                                  imgCard={item.image}
                                  productId={item.id}
                                  titleCard={item.name}
                                  numRate={item?.rate}
                                  textInfoBottom={t("activity")}
                                  titleInfoBottom={t("activityTitle")}
                                  textInfoBottom2={t("emirate")}
                                  titleInfoBottom2={item.city}
                                  textInfoBottom3={t("region")}
                                  titleInfoBottom3={item.region}
                                  routeCard={`/theExpert/${item.id}`}
                                  is_favorite={item.is_favorite}
                                />
                              </div>
                            </div>
                          ))
                      ) : (
                        <NoResults text={"noProviders"} />
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </>
  );
};

export default Archives;
