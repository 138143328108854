import { useTranslation } from "react-i18next";
import imgFooter from "../../assets/LandingPageImages/main/footer.jpeg";
import logoFooter from "../../assets/LandingPageImages/logo/logo.png";
import "../LandingPageComponent/Footer/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import iconPhone from "../../assets/LandingPageImages/footer/call.svg";
import iconEmail from "../../assets/LandingPageImages/footer/sms-tracking.svg";
import iconWhatsApp from "../../assets/LandingPageImages/footer/whatsapp.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* ================== START FOOTER ============= */}
      <footer>
        {/* ============= START SECTION FOOTER =============== */}
        <section className="footer position-relative" id="contact-us">
          <div className="overlay-background" />
          {/* ============= START CONTAINER ============= */}
          {/* ============= END CONTAINER ============= */}
          {/* ======== START BOTTOM FOOTER ========= */}
          <div className="bottom-footer">
            {/* ============ START CONTAINER =========== */}
            <div className="container">
              <div className="all-bottom-footer d-flex flex-column justify-content-center align-items-center gap-2">
                <div className="logo">
                  <img
                    src={logoFooter}
                    width="250px"
                    height="57px"
                    alt="logo"
                  />
                </div>
                {/* ======== START ICONS SOCIAL ========= */}
                <div className="icon-social">
                  <ul className="list-social p-0 m-0 pb-3 d-flex justify-content-center align-items-center gap-3">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61559327609048"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/Buildinccc"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@buildinccc"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTiktok} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/buildinccc/"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* ======== END ICONS SOCIAL ========= */}
                <div className="end-text">
                  <p className="text d-flex  justify-content-center  align-items-center  gap-2">
                    {t("All rights reserved. Designed and developed by")}{" "}
                    <a
                      target="_blank"
                      href="https://syntecheg.com/"
                      className="link-text"
                    >
                      syntech
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* =========== END CONTAINER  ============ */}
          </div>
          {/* ======== END BOTTOM FOOTER ========= */}
        </section>
        {/* ============= END SECTION FOOTER =============== */}
      </footer>
      {/* ================== END FOOTER ============= */}
    </>
  );
};

export default Footer;
