import React, { useRef, useState } from "react";
import ImageLogin from "../../../assets/images/gif/01.gif";
import "../Login.css";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import ImageLoginForm from "../ImageLogin/ImageLogin";
import HeaderForm from "../InfoHeaderForm/HeaderForm";
import LoginBottomInfo from "./LoginBottomInfo";
import IconHeaderForm from "../IconHeaderFormRoute/IconHeaderForm";
import LogoImageForm from "../LogoForm/LogoImageForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../../axios";
import { useDispatch } from "react-redux";
import { login } from "../../../Redux/auth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import audio from "../../../assets/WELCOME.mp3";
import { useTranslation } from "react-i18next";

const initialValues = {
  email_phone: "",
  password: "",
};

const LoginForm = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email_phone: Yup.string()
      .email(t("emailError"))
      .required(t("requiredField")),
    password: Yup.string()
      .required(t("requiredField"))
      .min(8, t("passwordLength")),
  });

  const dispatch = useDispatch();
  const router = useNavigate();
  const soundEffect = useRef();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    setLoading(true);
    console.log(values);
    axiosInstance
      .post("/login", values)
      .then((res) => {
        console.log(res);

        dispatch(login(res.data));
        toast.success(t("loginSuccess"));
        soundEffect.current.play();
        setLoading(false);
        setTimeout(() => {
          router("/home");
        }, 700);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          toast.error(t("loginError"));
        } else {
          toast.error(t("generalError"));
        }
        setLoading(false);
      });
  };

  // SHOW PASSWORD INPUT
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <HelmetInfo titlePage={t("loginTitle")} />
      <audio ref={soundEffect} src={audio}></audio>
      <div className="login-form">
        {/* ========= START LOGO LOGIN ======== */}
        <div className="logo-top">
          <LogoImageForm />
        </div>
        {/* ========= END LOGO LOGIN ======== */}
        {/* ========= START CONTAINER =========== */}
        <div className="container-fluid">
          {/* ======== START ALL LOGIN FORM =========== */}
          <div className="all-Login-form">
            {/* ========== START ROW =========== */}
            <div className="row g-3 align-items-center">
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO FORM LOGIN =========== */}
                <div className="info-form-login position-relative">
                  {/* ========= START ICON CLOSE ========= */}

                  <IconHeaderForm routerPage={"/"} iconHeader={faTimes} />
                  {/* ========= END ICON CLOSE ========= */}
                  {/* ========= START ALL FORM LOG ======== */}
                  <div className="all-form-log">
                    {/* ========== START HEADER FORM LOG ========= */}
                    <HeaderForm
                      titleHeaderForm={t("welcomeBack")}
                      textHeaderForm={t("loginPrompt")}
                    />
                    {/* ========== END HEADER FORM LOG ========= */}

                    {/* ========== START FORM FIELD ========= */}
                    <FormField
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <InputFiled
                        label={t("emailPhone")}
                        name="email_phone"
                        type="email"
                        placeholder=""
                        success
                      />

                      <div className="input-pass position-relative">
                        <InputFiled
                          label={t("password")}
                          name="password"
                          placeholder=""
                          success
                          value={password}
                          onChange={handlePasswordChange}
                          type={showPassword ? "text" : "password"}
                        />

                        <span
                          className="icon-eye-button"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-main btn-submit w-100 mt-5"
                      >
                        {loading ? (
                          <span className="loader"></span>
                        ) : (
                          t("continue")
                        )}
                      </button>
                    </FormField>
                    {/* ========== END FORM FIELD ========= */}
                    {/* ========== START BOTTOM FORM ========= */}
                    <div className="bottom-form d-flex align-items-center  gap-2 justify-content-center mt-4">
                      <p className="text"> {t("forgotPassword")} </p>
                      <Link className="link-name" to={"/forgetPassword"}>
                        {t("changePassword")}
                      </Link>
                    </div>
                    <LoginBottomInfo />
                    {/* ========== END BOTTOM FORM ========= */}
                  </div>
                  {/* ========= END ALL FORM LOG ======== */}
                </div>
                {/* ========== END INFO FORM LOGIN =========== */}
              </div>
              {/* =========== END COL ======== */}
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO LOGIN ========== */}
                <div className="info-login position-relative">
                  {/* ========= START IMAGE LOGIN ======= */}
                  <ImageLoginForm ImageLoginPic={ImageLogin} />
                  {/* ========= END IMAGE LOGIN ======= */}
                  {/* ========= START LOGO LOGIN ======== *
                 <div className="logo-login-bottom">
                   <LogoImageForm />
                 </div>
                 {/* ========= END LOGO LOGIN ======== */}
                </div>
                {/* ========== END INFO LOGIN ========== */}
              </div>
              {/* =========== END COL ======== */}
            </div>
            {/* ========== END ROW =========== */}
          </div>
          {/* ======== END ALL LOGIN FORM =========== */}
        </div>
        {/* ========= END CONTAINER =========== */}
      </div>
    </>
  );
};

export default LoginForm;
