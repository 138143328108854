import React from "react";
import "./SuccessData.css";
import successIcon from "../../assets/images/gif/success.gif";
import MainButton from "Components/Button/Button";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
const SuccessData = ({ showButton }) => {
  return (
    <>
      <HelmetInfo titlePage={"تهانينا ! تم إنشاء حساب بنجاح !"} />
      <main>
        <div className={`main`}>
          {/* ============= START SUCCESS DATA ============ */}
          <div className="success-data center-section">
            {/* ========== START CONTAINER =========== */}
            <div className="container">
              {/* ============ START ALL SUCCESS ========== */}
              <div className="all-success width-content">
                {/* ========= START SUCCESS ICON ========== */}
                <div className="success-icon">
                  <img
                    src={successIcon}
                    alt="icon success"
                    loading="lazy"
                    className="object-fit-cover"
                    width={"200px"}
                    height={"200px"}
                  />
                </div>
                {/* ========= END SUCCESS ICON ========== */}
                {/* ========= START SUCCESS INFO ========== */}
                <div className="success-info mt-3">
                  <h1 className="title">تهانينا ! تم إنشاء حساب بنجاح !</h1>

                  <MainButton
                    textButton={"الصفحة الرئيسية"}
                    newClass={"button-home w-100"}
                    routeButton={"/"}
                  />
                  {showButton && (
                    <MainButton
                      textButton={"إستكمال البيانات"}
                      newClass={"button-Show w-100"}
                      routeButton={"/"}
                    />
                  )}
                </div>
                {/* ========= END SUCCESS INFO ========== */}
              </div>
              {/* ============ END ALL SUCCESS ========== */}
            </div>
            {/* ========== END CONTAINER =========== */}
          </div>
          {/* ============= END SUCCESS DATA ============ */}
        </div>
      </main>
    </>
  );
};

export default SuccessData;
