import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/images/main/Rectangle.png";
import iconDollar from "../../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../../assets/images/icons/calendar.svg";
import RecentCardProject from "./RecentCardProject";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const RecentProjects = ({ projects }) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(projects);
  }, []);

  return (
    <div className="recent-projects card-style margin-top-content">
      {/* ======= START HEADER TOP INFO  ======== */}
      <div className="header-top-info d-flex  justify-content-between  align-items-center  gap-2 ">
        <h2 className="title">{t("recentProjectsTitle")}</h2>
        <Link to="/allProjects" className="link-all">
          {t("allProjects")}
        </Link>
      </div>
      {/* ======= END HEADER TOP INFO  ======== */}
      {/* ======== START ALL RECENT PROJECTS ============ */}
      <div className="all-recent-projects">
        <div className="row g-3">
          {projects.length !== 0 ? (
            projects.map((project, index) => {
              return (
                <div className="col-12  col-lg-12 col-xl-6" key={index}>
                  <RecentCardProject
                    img={project?.images[0]}
                    altImg={t("imgCardAlt")}
                    // badgeText={t("badgeTextCreation")}
                    titleCard={project?.title}
                    titleAddress={`${project?.city}, ${project?.region}`}
                    iconDollar={iconDollar}
                    jopType={project?.type}
                    priceCard={project?.price}
                    typePrice={t("currency")}
                    textPrice={t("expectedValue")}
                    iconCalendar={iconCalendar}
                    dateCard={project?.end_date}
                    textInfoDate={t("expectedDeliveryDate")}
                    numWatch={project?.views}
                    textWatch={t("watchText")}
                    numOffer={project?.offers_count}
                    textOffer={t("offerText")}
                    numDay={project?.since}
                    is_favorite={project?.is_favorite}
                    projectId={project?.id}
                    routeCard={`/productDetailsPage/${project?.id}`}
                  />
                </div>
              );
            })
          ) : (
            <NoResults />
          )}
        </div>
      </div>
      {/* ======== END ALL RECENT PROJECTS ============ */}
    </div>
  );
};

export default RecentProjects;
