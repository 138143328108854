import React from 'react'
import { Link } from 'react-router-dom'

const BottomForm = ({textBottomForm,routePage,linkTextBottomForm}) => {
  return (
    <>
    <div className="bottom-form d-flex align-items-center  gap-2 justify-content-center mt-4">
    <p className="text"> {textBottomForm} </p>
    <Link className="link-name" to={routePage}>
      {linkTextBottomForm}
    </Link>
  </div>
    </>
  )
}

export default BottomForm