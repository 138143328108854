import Footer from "Components/LandingPageComponent/Footer/Footer";
import Header from "Components/LandingPageComponent/Header/Header";
import Main from "Components/LandingPageComponent/Main/Main";
import "./LandingPage.css";
import ButtonScroll from "Components/LandingPageComponent/ButtonScroll/ButtonScroll";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      easing: "ease-in-out", // Easing for the animation
      once: true
    });
    window.addEventListener("load", AOS.refresh);
  }, []);

  return (
    <div className="bg-white">
      <HelmetInfo titlePage={"BUILDIN"} />

      <Header />
      <Main />
      <Footer />
      <ButtonScroll />
    </div>
  );
};

export default LandingPage;
