import React, { useCallback, useRef, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};



function Location({lat,lng,onLocationChange}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBAkwVYHpFxiSiYG5JWJ2Pq_Bo-llnewTI"
  })
  const markerRef = useRef(null);
  const [map, setMap] = React.useState(null)
console.log(typeof +lat,lng)
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(position);
    map.fitBounds(bounds);

    setMap(map)
  }, [])
  const [position, setPosition] = useState({lat:+lat || 25.2048,lng :+lng || 55.2708});
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleMapClick = useCallback((e) => {
 
    const latLng = e.latLng;

    setPosition(() => {
      return {
     
        lat: latLng.lat(),
        lng: latLng.lng(),
      }
    });

    onLocationChange({
     lat: latLng.lat(), lng:latLng.lng()
    })
  }, []);

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={30}
       
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={(e)=>handleMapClick(e)}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <>
        
        <Marker
              position={position}
              ref={markerRef}
              icon={{
      
                scaledSize: new window.google.maps.Size(40, 40),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(20, 40)
              }}

              draggable
              onDragEnd={(e)=>handleMapClick(e)}
             
            />
        </>
      </GoogleMap>
  ) : <></>
}
export default Location