import CustomModal from "Components/CustomModal/CustomModal";
import "./ModalStopOffer.css";

const ModalStopOffer = ({
  showModalStopOffer,
  handleCloseStopOffer,
  titleHeaderModal,
  textModalStop,
  titleButtonCancel,
  titleButtonBack,
  onHide,
  setShowModalStopOffer,
}) => {
  return (
    <>
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModalStopOffer}
        onHide={() => onHide()}
        title={titleHeaderModal}
        newClass={"modal-stop"}
      >
        <div className="info-modal-stop">
          <p className="text">{textModalStop}</p>

          <div className="buttons-data d-flex align-items-center gap-2">
            <button onClick={handleCloseStopOffer} className="btn-main">
              {titleButtonCancel}{" "}
            </button>
            <button
              onClick={() => setShowModalStopOffer(false)}
              className="btn-main btn-back"
            >
              {titleButtonBack}
            </button>
          </div>
        </div>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ModalStopOffer;
