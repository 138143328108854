import loaderIcon from "../../assets/images/loader/spinner.svg";
const WindowLoader = () => {
  return (
    <>
      <div className="loader-wrapper hidden">
        <div className="loader">
          <img
            src={loaderIcon}
            alt="spinner loader"
            loading="lazy"
            width="100px"
            height="100px"
          />
        </div>
      </div>
    </>
  );
};

export default WindowLoader;
