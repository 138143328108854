import CardProductDetails from "./CardProductDetails";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import "./ProductDetailsPage.css";
import InfoOwnerDetails from "./InfoOwnerDetails";
import HeaderPages from "Components/HeaderPages/HeaderPages";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import CardOffer from "Pages/Home/OffersPackages/CardOffer";
import { Accordion } from "react-bootstrap";
import iconCurrancy from "../../assets/images/icons/dollar-circle.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const ProductDetailsPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const type = useSelector((state) => state.auth.type);
  const id = useParams();
  const location = useLocation();
  const userId = useSelector((state) => state.auth.userId);
  const [showChatBtn, setShowChatBtn] = useState(false);
  const [hideOfferbtn, setHideOfferBtn] = useState(false);
  const [setchatData, setSetchatData] = useState();
  useEffect(() => {
    console.log(id);
    axiosInstance
      .get(`/project/${id.id}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    console.log(location);
    if (data?.offers) {
      data?.offers.map((offer) => {
        console.log(offer?.provider?.id == userId);
        if (offer?.provider?.id == userId) {
          setHideOfferBtn(true);
        }
      });
    }
    if (data?.provider_id) {
      if (data?.provider_id == userId) {
        setShowChatBtn(true);
      }
    }
  }, [data]);

  const refetchData = () => {
    axiosInstance
      .get(`/project/${id.id}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <HelmetInfo titlePage={t("Details")} />
      <HeaderPages
        titlePage={t("Project")}
        routePage={"/allProjects"}
        mainTitlePage={t("All Projects")}
        showMainTitlePage={true}
      />
      <div className="products-details-page margin-top-content p-lg-5">
        {/* ======== START ALL CARD PRODUCTS DETAILS ========= */}
        <div className="all-card-product-details">
          <CardProductDetails
            img={data?.images || img}
            projectId={data?.id}
            altImg={t("img Card")}
            badgeText={data?.type}
            titleCard={data?.title}
            titleAddress={`${data?.city} ,${data?.region}`}
            iconDollar={iconDollar}
            priceCard={data?.price}
            typePrice={t("currency")} // This is not translated, it's unclear what it should be
            textPrice={t("Value")}
            iconCalendar={iconCalendar}
            dateCard={data?.end_date}
            textInfoDate={t("Expected Delivery Date")}
            numWatch={data?.views}
            textWatch={t("Views")}
            status={data?.status}
            numOffer={data?.offers_count}
            textOffer={t("Offers")}
            numDay={data?.since} // This is not translated, it's unclear what it should be
            isActiveMainButtons={!hideOfferbtn}
            hideofferBtn={!hideOfferbtn}
            isActiveButtonUpdateAndStop={false}
            isActiveFinalCard={false}
            dateCard2={data?.start_date}
            textInfoDate2={t("Project Start Date")}
            dateCard3={data?.accept_date}
            textInfoDate3={t("Date of Acceptance of Offers")}
            isActiveDate={true}
            isActiveOffersWatch={true}
            routeCard={false}
            noNavigation={true}
            refetch={refetchData}
            offers={data?.offers}
            is_favorite={data?.is_favorite}
          />
        </div>
        {/* ======== END ALL CARD PRODUCTS DETAILS ========= */}
        <InfoOwnerDetails
          setchatData={setchatData}
          showChatBtn={showChatBtn}
          data={data}
        />
      </div>
      {type !== "customer" && data?.my_offer ? (
        <div className="project-details-info-pro">
          <h2 className="title">{t("My Offers")}</h2>
          <div className="all-main-cards d-flex flex-column  gap-2">
            <div className="row g-3">
              {type == "customer" ? (
                data?.offers?.map((item) => {
                  return (
                    <div className="col-12 col-lg-6" key={item.id}>
                      {/* ====== START MAIN CARD PROJECT ====== */}
                      <div className="main-card-projeالعروض المقبولةct-1">
                        {/* ======== START CARD DETAILS INFO ========== */}
                        <CardOffer
                          expertId={item.provider.id}
                          numDay={"3"}
                          image={item.provider.image}
                          imageCardAlt={"image-card"}
                          titleCard={item.provider.name}
                          textCard={"مبنى سكني مكون من 10 أدوار"}
                          rateNum={item.provider.rate}
                          textCardTrue={false}
                          cardTwoInfo={true}
                          iconCurrancy={iconCurrancy}
                          iconAlt={"iconCurrancy"}
                          priceNum={item?.price}
                          typePrice={t("currency")} // This is not translated, it's unclear what it should be
                          textPrice={t("Value")}
                          textButtonOne={t("Accept")}
                          textButtonTwo={t("Contact")}
                          buttonTwoActive={false}
                          showBtns={false}
                          place={`${item.provider.city} ,${item.provider.region}`}
                          buttonThreeActive={true}
                        />
                        {/* ======== END CARD DETAILS INFO ========== */}
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>{t("Details")}</Accordion.Header>
                            <Accordion.Body>
                              <h2 className="title">{t("Comment")}</h2>
                              <p className="text">{item.content}</p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      {/* ====== END MAIN CARD PROJECT ====== */}
                    </div>
                  );
                })
              ) : data?.my_offer ? (
                <div className="col-12 col-lg-6" key={data.my_offer.id}>
                  {/* ====== START MAIN CARD PROJECT ====== */}
                  <div className="main-card-project-1">
                    {/* ======== START CARD DETAILS INFO ========== */}
                    <CardOffer
                      expertId={data?.my_offer?.provider?.id}
                      numDay={"3"}
                      image={data?.my_offer?.provider.image}
                      imageCardAlt={"image-card"}
                      titleCard={data?.my_offer?.provider.name}
                      textCard={"مبنى سكني مكون من 10 أدوار"}
                      rateNum={data?.my_offer?.provider.rate}
                      textCardTrue={false}
                      cardTwoInfo={true}
                      iconCurrancy={iconCurrancy}
                      iconAlt={"iconCurrancy"}
                      priceNum={data?.my_offer?.price}
                      typePrice={t("currency")} // This is not translated, it's unclear what it should be
                      textPrice={t("Value")}
                      textButtonOne={t("Accept")}
                      textButtonTwo={t("Contact")}
                      buttonTwoActive={false}
                      showBtns={false}
                      place={`${data?.my_offer?.provider.city} ,${data?.my_offer?.provider.region}`}
                      buttonThreeActive={true}
                    />
                    {/* ======== END CARD DETAILS INFO ========== */}
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>{t("Details")}</Accordion.Header>
                        <Accordion.Body>
                          <h2 className="title">{t("Comment")}</h2>
                          <p className="text">{data?.my_offer?.content}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  {/* ====== END MAIN CARD PROJECT ====== */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProductDetailsPage;
