import React from "react";
import "./Pagination.css";
import ReactPaginate from "react-paginate";
const PaginationPage = ({itmeCount,setBadgeView,itemperPage}) => {
  const handlePageClick = (e) => {
console.log(e);
    let selected = e.selected;
    setBadgeView(selected * itemperPage + itemperPage);
    console.log(selected * itemperPage + itemperPage);
  };



  return (
    <div className="all-pagination d-flex justify-content-center  align-items-center mt-5">
      <ReactPaginate
        breakLabel="..."
        nextLabel="التالى"
        onPageChange={handlePageClick}
               
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={itmeCount}
        previousLabel="السابق"
        renderOnZeroPageCount={null}
        containerClassName="pagination d-flex align-items-center gap-2"
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active-1"}
      />
    </div>
  );
};

export default PaginationPage;
