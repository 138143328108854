import { Link } from "react-router-dom";
import "./NavbarMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faGear,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import imageUser from "../../assets/images/user/userImage.png";

import { useEffect, useState } from "react";
import SidbarMenu from "Components/Sidbar/SidbarMenu";
import NotificationSideBar from "Components/Notification/NotificationSideBar";

import LanguageSwitcher from "Components/Languages/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios";
const NavbarMenu = () => {
  const [openButtons, setopenButtons] = useState(false);
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    axiosInstance
      .get("/profile", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setData(res.data.data);
      });
  }, []);
  const closeToggleTab = () => {
    setopenButtons(false);
  };

  // TOGGLE BAR SIDEBAR
  const [isOpen, setIsOpen] = useState(false);

  // OPEN SIDEBAR
  const toggleSidebar = () => {
    setIsOpen(true);
  };
  // CLOSE SIDEBAR
  const closeToggleSidebar = () => {
    setIsOpen(false);
  };

  // NOTIFICATION SIDEBAR
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar1 = () => {
    setShowSidebar(true);
  };
  const closeNot = () => {
    setShowSidebar(false);
  };

  return (
    <>
      <NotificationSideBar showSidebar={showSidebar} closeNot={closeNot} />
      <div className="navbar-top">
        <SidbarMenu isOpen={isOpen} closeToggleSidebar={closeToggleSidebar} />
        <nav className="navbar navbar-expand-lg bg-white">
          <div className="container-fluid">
            <div className="info-user">
              <h2 className="title-name">
                {t("welcomeBack")} {data?.name}{" "}
                <span className="navBarTypeAc">{t(data?.type)}</span>
              </h2>
              <p className="text">{t("welcomeBackMessage")}</p>
            </div>

            <div className="all-menu d-flex align-items-center gap-4 ">
              <div
                className={`navbar-menu-buttons  ${openButtons ? "open" : ""}`}
              >
                <div
                  className={`icon-close ${openButtons ? "d-flex" : "d-none"}`}
                  onClick={closeToggleTab}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2">
                  <li className="nav-item">
                    <Link
                      className="text-decoration-none d-flex align-items-center gap-2 btn-main button-home"
                      to={"projectManagementRequest"}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {t("projectManagementRequest")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="text-decoration-none d-flex align-items-center gap-2 btn-main button-home button-new-project"
                      to={"/addNewProject"}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {t("addNewProject")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="menu-left d-flex align-items-center gap-3">
                <div className="main-icons d-flex  align-items-center gap-3">
                  <button onClick={toggleSidebar} className="toggle-bar">
                    <FontAwesomeIcon icon={faBars} />
                  </button>

                  <button
                    onClick={() => {
                      setopenButtons(openButtons === false ? true : false);
                    }}
                    className="icon-nav-menu toggle-gear"
                  >
                    <FontAwesomeIcon icon={faGear} />
                  </button>
                  <Link className="icon-nav-menu" to={"archives"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3.30023 22.75C2.94023 22.75 2.59022 22.66 2.29022 22.48C1.62022 22.09 1.24023 21.31 1.24023 20.34V8.98001C1.24023 6.85001 2.97022 5.12 5.10022 5.12H12.8802C15.0102 5.12 16.7402 6.85001 16.7402 8.98001V20.34C16.7402 21.31 16.3602 22.08 15.6902 22.48C15.0202 22.87 14.1602 22.83 13.3102 22.36L9.38025 20.17C9.19025 20.06 8.79022 20.06 8.60022 20.17L4.67023 22.36C4.21023 22.62 3.74023 22.75 3.30023 22.75ZM5.11023 6.63C3.81023 6.63 2.75024 7.68999 2.75024 8.98999V20.35C2.75024 20.76 2.87024 21.08 3.06024 21.19C3.25024 21.3 3.59026 21.25 3.95026 21.05L7.88025 18.86C8.52025 18.51 9.48024 18.51 10.1202 18.86L14.0502 21.05C14.4102 21.25 14.7402 21.3 14.9402 21.19C15.1302 21.08 15.2502 20.76 15.2502 20.35V8.98999C15.2502 7.68999 14.1903 6.63 12.8903 6.63H5.11023Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M14.69 22.75C14.25 22.75 13.79 22.62 13.32 22.37L9.39001 20.18C9.20001 20.08 8.78998 20.08 8.59998 20.18L4.67999 22.37C3.82999 22.84 2.95999 22.88 2.29999 22.49C1.62999 22.1 1.25 21.32 1.25 20.36V9C1.25 6.87 2.97999 5.14001 5.10999 5.14001H12.89C15.02 5.14001 16.75 6.87 16.75 9V20.36C16.75 21.32 16.37 22.1 15.7 22.49C15.4 22.66 15.06 22.75 14.69 22.75ZM9 18.6C9.4 18.6 9.79 18.69 10.12 18.87L14.05 21.06C14.41 21.26 14.75 21.31 14.94 21.19C15.13 21.08 15.25 20.76 15.25 20.35V8.98999C15.25 7.68999 14.19 6.63 12.89 6.63H5.10999C3.80999 6.63 2.75 7.68999 2.75 8.98999V20.35C2.75 20.76 2.87 21.08 3.06 21.19C3.25 21.3 3.59001 21.25 3.95001 21.05L7.88 18.86C8.20001 18.69 8.6 18.6 9 18.6Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M20.69 18.87C20.25 18.87 19.79 18.74 19.32 18.49L15.63 16.43C15.39 16.3 15.25 16.05 15.25 15.78V9C15.25 7.7 14.19 6.64001 12.89 6.64001H8C7.59 6.64001 7.25 6.30001 7.25 5.89001V5.12C7.25 2.99 8.97999 1.26001 11.11 1.26001H18.89C21.02 1.26001 22.75 2.99 22.75 5.12V16.48C22.75 17.44 22.37 18.22 21.7 18.61C21.4 18.78 21.06 18.87 20.69 18.87ZM16.75 15.33L20.05 17.18C20.41 17.38 20.74 17.43 20.94 17.31C21.14 17.19 21.25 16.88 21.25 16.47V5.10999C21.25 3.80999 20.19 2.75 18.89 2.75H11.11C9.80999 2.75 8.75 3.80999 8.75 5.10999V5.13H12.89C15.02 5.13 16.75 6.85999 16.75 8.98999V15.33Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M11.9999 22.8999C10.9299 22.8999 9.87992 22.4599 9.11992 21.6999C8.35992 20.9399 7.91992 19.8899 7.91992 18.8199H9.41992C9.41992 19.4999 9.69992 20.1599 10.1799 20.6399C10.6599 21.1199 11.3199 21.3999 11.9999 21.3999C13.4199 21.3999 14.5799 20.2399 14.5799 18.8199H16.0799C16.0799 21.0699 14.2499 22.8999 11.9999 22.8999Z"
                        fill="#B0B0B0"
                      />
                    </svg>
                  </Link>
                  <div onClick={toggleSidebar1} className="icon-nav-menu">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 10.5199C11.59 10.5199 11.25 10.1799 11.25 9.76994V6.43994C11.25 6.02994 11.59 5.68994 12 5.68994C12.41 5.68994 12.75 6.02994 12.75 6.43994V9.76994C12.75 10.1899 12.41 10.5199 12 10.5199Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M12.0208 20.35C9.44084 20.35 6.87084 19.94 4.42084 19.12C3.51084 18.82 2.82084 18.17 2.52084 17.35C2.22084 16.53 2.32084 15.59 2.81084 14.77L4.08084 12.65C4.36084 12.18 4.61084 11.3 4.61084 10.75V8.64999C4.61084 4.55999 7.93084 1.23999 12.0208 1.23999C16.1108 1.23999 19.4308 4.55999 19.4308 8.64999V10.75C19.4308 11.29 19.6808 12.18 19.9608 12.65L21.2308 14.77C21.7008 15.55 21.7808 16.48 21.4708 17.33C21.1608 18.18 20.4808 18.83 19.6208 19.12C17.1708 19.95 14.6008 20.35 12.0208 20.35ZM12.0208 2.74999C8.76084 2.74999 6.11084 5.39999 6.11084 8.65999V10.76C6.11084 11.57 5.79084 12.74 5.37084 13.43L4.10084 15.56C3.84084 15.99 3.78084 16.45 3.93084 16.85C4.08084 17.25 4.42084 17.55 4.90084 17.71C9.50084 19.24 14.5608 19.24 19.1608 17.71C19.5908 17.57 19.9208 17.25 20.0708 16.83C20.2308 16.41 20.1808 15.95 19.9508 15.56L18.6808 13.44C18.2608 12.75 17.9408 11.58 17.9408 10.77V8.66999C17.9308 5.39999 15.2808 2.74999 12.0208 2.74999Z"
                        fill="#B0B0B0"
                      />
                      <path
                        d="M11.9999 22.8999C10.9299 22.8999 9.87992 22.4599 9.11992 21.6999C8.35992 20.9399 7.91992 19.8899 7.91992 18.8199H9.41992C9.41992 19.4999 9.69992 20.1599 10.1799 20.6399C10.6599 21.1199 11.3199 21.3999 11.9999 21.3999C13.4199 21.3999 14.5799 20.2399 14.5799 18.8199H16.0799C16.0799 21.0699 14.2499 22.8999 11.9999 22.8999Z"
                        fill="#B0B0B0"
                      />
                    </svg>
                    <span className="badge-num not-fic"></span>
                  </div>
                </div>
                <Link to="/myAccount" className="image-user">
                  <img src={data?.image} alt="userImage" />
                </Link>
                {/* LANGUAGES SWITCHER */}
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavbarMenu;
