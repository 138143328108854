import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const HelmetInfo = ({ titlePage }) => {

  return (
    <Helmet>
      <title>bulidIn</title>

      {/* description */}
      <meta
        name="description"
        content="buildIn Discription Content info buildIn  Discription Content info buildIn Discription Content info"
      />

      {/* keywords */}
      <meta
        name="keywords"
        content="buildIn  KeyWords buildIn  KeyWords buildIn  KeyWords"
      />
      {/*  <!-- #author --> */}
      <meta name="author" content="buildIn Website" />
      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="buildIn Link Url" />
      <meta property="og:type" content="buildIn website" />
      <meta property="og:title" content="buildIn Services" />
      <meta
        property="og:description"
        content="buildIn A concise description of your page content"
      />
      <meta property="og:image" content="buildIn Image" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="buildIn Domain Link Url" />
      <meta property="twitter:url" content="buildIn Url" />
      <meta name="twitter:title" content="buildIn" />
      <meta
        name="twitter:description"
        content="buildIn description of your page content"
      />
      <meta name="twitter:image" content="buildIn  Image" />
      {/* LINK URL WEBSITE buildIn */}
      <link rel="canonical" href="https://www.example.com/your-page" />
      {/* <!-- Favicon --> */}
      <link
        rel="shortcut icon"
        type="image/png"
        href="../src/assets/images/logo/logo.svg"
      />
    </Helmet>
  );
};

export default HelmetInfo;
