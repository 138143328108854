import React from 'react'
import { Link } from 'react-router-dom'

const MainButton = ({textButton, newClass, routeButton}) => {
  return (
    <>
    <Link 
    to={routeButton}
    className={`btn btn-main ${newClass}`}
  >
    {textButton}
  </Link>
    </>
  )
}

export default MainButton
