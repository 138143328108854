import iconHome from "../../assets/images/iconsSideBar/home-2.svg";
import iconBuildings from "../../assets/images/iconsSideBar/buildings-2.svg";
import iconMessages from "../../assets/images/iconsSideBar/messages.svg";
import iconArchive from "../../assets/images/iconsSideBar/archive.svg";
import iconNotification from "../../assets/images/iconsSideBar/notification-bing.svg";
import iconDiamonds from "../../assets/images/iconsSideBar/diamonds.svg";
import iconMoneys from "../../assets/images/iconsSideBar/moneys.svg";

import iconDocument from "../../assets/images/iconsSideBar/document-text.svg";
import iconUser from "../../assets/images/iconsSideBar/user.svg";
import iconLogout from "../../assets/images/iconsSideBar/logout.svg";
import logo from "../../assets/images/logo/logo.png";
import sidbarImage from "../../assets/images/main/sidebarimage.png";
import iconSend from "../../assets/images/icons/send-2.svg";
import "./SidebarMenu.css";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ContactModal from "Components/ContactModal/ContactModal";
import { useEffect, useState } from "react";
import NotificationSideBar from "Components/Notification/NotificationSideBar";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../axios";
import Pusher from "pusher-js";
import { logout } from "../../Redux/auth";
import { useTranslation } from "react-i18next";


const SidbarMenu = ({ isOpen, closeToggleSidebar }) => {
  const { t, i18n } = useTranslation();
  const [numOfMessages, setNumOfMessages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const Type = useSelector((state) => state.auth.type);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    axiosInstance
      .get("/chat", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        let count = 0;
        res.data.data.map((chat) => {
          count += chat.unread_count;
        });
        console.log(count);
        setNumOfMessages(count);
      });
  }, []);

  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar1 = (e) => {
    e.preventDefault();
    setShowSidebar(true);
    closeToggleSidebar();
  };

  const closeNot = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    const pusher = new Pusher("6c28f2175d22ab159957", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("my-channel");

    channel.bind("App\\Events\\FlutterEvent", function (data) {
      console.log("dsad", data);
      if (data.to == userId) {
        axiosInstance
          .get("/chat", {
            headers: {
              "Accept-Language": i18n.language,
            },
          })
          .then((res) => {});
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
      pusher.disconnect();
    };
  }, []);

  const linksMenuBar = [
    {
      text: t("home"),
      icon: iconHome,
      path: "home",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("allProjects"),
      icon: iconBuildings,
      path: "allProjects",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("myProjects"),
      icon: iconBuildings,
      path: "myProjects",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("experts"),
      icon: iconBuildings,
      path: "allExperts",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("Wallet"),
      icon: iconMoneys,
      path: "Wallet",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("chats"),
      icon: iconMessages,
      path: "chat",
      numCount: 0,
      iconCount: true,
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("archives"),
      icon: iconArchive,
      path: "archives",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("notifications"),
      icon: iconNotification,
      path: null,
      newClass: "notActive",
      // numCount: 0,
      // iconCount: true,
      toggleSidebar: toggleSidebar1,
    },
    {
      text: t("packages"),
      icon: iconDiamonds,
      path: "packagesCards",
      toggleSidebar: closeToggleSidebar,
    },
    // {
    //   text: t("subscriptions"),
    //   icon: iconMoneys,
    //   path: "subscriptions",
    //   toggleSidebar: closeToggleSidebar,
    // },
    {
      text: t("subscriptions"),
      icon: iconMoneys,
      path: "subscriptions",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("reports"),
      icon: iconDocument,
      path: "reports",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("myAccount"),
      icon: iconUser,
      path: "myAccount",
      toggleSidebar: closeToggleSidebar,
    },
  ];
  const linksMenuBarForCustomer = [
    {
      text: t("home"),
      icon: iconHome,
      path: "home",
      toggleSidebar: closeToggleSidebar,
    },
  
    {
      text: t("myProjects"),
      icon: iconBuildings,
      path: "myProjects",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("experts"),
      icon: iconBuildings,
      path: "allExperts",
      toggleSidebar: closeToggleSidebar,
    },
  
    {
      text: t("chats"),
      icon: iconMessages,
      path: "chat",
      numCount: 0,
      iconCount: true,
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("archives"),
      icon: iconArchive,
      path: "archives",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("notifications"),
      icon: iconNotification,
      path: null,
      newClass: "notActive",
      // numCount: 0,
      // iconCount: true,
      toggleSidebar: toggleSidebar1,
    },
    // {
    //   text: t("packages"),
    //   icon: iconDiamonds,
    //   path: "packagesCards",
    //   toggleSidebar: closeToggleSidebar,
    // },
 
    {
      text: t("reports"),
      icon: iconDocument,
      path: "reports",
      toggleSidebar: closeToggleSidebar,
    },
    {
      text: t("myAccount"),
      icon: iconUser,
      path: "myAccount",
      toggleSidebar: closeToggleSidebar,
    },
  ];
  const dispatch = useDispatch();

  return (
    <>
      <NotificationSideBar showSidebar={showSidebar} closeNot={closeNot} />
      <ContactModal showModal={showModal} handleCloseModal={handleCloseModal} />
      <div className={`all-sidebar ${isOpen ? "open" : ""}`}>
        <div
          className={`overlay-bg-1 ${isOpen ? "open" : ""}`}
          onClick={closeToggleSidebar}
        ></div>
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="icon-close" onClick={closeToggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Link to="/home">
            <div className="logo-sidebar">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <ul className="navbar-nav">
            { Type!="customer" && linksMenuBar.map((link) => (
              <li className="nav-item" key={link.path}>
                <NavLink
                  className={`nav-link ${link.newClass}`}
                  to={link.path}
                  onClick={link.toggleSidebar}
                >
                  <img src={link.icon} alt={link.text} /> {link.text}
                  {link.iconCount && (
                    <div className="badge-num num-count">
                      {link.path == "chat" && numOfMessages}
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
             { Type=="customer" && linksMenuBarForCustomer.map((link) => (
              <li className="nav-item" key={link.path}>
                <NavLink
                  className={`nav-link ${link.newClass}`}
                  to={link.path}
                  onClick={link.toggleSidebar}
                >
                  <img src={link.icon} alt={link.text} /> {link.text}
                  {link.iconCount && (
                    <div className="badge-num num-count">
                      {link.path == "chat" && numOfMessages}
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
            <li>
              <ul className="p-0 m-0">
                <li className="nav-item nav-item-bottom">
                  <NavLink
                    className="nav-link"
                    to={"/loginForm"}
                    onClick={() => dispatch(logout())}
                  >
                    <img src={iconLogout} alt="icon" />
                    {t("logout")}
                  </NavLink>
                </li>
                <div className="contact-sidebar-info">
                  <h2 className="title">{t("needHelp")}</h2>
                  <div className="image-sidebar">
                    <img src={sidbarImage} alt="imagesidebar" />
                  </div>
                  <button
                    onClick={handleShowModal}
                    className="btn-main d-flex align-items-center gap-2 "
                  >
                    <img src={iconSend} alt="icon send" />
                    {t("contactUs")}
                  </button>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SidbarMenu;
