import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo_2.png";
const Logo = () => {
  return (
    <Link className="navbar-brand" to="/">
      <div className="image-logo object-fit-cover">
        <img
          src={logo}
          width={"170px"}
          height={"60px"}
          alt="logo"
          className="object-fit-cover"
        />
      </div>
    </Link>
  );
};

export default Logo;
