import React from "react";
import HeaderTop from "./HeaderTopNav/HeaderTop";
import WorkComponent from "./WorkComponent/WorkComponent";
import WorkSteps from "./WorkSteps/WorkSteps";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import * as Yup from "yup";
import iconLocation from "../../assets/images/icons/location.svg";
const validationSchema = Yup.object().shape({
  //   placeAddress: Yup.string().required("ادخل موقعك")
  email: Yup.string().required("ادخل البريد الالكترونى الخاص  بالعمل"),
  phone: Yup.string().required("رقم الموبايل الخاص بالعمل")
});

const initialValues = {
  locationA: "",
  email: "",
  phone: ""
};

const FinalCompleteData = () => {
  // FORM SUBMIT
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm({ values: "" });
  };
  return (
    <div className="work-information">
      {/* ========= START CONTAINER ========= */}
      <div className="container-fluid container-md">
        {/* ========= START ALL WORK INFORATION ========= */}
        <div className="all-work-information">
          <HeaderTop />

          {/* ============ START MAIN ========== */}
          <main>
            {/* ============ START ALL MAIN ========== */}
            <div className="all-main">
              {/* ========== START HEADER TOP ======= */}
              <WorkComponent />
              {/* ========== END HEADER TOP ======= */}
              <WorkSteps
                addActiveStep1={"active"}
                addActiveStep2={"active"}
                addActiveStep3={"active"}
                activeText={"activeText"}
              />

              {/* ========= START FORM INFO  ========== */}
              <div className="form-info pt-5">
                <FormField
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <div className="row g-3">
                    {/* ========= START COL ======== */}
                    <div className="col-12">
                      <div className="input-pass position-relative">
                        <InputFiled
                          label="عنوان المكان"
                          name="placeAddress"
                          type="text"
                          placeholder=""
                          success
                          iconInput={""}
                        />
                        <div className="icon-svg-local">
                          <img src={iconLocation} alt="icon location" />
                        </div>
                      </div>
                    </div>
                    {/* ========= END COL ======== */}

                    {/* ========= START COL ======== */}
                    <div className="col-12 col-md-12">
                      <div className="map-location mt-3 mb-1">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1222073449103!2d106.77590781537452!3d-6.2476228629146675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f11b12c11ab7%3A0xcd48f5c775249316!2sHumanity%20First%20Indonesia!5e0!3m2!1sid!2sid!4v1605684563677!5m2!1sid!2sid"
                          frameBorder="0"
                          style={{ border: 0 }}
                          aria-hidden="false"
                          title="Humanity First Indonesia"
                        />
                      </div>
                    </div>
                    {/* ========= END COL ======== */}

                    {/* ========= START COL ======== */}
                    <div className="col-12 col-md-6">
                      <InputFiled
                        label="البريد الالكترونى الخاص بالعمل *"
                        name="email"
                        type="email"
                        placeholder=""
                        success
                        iconInput={""}
                      />
                    </div>
                    {/* ========= END COL ======== */}
                    {/* ========= START COL ======== */}
                    <div className="col-12 col-md-6">
                      <InputFiled
                        label="رقم الموبايل الخاص بالعمل *"
                        name="phone"
                        type="tel"
                        placeholder=""
                        success
                        iconInput={""}
                      />
                    </div>
                    {/* ========= END COL ======== */}
                  </div>
                  <button className="btn-main w-100">حفظ</button>
                </FormField>
              </div>
              {/* ========= END FORM INFO ========== */}
            </div>
            {/* ============ START ALL MAIN ========== */}
          </main>
          {/* ============ END MAIN ========== */}
        </div>
        {/* ========= END ALL WORK INFORATION ========= */}
      </div>
      {/* ========= END CONTAINER ========= */}
    </div>
  );
};

export default FinalCompleteData;
