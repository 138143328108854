import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "ar"
  );

  useEffect(() => {
    // Save language to local storage
    localStorage.setItem("language", currentLanguage);
  }, [currentLanguage]);

  const direction = currentLanguage === "ar" ? "rtl" : "ltr";

  useEffect(() => {
    // Save direction to local storage
    localStorage.setItem("direction", direction);

    // Update the direction of the entire HTML document
    document.documentElement.dir = direction;
    // Set the default lang attribute
    document.documentElement.lang = currentLanguage;
  }, [direction, currentLanguage]);

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
