import CustomModal from "Components/CustomModal/CustomModal";
import Otp from "Pages/Otp/Otp";
import ModalSuccess from "Components/Success/ModalSuccess";
import successIcon from "../../../assets/images/gif/success.gif";
import { useState } from "react";

const ModalConfirmNumber = ({
  showModalConfirmNumber,
  handleCloseModalConfirmNumber
}) => {
  // OEPN ANDCLOSE MODAL EDIT INFO WORK
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  // CLOSE MODAL
  const handleCloseModalSuccess = () => {
    setShowModalSuccess(false);
  };
  // OPEN MODAL
  const handleShowModalSuccess = (e) => {
    e.preventDefault();
    setShowModalSuccess(true);
    handleCloseModalConfirmNumber();
  };

  return (
    <>
      <ModalSuccess
        showModalSuccess={showModalSuccess}
        handleCloseModalSuccess={handleCloseModalSuccess}
        titleInfo={"تم تعديل معلومات الحساب الخاصة بك بنجاح !"}
        isAddText={false}
        textInfo={false}
        titleButton={"تم"}
        buttonRoute={true}
        routeDir={"/myAccount"}
        successIcon={successIcon}
        isActiveMainButton={false}
      />
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModalConfirmNumber}
        onHide={handleCloseModalConfirmNumber}
        title=""
        newClass={"modal-account"}
      >
        <Otp
          openPopupSuccess={handleShowModalSuccess}
          isAcriveButtonModal={true}
          showModalConfirmNumber={showModalSuccess}
          handleCloseModalConfirmNumber={handleCloseModalSuccess}
        />
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ModalConfirmNumber;
