import CustomModal from "Components/CustomModal/CustomModal";

import { Link } from "react-router-dom";

const ModalSuccess = ({
  showModalSuccess,
  handleCloseModalSuccess,
  titleInfo,
  isAddText,
  textInfo,
  titleButton,
  buttonRoute,
  routeDir,
  successIcon,
  isActiveMainButton
}) => {
  return (
    <>
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModalSuccess}
        onHide={handleCloseModalSuccess}
        title=""
        newClass={"modal-success"}
      >
        <div className="info-success">
          {/* ========= START SUCCESS ICON ========== */}
          <div className="success-icon">
            <img
              src={successIcon}
              alt="icon success"
              loading="lazy"
              className="object-fit-cover"
              width={"200px"}
              height={"200px"}
            />
          </div>
          {/* ========= END SUCCESS ICON ========== */}
          {/* ========= START SUCCESS INFO ========== */}
          <div className="success-info mt-3">
            <h1 className="title">{titleInfo}</h1>
            {isAddText && <p className="text">{textInfo}</p>}
            {isActiveMainButton && (
              <button
                className="btn-main w-100 button-home"
                onClick={handleCloseModalSuccess}
              >
                {titleButton}
              </button>
            )}

            {buttonRoute && (
              <Link
                className="btn-main w-100 button-home"
                onClick={handleCloseModalSuccess}
                to={routeDir}
              >
                {titleButton}
              </Link>
            )}
          </div>
          {/* ========= END SUCCESS INFO ========== */}
        </div>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ModalSuccess;
