import NavbarMenu from "Components/Navbar/NavbarMenu";
import SidbarMenu from "Components/Sidbar/SidbarMenu";
import Cookies from "js-cookie";
import Pusher from "pusher-js";
import audio from "../../assets/iPHONE.mp3";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate, useOutlet } from "react-router-dom";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
import { getToken, onMessage } from "firebase/messaging";
import { messaging, setupNotifications } from "../../firebase";
import useVisibilityChange from "useVisibilityChange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faTiktok,
  faWhatsapp,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import logoFooter from "../../assets/LandingPageImages/logo/logo.png";

const Layout = () => {
  const token = useSelector((state) => state.auth.token);
  const { t } = useTranslation();
  const userId = useSelector((state) => state.auth.userId);
  const router = useNavigate();
  const soundEffect = useRef(null);
  const outlet = useOutlet();
  useEffect(() => {
    console.log(outlet);
  }, [outlet.children]);
  useEffect(() => {
    console.log(token);
    if (!token) {
      router("/loginForm");
    }
  }, [token]);
  useEffect(() => {
    const pusher = new Pusher("6c28f2175d22ab159957", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("my-channel");
    // channel.bind("my-event", function (data) {
    //   setData(data);
    // });

    channel.bind("App\\Events\\FlutterEvent", function (data) {
      console.log("dsad", data);
      if (data.to == userId) {
        soundEffect.current.play();
        toast.success("لديك رسالة جديدة");
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
      pusher.disconnect();
    };
  }, []);

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BAG4A98EvioFZ3clO7x086eJNSXI0hEy1FcnDp22cfBz__8xTydVtktGSp6LT9Omz0VqpFSPvEt82VSpQdIhCZw",
      });

      axiosInstance
        .post("/updatetoken", {
          device_token: token,
        })
        .then((res) => {
          console.log(res);
        });
      //We can send token to server
      onMessage(messaging, (payload) => {
        toast.success("you hav a new notifcation");
        console.log("asdsadsad", payload);
        // Handle the notification or update your UI
      });
    } else if (permission === "denied") {
      //notifications are blocked
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <div className="d-flex">
      <SidbarMenu isOpen={undefined} closeToggleSidebar={undefined} />

      <div className="contnet-page">
        <NavbarMenu />
        <audio ref={soundEffect} src={audio}></audio>
        <div className="px-3" style={{ paddingBottom: "100px" }}>
          {token ? <Outlet /> : <Navigate to="/loginForm" />}
        </div>
        <div className="footer">
          <div className="bottom-footer">
            {/* ============ START CONTAINER =========== */}
            <div className="container">
              <div className="all-bottom-footer d-flex flex-column justify-content-center align-items-center gap-2">
                <div className="logo">
                  <img
                    src={logoFooter}
                    width="160px"
                    height="57px"
                    alt="logo"
                  />
                </div>
                {/* ======== START ICONS SOCIAL ========= */}
                <div className="icon-social">
                  <ul className="list-social p-0 m-0 pb-3 d-flex justify-content-center align-items-center gap-3">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61559327609048"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/Buildinccc"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@buildinccc"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTiktok} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/+971581541366"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/buildinccc/"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* ======== END ICONS SOCIAL ========= */}
                <div className="end-text">
                  <p className="text d-flex  justify-content-center  align-items-center  gap-2">
                    {t("All rights reserved. Designed and developed by")}{" "}
                    <a
                      target="_blank"
                      href="https://syntecheg.com/"
                      className="link-text"
                    >
                      syntech
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* =========== END CONTAINER  ============ */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
