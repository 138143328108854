import ImageComponent from "../ImageLogin/ImageComponent";
import LogoImageForm from "../LogoForm/LogoImageForm";
import IconHeaderForm from "../IconHeaderFormRoute/IconHeaderForm";

import { faArrowRight, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import HeaderForm from "../InfoHeaderForm/HeaderForm";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChooseSpecialty.css";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { Link } from "react-router-dom";

// Import i18n library and translations
import { useTranslation } from 'react-i18next';


const ChooseSpecialty = () => {
  const [activeItem, setActiveItem] = useState(0);
  const { t, i18n } = useTranslation();

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const items = [t("contractor"), t("consultant"), t("supplier")];
  return (
    <>
      <HelmetInfo titlePage={t("selectSpecialty")} />
      <main>
        <div className="login-form padding-xy">
          {/* ========= START LOGO LOGIN ======== */}
          <div className="logo-top">
            <LogoImageForm />
          </div>
          {/* ========= END LOGO LOGIN ======== */}
          {/* ========= START CONTAINER =========== */}
          <div className="container-fluid">
            {/* ======== START ALL LOGIN FORM =========== */}
            <div className="all-Login-form">
              {/* ========== START ROW =========== */}
              <div className="row g-3 align-items-center">
                {/* =========== START COL ======== */}
                <div className="col-12 col-md-6">
                  {/* ========== START INFO FORM LOGIN =========== */}
                  <div className="info-form-login position-relative">
                    {/* ========= START ICON CLOSE ========= */}
                    <IconHeaderForm
                      routerPage={"/LoginForm"}
                      iconHeader={faArrowRight}
                    />
                    {/* ========= END ICON CLOSE ========= */}
                    {/* ========= START ALL FORM LOG ======== */}
                    <div className="all-form-log">
                      {/* ========== START HEADER FORM LOG ========= */}
                      <HeaderForm
                        titleHeaderForm={t("selectYourSpecialty")}
                        textHeaderForm={t("selectSpecialtyText")}
                      />
                      <div className="all-users">
                        <div className="row g-3">
                          {items.map((item, index) => (
                            <div className="col-12 col-md-12" key={index}>
                              <div
                                className={`user-info-one ${
                                  index === activeItem ? "active" : ""
                                }`}
                                onClick={() => handleItemClick(index)}
                              >
                                {item}
                                {index === activeItem && (
                                  <FontAwesomeIcon
                                    icon={faCheckDouble}
                                    className="check-icon"
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Link
                        to="/basicAccountInformation"
                        state={{ specialty: activeItem }}
                        type="submit"
                        className="btn btn-main btn-submit w-100 mt-5"
                      >
                        {t("continue")}
                      </Link>
                    </div>
                    {/* ========= END ALL FORM LOG ======== */}
                  </div>
                  {/* ========== END INFO FORM LOGIN =========== */}
                </div>
                {/* =========== END COL ======== */}
                {/* =========== START COL ======== */}
                <div className="col-12 col-md-6">
                  {/* ========== START INFO LOGIN ========== */}
                  <div className="info-login position-relative">
                    {/* ========= START IMAGE LOGIN ======= */}
                    <ImageComponent />
                    {/* ========= END IMAGE LOGIN ======= */}
                  </div>
                  {/* ========== END INFO LOGIN ========== */}
                </div>
                {/* =========== END COL ======== */}
              </div>
              {/* ========== END ROW =========== */}
            </div>
            {/* ======== END ALL LOGIN FORM =========== */}
          </div>
          {/* ========= END CONTAINER =========== */}
        </div>
      </main>
    </>
  );
};

export default ChooseSpecialty;

// Export translations

