import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CardProductDetails from "Pages/ProductDetailsPage/CardProductDetails";
import img from "../../assets/images/main/Rectangle.png";
import iconDollar from "../../assets/images/icons/dollar-circle2.svg";
import iconCalendar from "../../assets/images/icons/calendar.svg";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import { Link } from "react-router-dom";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const EndContent = ({ projects }) => {
  const { t } = useTranslation();
  return (
    <>
      {/** ========== START END CONTENT REPORTS ============= */}
      <div className="end-content-reports">
        {/* ======= START ROW ======== */}
        <div className="row g-3">
          {/* ========== START COL =========== */}
          <div className="col-12 col-md-12">
            <div className="all-projects-reports card-style">
              {/* ======= START HEADER TOP INFO  ======== */}
              <div className="header-top-info d-flex  justify-content-between flex-wrap  align-items-center  gap-2 ">
                <h2 className="title">{t("myProjects")} </h2>
                <Link className="title" to={"/myProjects"} style={{color:'#377dff'}}>{t("all Projects")} </Link>

                {/* <div className="form-select-option">
                  <select className="form-select">
                    <option selected>{t("last7DaysOption")}</option>
                    <option value="1">{t("one")}</option>
                    <option value="2">{t("two")}</option>
                    <option value="3">{t("three")}</option>
                  </select>
                </div> */}
              </div>
              {/* ======= END HEADER TOP INFO  ======== */}

              {/* ========= START CONTENT TABS REPORTS ========== */}
              <div className="content-tabs-reports">
                {/* ======= START TABS CONTENT ========= */}
                <div className="tabs-content">
                  <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="profile" title={t("all")}>
                      <div className="all-cards-tabs">
                        {projects?.all?.length ? (
                          projects?.all?.slice(0, 3).map((item) => {
                            return (
                              <CardProductDetails
                                               img={item?.images[0] || img}
                                altImg={"img Card"}
                                badgeText={item.category}
                                titleCard={item.title}
                                titleAddress={`${item.city} , ${item.region}`}
                                iconDollar={iconDollar}
                                priceCard={item.price}
                                status={item.status}
                                typePrice={"درهم"}
                                textPrice={t("expectedValue")}
                                iconCalendar={iconCalendar}
                                dateCard={false}
                                textInfoDate={false}
                                numWatch={false}
                                textWatch={false}
                                numOffer={false}
                                textOffer={false}
                                numDay={false}
                                isActiveMainButtons={false}
                                isActiveButtonUpdateAndStop={false}
                                isActiveFinalCard={false}
                                dateCard2={false}
                                textInfoDate2={false}
                                dateCard3={false}
                                textInfoDate3={false}
                                isActiveDate={false}
                                isActiveOffersWatch={false}
                                routeCard={"projectOneDetails"}
                                projectId={item.id}
                                formWorkProject={true}
                              />
                            );
                          })
                        ) : (
                          <NoResults text={t("noProjects")} />
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="profile-2" title={t("approvedProjects")}>
                      <div className="all-cards-tabs">
                        {projects?.approved?.length ? (
                          projects?.approved?.slice(0, 3).map((item) => {
                            return (
                              <CardProductDetails
                                               img={item?.images[0] || img}
                                altImg={"img Card"}
                                badgeText={item.category}
                                titleCard={item.title}
                                titleAddress={`${item.city} , ${item.region}`}
                                iconDollar={iconDollar}
                                priceCard={item.price}
                                typePrice={"درهم"}
                                textPrice={t("expectedValue")}
                                iconCalendar={iconCalendar}
                                dateCard={false}
                                textInfoDate={false}
                                numWatch={false}
                                textWatch={false}
                                status={item.status}
                                numOffer={false}
                                textOffer={false}
                                numDay={false}
                                isActiveMainButtons={false}
                                isActiveButtonUpdateAndStop={false}
                                isActiveFinalCard={false}
                                dateCard2={false}
                                textInfoDate2={false}
                                dateCard3={false}
                                textInfoDate3={false}
                                isActiveDate={false}
                                isActiveOffersWatch={false}
                                routeCard={"projectOneDetails"}
                                projectId={item.id}
                                formWorkProject={true}
                              />
                            );
                          })
                        ) : (
                          <NoResults text={t("noProjects")} />
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="profile-3" title={t("finishedProjects")}>
                      <div className="all-cards-tabs">
                        {projects?.finished?.length ? (
                          projects?.finished?.slice(0, 3).map((item) => {
                            return (
                              <CardProductDetails
                                               img={item?.images[0] || img}
                                altImg={"img Card"}
                                badgeText={item.category}
                                titleCard={item.title}
                                titleAddress={`${item.city} , ${item.region}`}
                                iconDollar={iconDollar}
                                priceCard={item.price}
                                typePrice={"درهم"}
                                textPrice={t("expectedValue")}
                                iconCalendar={iconCalendar}
                                dateCard={false}
                                textInfoDate={false}
                                numWatch={false}
                                textWatch={false}
                                numOffer={false}
                                status={item.status}

                                textOffer={false}
                                numDay={false}
                                isActiveMainButtons={false}
                                isActiveButtonUpdateAndStop={false}
                                isActiveFinalCard={false}
                                dateCard2={false}
                                textInfoDate2={false}
                                dateCard3={false}
                                textInfoDate3={false}
                                isActiveDate={false}
                                isActiveOffersWatch={false}
                                routeCard={"projectOneDetails"}
                                projectId={item.id}
                                formWorkProject={true}
                              />
                            );
                          })
                        ) : (
                          <NoResults text={t("noProjects")} />
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                {/* ======= END TABS CONTENT ========= */}
              </div>
              {/* ========= END CONTENT TABS REPORTS ========== */}
            </div>
          </div>
          {/* ========== END COL =========== */}
          {/* ========== START COL =========== */}

          {/* ========== END COL =========== */}
        </div>
        {/* ======= END ROW ======== */}
      </div>
      {/** ========== END END CONTENT REPORTS ============= */}
    </>
  );
};

export default EndContent;
