import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import iconHead from "../../assets/images/icons/note.svg";
import "./Terms.css";
import MainButton from "Components/Button/Button";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import CustomModal from "Components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
const TermsConditionsModal = ({show,setShow}) => {
  const [content, setContent] = useState("");
  const { t ,i18n} = useTranslation();
  useEffect(() => {

    axiosInstance.get("/privacy",{
      headers: {
        "Accept-Language": i18n.language,
      },
    }).then((res)=>{
      setContent(res.data.data.content)
    })
  }, []);
  return (
    <>
<CustomModal  size={"xl"} show={ show} onHide={()=>setShow(false)} >
<main dir="ltr">
        <div className="terms-conditions padding-50">
          {/* ======= START CONTAINER ======= */}
          <div className="container">
            {/* ======= START HEADER TOP TERMS ======= */}
            <div className="header-top-terms d-flex align-items-center gap-3">
              {/* ======= START ICON ======= */}
              <div className="icon-header">
                <img
                  src={iconHead}
                  alt="icon"
                  className="object-fit-cover"
                  loading="lazy"
                />
              </div>
              {/* ======= END ICON ======= */}

              {/* ======= START INFO HEADER ICON ======= */}
              <div className="info-header-icon">
                <h1 className="title"> {t("Terms and Conditions for Buildin Platform")}</h1>
                {/* <p className="text">آخر تحديث ..12 يناير 2024</p> */}
              </div>
              {/* ======= END INFO HEADER ICON ======= */}
            </div>
            {/* ======= END HEADER TOP TERMS ======= */}

            {/* ======= START CONTENT INFO  ======= */}
            <div className="content-info">
              {/* <h2 className="title">01 .الشروط</h2> dangerouslySetInnerHTML={{ __html: content }} */}
              
             {
              i18n.language =="ar"?
              <div className="info-text" >
         
    <h2>المقدمة</h2>
    <p>مرحبًا بكم في منصة Buildin. من خلال التسجيل في منصتنا، سواء كنت مستشارًا أو مقاولًا أو موردًا أو عميلًا، فإنك توافق على الالتزام بالشروط والأحكام التالية. نهدف من خلال هذه الشروط إلى توضيح حقوقك وواجباتك وضمان تقديم خدمة متميزة وآمنة لجميع المستخدمين.</p>
    
    <h2>1. تعريفات</h2>
    <p><strong>المنصة:</strong> تشير إلى منصة Buildin التي تُستخدم لتقديم وطلب عروض أسعار لمشاريع البناء.</p>
    <p><strong>المستشارين والمقاولين والموردين:</strong> يُشار إليهم مجتمعين بـ"مقدمي الخدمات"، وهم الأطراف الذين يقدمون عروض الأسعار والخدمات عبر المنصة.</p>
    <p><strong>العملاء:</strong> الأفراد أو الشركات الذين يستخدمون المنصة لطلب عروض أسعار لمشاريع البناء الخاصة بهم.</p>
    <p><strong>المستخدمين:</strong> يشمل هذا المصطلح جميع مستخدمي المنصة سواء كانوا مقدمي خدمات أو عملاء.</p>

    <h2>2. التسجيل واستخدام المنصة</h2>
    <p><strong>الحساب الشخصي:</strong> يجب على المستخدمين إنشاء حساب شخصي باستخدام معلومات دقيقة وكاملة. يتحمل المستخدم مسؤولية الحفاظ على سرية معلومات الحساب.</p>
    <p><strong>استخدام قانوني:</strong> يلتزم المستخدمون باستخدام المنصة لأغراض قانونية فقط والامتناع عن أي سلوك يمكن أن يضر بالمنصة أو بالمستخدمين الآخرين.</p>

    <h2>3. طلب وتقديم العروض</h2>
    <p><strong>العملاء:</strong> يمكن للعملاء طلب عروض أسعار من مقدمي الخدمات لمشاريعهم من خلال تقديم تفاصيل واضحة ودقيقة للمشروع.</p>
    <p><strong>مقدمو الخدمات:</strong> يجب على مقدمي الخدمات تقديم عروض أسعار واضحة ومفصلة بما يتماشى مع متطلبات العملاء.</p>
    <p><strong>التفاوض والتعاقد:</strong> يتم التفاوض والتعاقد بين العملاء ومقدمي الخدمات بشكل مباشر. لا تتحمل منصة Buildin أي مسؤولية عن دقة أو شرعية العروض المقدمة.</p>

    <h2>4. الرسوم والدفع</h2>
    <p><strong>الاشتراكات:</strong> قد تتطلب بعض الخدمات المقدمة على المنصة اشتراكًا أو دفع رسوم. سيتم توضيح هذه الرسوم في وقت التسجيل أو الاستخدام.</p>
    <p><strong>المدفوعات:</strong> تتم جميع المدفوعات بين العملاء ومقدمي الخدمات مباشرة، ولا تتدخل المنصة في المعاملات المالية بين الأطراف.</p>

    <h2>5. الملكية الفكرية</h2>
    <p><strong>المحتوى:</strong> جميع المحتويات الموجودة على المنصة، بما في ذلك النصوص والصور والشعارات، محمية بموجب قوانين الملكية الفكرية. لا يجوز للمستخدمين استخدام المحتوى بدون إذن كتابي صريح من Buildin.</p>
    <p><strong>حقوق المستخدمين:</strong> يحتفظ المستخدمون بحقوق الملكية الفكرية للمحتوى الذي ينشئونه ويشاركونه على المنصة، ولكنهم يمنحون Buildin ترخيصًا غير حصري لاستخدام هذا المحتوى لأغراض تشغيل وترويج المنصة.</p>

    <h2>6. الخصوصية والأمان</h2>
    <p><strong>البيانات الشخصية:</strong> تلتزم Buildin بحماية بيانات المستخدمين الشخصية وعدم مشاركتها مع أطراف ثالثة بدون موافقة المستخدم، إلا إذا كان ذلك مطلوبًا بموجب القانون.</p>
    <p><strong>الأمان:</strong> تتخذ المنصة تدابير أمنية معقولة لحماية بيانات المستخدمين وضمان سلامة الاتصالات الإلكترونية.</p>

    <h2>7. التعديلات على الشروط والأحكام</h2>
    <p>تحتفظ Buildin بالحق في تعديل هذه الشروط والأحكام في أي وقت. سيتم إخطار المستخدمين بأي تغييرات كبيرة، ويُعتبر استمرار استخدام المنصة بعد التعديلات قبولًا بالشروط المعدلة.</p>

    <h2>8. إخلاء المسؤولية</h2>
    <p><strong>عدم الضمان:</strong> تُقدم المنصة "كما هي" بدون أي ضمانات صريحة أو ضمنية. لا تضمن Buildin دقة أو موثوقية أي من المحتويات أو الخدمات المقدمة عبر المنصة.</p>
    <p><strong>المسؤولية المحدودة:</strong> لا تتحمل Buildin أي مسؤولية عن أي أضرار مباشرة أو غير مباشرة ناتجة عن استخدام المنصة.</p>

    <h2>9. القانون المعمول به</h2>
    <p>تخضع هذه الشروط والأحكام وتفسر وفقًا لقوانين دولة الأمارات العربية المتحدة، وأي نزاعات تنشأ عن هذه الشروط سيتم حلها أمام المحاكم المختصة في دولة الأمارات العربية المتحدة.</p>

    <h2>10. الاتصال بنا</h2>
    <p>إذا كان لديك أي أسئلة أو استفسارات حول هذه الشروط والأحكام، يرجى الاتصال بنا عبر [معلومات الاتصال].</p>

    <p>بدخولك واستخدامك لمنصة Buildin، فإنك توافق على الالتزام بهذه الشروط والأحكام. نأمل أن تستمتع بتجربتك على منصتنا وأن تجد الخدمات التي تبحث عنها بكل سهولة ويسر.</p>

    <h2>مختصر الشروط والاحكام</h2>
    <ul>
        <li><strong>التسجيل واستخدام المنصة:</strong> يتعين على المستخدمين التسجيل بمعلومات دقيقة واستخدام المنصة لأغراض قانونية فقط.</li>
        <li><strong>طلب وتقديم العروض:</strong> يمكن للعملاء طلب العروض ويجب على مقدمي الخدمات تقديم عروض واضحة ومفصلة.</li>
        <li><strong>الرسوم والدفع:</strong> قد تطبق رسوم على بعض الخدمات، ويتم الدفع مباشرة بين العملاء ومقدمي الخدمات.</li>
        <li><strong>الخصوصية والأمان:</strong> تحافظ المنصة على خصوصية بيانات المستخدمين وتتبع إجراءات أمنية لحمايتها.</li>
        <li><strong>التعديلات والإخلاء:</strong> قد تُجرى تعديلات على الشروط والمستخدمون ملزمون بالالتزام بها، وتخلي المنصة مسؤوليتها عن أي أضرار.</li>
    </ul>
              </div>:
              <div className="info-text" >
                <h2>Introduction</h2>
    <p>Welcome to the Buildin platform. By registering on our platform, whether you are a consultant, contractor, supplier, or client, you agree to abide by the following terms and conditions. Through these terms, we aim to clarify your rights and obligations and ensure the provision of outstanding and secure service to all users.</p>

    <h2>Definitions</h2>
    <p><strong>Platform:</strong> Refers to the Buildin platform used for submitting and requesting quotations for construction projects.</p>
    <p><strong>Service Providers:</strong> This term includes consultants, contractors, and suppliers, who submit quotations and services through the platform.</p>
    <p><strong>Clients:</strong> Refers to individuals or companies using the platform to request quotations for their construction projects.</p>
    <p><strong>Users:</strong> This term includes all platform users, whether service providers or clients.</p>

    <h2>Registration and Platform Use</h2>
    <p><strong>Personal Account:</strong> Users must create a personal account with accurate and complete information, and they are responsible for maintaining the confidentiality of their account information.</p>
    <p><strong>Legal Use:</strong> Users must use the platform for lawful purposes only and refrain from any behavior that may harm the platform or other users.</p>

    <h2>Requesting and Submitting Quotations</h2>
    <p><strong>Clients:</strong> Clients can request quotations from service providers for their projects by providing clear and accurate project details.</p>
    <p><strong>Service Providers:</strong> Service providers must submit clear and detailed quotations that meet the clients' requirements.</p>
    <p><strong>Negotiation and Contracting:</strong> Negotiation and contracting take place directly between clients and service providers, and the platform does not bear any responsibility for the accuracy or legitimacy of the submitted quotations.</p>

    <h2>Fees and Payments</h2>
    <p><strong>Subscriptions:</strong> Some services may require a subscription or payment of fees, which will be clarified at the time of registration or use.</p>
    <p><strong>Payments:</strong> All payments are made directly between clients and service providers, and the platform does not intervene in financial transactions between the parties.</p>

    <h2>Intellectual Property</h2>
    <p><strong>Content:</strong> All content on the platform, including texts, images, and logos, is protected by intellectual property laws, and users are not allowed to use the content without explicit written permission from Buildin.</p>
    <p><strong>User Rights:</strong> Users retain intellectual property rights to the content they create and share on the platform, but they grant Buildin a non-exclusive license to use this content for platform operation and promotion purposes.</p>

    <h2>Privacy and Security</h2>
    <p><strong>Personal Data:</strong> Buildin is committed to protecting users' personal data and not sharing it with third parties without user consent, except as required by law.</p>
    <p><strong>Security:</strong> The platform implements reasonable security measures to protect user data and ensure the safety of electronic communications.</p>

    <h2>Amendments to Terms and Conditions</h2>
    <p>Buildin reserves the right to amend these terms and conditions at any time, and users will be notified of any significant changes. Continued use of the platform after amendments constitutes acceptance of the modified terms.</p>

    <h2>Disclaimer</h2>
    <p><strong>No Warranty:</strong> The platform is provided "as is" without any express or implied warranties, and Buildin does not guarantee the accuracy or reliability of any content or services provided through the platform.</p>
    <p><strong>Limited Liability:</strong> Buildin shall not be liable for any direct or indirect damages arising from the use of the platform.</p>

    <h2>Governing Law</h2>
    <p>These terms and conditions are governed by and construed in accordance with the laws of the United Arab Emirates, and any disputes arising from these terms shall be resolved before the competent courts in the United Arab Emirates.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions or inquiries regarding these terms and conditions, please contact us at [contact information].</p>

    <p>By accessing and using the Buildin platform, you agree to abide by these terms and conditions. We hope you enjoy your experience on our platform and find the services you are looking for with ease and convenience.</p>

    <h2>Summary of Terms and Conditions</h2>
    <ul>
        <li><strong>Registration and Platform Use:</strong> Users must register with accurate information and use the platform for lawful purposes only.</li>
        <li><strong>Requesting and Providing Bids:</strong> Customers can request bids, and service providers must submit clear and detailed bids.</li>
        <li><strong>Fees and Payments:</strong> Some services may have associated fees, and payments are made directly between customers and service providers.</li>
        <li><strong>Privacy and Security:</strong> The platform safeguards user data privacy and implements security measures to protect it.</li>
        <li><strong>Amendments and Disclaimer:</strong> The platform reserves the right to amend terms, which users are bound to, and disclaims liability for any damages.</li>
    </ul>
              </div>
             }
              {/* <MainButton
                textButton={"أوافق على الشروط والأحكام"}
                newClass={"btn-terms"}
                routeButton={"/otp"}
              /> */}
            </div>
            {/* ======= END CONTENT INFO  ========= */}
          </div>
          {/* ======= END CONTAINER ======= */}
        </div>
      </main>
</CustomModal>
     
    </>
  );
};

export default TermsConditionsModal;
