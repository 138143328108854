import { useTranslation } from "react-i18next";
import image_1 from "../../../assets/images/icons/bag.svg";
import image_2 from "../../../assets/images/icons/bars.svg";
import image_3 from "../../../assets/images/icons/eye.svg";
import CardInfoDetails from "./CardInfoDetails";
import { useNavigate } from "react-router-dom";

const CardsInfo = ({ projectNumb, offersnum, views }) => {
  const { t } = useTranslation();
  const navigate=useNavigate()
  return (
    <div className="cards-info padding-top-25">
      <div className="row g-3">
        <div className="col-12 col-md-4">
          <CardInfoDetails
            image_1={image_1}
            newClass={"card-one-1"}
            titleCard={t("totalProjects")}
            NumCounter={projectNumb}
            iconCounter={""}
            NumK={undefined}
            badgeInfo={undefined}
            numBadge={undefined}
            onClick={()=>navigate("/myProjects")}
          />
        </div>
        <div className="col-12 col-md-4">
          <CardInfoDetails
            image_1={image_2}
            newClass={"card-one-2"}
            titleCard={t("newOffers")}
            NumCounter={offersnum}
            iconCounter={""}
            NumK={undefined}
            badgeInfo={undefined}
            numBadge={undefined}
            onClick={()=>navigate("/offers")}

          />
        </div>
        <div className="col-12 col-md-4">
          <CardInfoDetails
            image_1={image_3}
            newClass={"card-one-3"}
            titleCard={t("totalViews")}
            NumK={""}
            NumCounter={views}
            iconCounter={""}
            badgeInfo={undefined}
            numBadge={undefined}
            onClick={()=>navigate("/myProjects")}

          />
        </div>
      </div>
    </div>
  );
};

export default CardsInfo;
