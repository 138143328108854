import { useTranslation } from "react-i18next";
import imgAbout from "../../../assets/LandingPageImages/main/01.png"
import imgShape from "../../../assets/LandingPageImages/shape/Blueprint.png"
import iconDownload from "../../../assets/LandingPageImages/icons/import.svg"
import "./About.css";
import mainImage from "../../../assets/LandingPageImages/main/bulidin.jpeg";

const About = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* ========= START SECTION ABOUT APP =========== */}
      <section className="about-app padding position-relative" id="about-app">
        <div className="shape">
          <img
            className="w-100 h-100 object-fit-cover"
            src={imgShape}
            alt="img shape"
          />
        </div>
        {/* ========== START CONTAINER ========== */}
        <div className="container">
          {/* ========== START ALL ABOUT APP ============ */}
          <div className="all-about-app">
            {/* =========== START ROW ========== */}
            <div className="row g-3 flex-wrap-reverse gy-5">
              {/* ============ START COL =========== */}
              <div className="col-12 col-lg-12 col-xl-6">
                {/* ============= START INFO ABOUT APP =========== */}
                <div className="info-about-app" data-aos="fade-left">
                  {/* ======== START SECTION TITLE INFO =========== */}
                  <div className="section-title-info">
                    <h2 className="title-head">Buildin</h2>
                    <h2 className="title">
                      {t("The first application in the United Arab Emirates that brings together project owners and construction experts")}
                    </h2>
                  </div>
                  {/* ======== END SECTION TITLE INFO =========== */}
                  <p className="text">
                    {t("The first application in the United Arab Emirates where you can display projects and receive financial offers from contractors, consultants, and suppliers to get the best service and the best price. Through the application, you can request company management to manage the project, as contractors, consultants, and suppliers can browse projects, submit financial offers, and create a company profile containing achievements and a brief overview of the company.")}
                  </p>
                  <a href="#download-app" className="btn-main-01">
                    {t("Download the App")}
                    <img
                      src={iconDownload}
                      alt="icon"
                      width="24px"
                      height="24px"
                    />
                  </a>
                </div>
                {/* ============= END INFO ABOUT APP =========== */}
              </div>
              {/* ============ END COL =========== */}
              {/* ============ START COL =========== */}
              <div className="col-12 col-lg-12 col-xl-6">
                {/* ============== START IMAGE ABOUT APP ============== */}
                <div className="image-about-app" data-aos="fade-up">
                  <img
                    src={mainImage}
                    alt="img app"
                    className="w-100 h-100 object-fit-cover rounded-3"
                  />
                </div>
                {/* ============== END IMAGE ABOUT APP ============== */}
              </div>
              {/* ============ END COL =========== */}
            </div>
            {/* =========== END ROW ========== */}
          </div>
          {/* ========== END ALL ABOUT APP ============ */}
        </div>
        {/* ========== END CONTAINER ========== */}
      </section>
      {/* ========= END SECTION ABOUT APP =========== */}
    </>
  );
};

export default About;
