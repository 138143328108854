import React from "react";

function ImageLoginForm({ ImageLoginPic }) {
  return (
    <>
      {/* ========= START IMAGE LOGIN ======= */}
      <div className="image-login">
        <img
          src={ImageLoginPic}
          alt="img Login"
          className="w-100 h-100 object-fit-cover"
        />
      </div>
      {/* ========= END IMAGE LOGIN ======= */}
    </>
  );
}

export default ImageLoginForm;
