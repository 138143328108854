// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBpaf7Nla3sku10120iCq5pFJW5MHv3enk",
    authDomain: "buildin-79001.firebaseapp.com",
    projectId: "buildin-79001",
    storageBucket: "buildin-79001.appspot.com",
    messagingSenderId: "449556068847",
    appId: "1:449556068847:web:e90a24d42ff802ffd35e1a",
    measurementId: "G-YQR94F0HWE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 const messaging = getMessaging(app);

  export { messaging };