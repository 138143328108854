import WorkHeaderInfo from "Pages/WorkInformation/WorkHeaderInfo/WorkHeaderInfo";
import "./projectManagementRequest.css";
import * as Yup from "yup";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import ModalRequestAndAddProject from "Components/ModalRequestAndAddProject/ModalRequestAndAddProject";
import { useEffect, useState } from "react";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
import CustomReactSelect from "Components/Forms/ReactSelect/ReactSelect";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";



const initialValues = {
  titleProject: "",
  textAreaDetails: "",
  titleRegion: "",
};

const ProjectManagementRequest = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    titleProject: Yup.string().required(t("projectNameRequired")),
    textAreaDetails: Yup.string().required(t("projectDetailsRequired")),

  });
  // FORM SUBMIT
  const [category, setCategory] = useState();
  const [City, setCity] = useState();
  const [SelectedCategory, setSelectedCategory] = useState();
  const [SelectedCity, setSelectedCity] = useState();
  const [SelectedCategoryError, setSelectedCategoryError] = useState();
  const [SelectedCityError, setSelectedCityError] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesError, setSelectedFilesError] = useState(false);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [selectedFilesError2, setSelectedFilesError2] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  useEffect(() => {
    axiosInstance
      .get("/category")
      .then((res) => {
        const Options = res?.data?.data?.map((item) => {
          return { value: item.id, label: item.title };
        });

        setCategory(Options);
      })
      .catch((error) => {
        toast.error(t("errorOccurred"));
      });
  }, []);
  
  useEffect(() => {
    axiosInstance
      .get("/city")
      .then((res) => {
        const Options = res.data.data.map((item) => {
          return { value: item.id, label: item.title };
        });

        setCity(Options);
      })
      .catch((error) => {
        toast.error(t("errorOccurred"));
      });
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    const isEmpty =
      !values.titleProject && !values.textAreaDetails && !values.titleRegion;

    if (
      !selectedFiles2.length ||
      !SelectedCity
    ) {
      setSelectedFilesError2(!selectedFiles2?.length);
      setSelectedCityError(!SelectedCity);
      return;
    }

    if (isEmpty) {
      console.log(t("pleaseAddValueInput"));
    } else {
      const formData = new FormData();
      formData.append("title", values.titleProject);
      formData.append("content", values.textAreaDetails);
      formData.append("city_id", SelectedCity.value);
      formData.append("region", values.titleRegion);
      selectedFiles2.map((item, index) => {
        formData.append(`files[${index}]`, selectedFiles2[index].file);
      });
      setLoading(true)
      axiosInstance
        .post("/projectrequest", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        })
        .then((res) => {
          console.log(res.data);
          handleOpenModalSuccess();
          setLoading(false)
        })
        .catch((error) => {
          toast.error(t("errorOccurred"));
        });
    }
  };

  // OEPN ANDCLOSE MODAL
  const [showModal, setShowModalSuccess] = useState(false);
  // CLOSE MODAL
  const handleCloseModal = () => {
    setShowModalSuccess(false);
    navigate('/home')

  };
  // OPEN MODAL
  const handleOpenModalSuccess = () => {
    setShowModalSuccess(true);
  };

  return (
    <>
      <HelmetInfo titlePage={t("projectRequest")} />

      <ModalRequestAndAddProject
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="project-management-request all-main">
        <WorkHeaderInfo
          title={t("projectRequest")}
          text={t("requestCompanyManageProject")}
        />
        <div className="form-project-management">
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <div className="row g-3">
              <div className="col-12">
                <InputFiled
                  label={t("projectName")}
                  name="titleProject"
                  type="text"
                  placeholder=""
                  success
                />
              </div>
              <div className="col-12">
                <TextAreaInput
                  label=""
                  name="textAreaDetails"
                  type="text"
                  placeholder={t("projectDetails")}
                  success
                />
              </div>
              <div className="col-12">
                <CustomReactSelect
                  options={City}
                  placeholder={t("emirate")}
                  changeValue={setSelectedCity}
                />
              </div>
              <div className="col-12">
                <InputFiled
                  label={t("region")}
                  name="titleRegion"
                  type="text"
                  placeholder=""
                  success
                />
              </div>
              <div className="col-12">
                <div className="file-upload-manage ">
                  <FileUpload
                    title={t("attachProjectFile")}
                    text={"pdf/doc/autocad"}
                    showText={false}
                    SelectedData={setSelectedFiles2}
                  />
                </div>
              </div>
              <button disabled={loading} className="btn-main w-100" type={"submit"}>
                {loading ? <span className="loader"></span> :t("submitRequest")}
              </button>
            </div>
          </FormField>
        </div>
      </div>
    </>
  );
};

export default ProjectManagementRequest;
