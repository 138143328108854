import CustomModal from "Components/CustomModal/CustomModal";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import FormEditWork from "./FormEditWork";
import SetLocationTab from "./SetLocationTab";
import ModalSaveLocation from "./ModalSaveLocation";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import { toast } from "react-toastify";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const ProtfolioEditModal = ({
  data,
  refetch,
  showModalEditWork,
  handleCloseModalEditWork,
}) => {
  const [loading, setLoaidng] = useState(false);
  const [files, setFiles] = useState([]);
  const SubmitFiles = () => {
    console.log(refetch);
    setLoaidng(true);
    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`work_profile[${index}]`, file.file);
      });

      axiosInstance
        .post("/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // Handle success
          setLoaidng(false);
          setFiles([]);
          refetch();
        })
        .catch((error) => {
          // Handle error
          setLoaidng(false);
          toast.error(error?.response.data.message);
        });
    } else {
      toast.error(t("pleaseUploadImages"));
    }
  };
  const { t } = useTranslation();
  const [showModalSaveLocation, setShowModalSaveLocation] = useState(false);

  const toastId = React.useRef(null);

  const notify = () =>
    (toastId.current = toast(t("removing is pending"), { autoClose: false }));
  const update = () =>
    toast.update(toastId.current, {
      render: t("image deleted succesfully"),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
    });
  return (
    <CustomModal
      show={showModalEditWork}
      onHide={handleCloseModalEditWork}
      title={t("Work Gallery")}
      newClass={"modal-account modal-account-tabs"}
    >
      <div className="form-upload">
        <FileUpload
          title={t("workGallery")}
          text={"PNG / JPJ"}
          // showText={t("maxTenImages")}
          SelectedData={setFiles}
          profileEdit={true}
          dataUpdate={files}
          accept="image/*"
        />

        <div className="business-gallery mt-4">
          {/* ========== START ROW ========== */}
          <div className="row g-3">
            {data?.work_profile?.length > 0 && data?.work_profile[0] != "" ? (
              data?.work_profile.map((itemGallery) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4">
                    {/* ========= START PRODUCT GALLERY ONE ========= */}

                    <div className="ImageProfileContainer">
                      <button
                        className="button-remove-up"
                        onClick={() => {
                          notify();
                          axiosInstance
                            .post("/removeImage", {
                              image: itemGallery,
                            })
                            .then((res) => {
                              update();
                              refetch();
                            });
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                      <a
                        href={itemGallery}
                        target="_blank"
                        className="product-gallery-one"
                      >
                        {/* ========= START IMAGE PRODUCT GALLRRY ======= */}
                        <div className="image-product-gallery">
                          <img
                            src={itemGallery}
                            alt="imageGallery"
                            className="object-fit-cover "
                            style={{
                              borderRadius: "10px",
                              width: "280px",

                              height: "180px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                        {/* ========= END IMAGE PRODUCT GALLRRY ======= */}
                        <div className="info-product-gallery mt-3">
                          <h2 className="title"></h2>
                        </div>
                      </a>
                    </div>

                    {/* ========== END PRODUCT GALLERY ONE ========= */}
                  </div>
                );
              })
            ) : (
              <NoResults text={t("No Work Portfolio")} />
            )}
          </div>
          {/* ========== END ROW ========== */}
        </div>
        <button
          className="submit-upload w-100 btn-main"
          onClick={() => {
          if(files.length > 0){
            SubmitFiles();
          }
        else handleCloseModalEditWork()
        }}
        >
          {loading ? <span className="loader"></span> : t("save")}
        </button>
      </div>
    </CustomModal>
  );
};

export default ProtfolioEditModal;
