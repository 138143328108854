import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import userImage from "../../assets/images/user/userImage.png";
import "./Chat.css";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import ChatPanel from "./chatPanel";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "moment/locale/ar";
import moment from "moment";
import NoResults from "Components/NotFound/noResults";
const Chat = () => {
  const [chats, setChats] = useState([]);
  const [fitleredChats, setFitleredChats] = useState([]);
  const { i18n } = useTranslation();
  const location = useLocation();
  const [activeUser, setActiveUser] = useState(null);
  const { t } = useTranslation();
  const userId = useSelector((state) => state.auth.userId);
  const refetch = () => {
    axiosInstance
      .get("/chat", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setChats(res.data.data);
      });
  };
  useEffect(() => {
    console.log(activeUser);
  }, [activeUser]);
  useEffect(() => {
    const pusher = new Pusher("6c28f2175d22ab159957", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("my-channel");
    // channel.bind("my-event", function (data) {
    //   setData(data);
    // });

    channel.bind("App\\Events\\FlutterEvent", function (data) {
      console.log("dsad", data);
      if (data.to == userId) {
        axiosInstance
          .get("/chat", {
            headers: {
              "Accept-Language": i18n.language,
            },
          })
          .then((res) => {
            setChats(res.data.data);
          });
      }
    });

    return () => {
      pusher.unsubscribe("my-channel");
      pusher.disconnect();
    };
  }, []);
  useEffect(() => {
    axiosInstance
      .get("/chat", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setChats(res.data.data);
        setFitleredChats(res.data.data);
      });
  }, []);

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      setActiveUser(location.state);
    } else {
      let chatLastElement = chats[chats.length - 1];
      if (chatLastElement) {
        if (chatLastElement.sender.id == userId) {
          setActiveUser(chatLastElement.reciever);
        } else {
          setActiveUser(chatLastElement.sender);
        }
      }
    }
  }, [location, chats, userId]);

  return (
    <>
      <HelmetInfo titlePage={"محادثة"} />

      <div className="all-chat-info margin-top-content">
        {/* ========= START ROW ========= */}
        <div className="row g-3">
          {/* ========= START COL =========== */}
          {chats.length > 0 || activeUser ? (
            <>
              <div className="col-12 col-md-6 col-xl-4">
                {/* ========= START FILTER MESSAGES ============= */}
                <div className="all-filter-products filter-message">
                  {/* ======== START  SEARCH INPUT ========= */}
                  {chats.length > 0 && (
                    <div className="search-filter">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="إبحث هنا..."
                        onChange={(e) => {
                          const searchContent = e.target.value;
                          const filtered = chats.filter((chat) => {
                            let user =
                              chat.sender.id == userId
                                ? chat.reciever
                                : chat.sender;
                            return user.name.includes(searchContent);
                          });
                          setFitleredChats(filtered);
                        }}
                      />
                      <div className="icon-search">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                            fill="#B0B0B0"
                          />
                          <path
                            d="M21.9995 22.7499C21.8095 22.7499 21.6195 22.6799 21.4695 22.5299L19.4695 20.5299C19.1795 20.2399 19.1795 19.7599 19.4695 19.4699C19.7595 19.1799 20.2395 19.1799 20.5295 19.4699L22.5295 21.4699C22.8195 21.7599 22.8195 22.2399 22.5295 22.5299C22.3795 22.6799 22.1895 22.7499 21.9995 22.7499Z"
                            fill="#B0B0B0"
                          />
                        </svg>
                      </div>
                    </div>
                  )}

                  {/* ======== END SEARCH INPUT ========= */}
                  {/* ========= START INFO MESSAGES CONTENT ============ */}
                  <div className="info-message-content">
                    {/* ========= START MESSAGE ONE ======= */}
                    {userId && fitleredChats.length > 0 ? (
                      fitleredChats?.map((chat) => {
                        let user =
                          chat.sender.id == userId
                            ? chat.reciever
                            : chat.sender;
                        console.log(
                          chat.sender,
                          chat.sender.id == userId,
                          userId
                        );
                        return (
                          <div
                            style={{ cursor: "pointer" }}
                            className={`message-one  ${
                              chat.unread_count > 0 && "new-message"
                            }`}
                            onClick={() => setActiveUser(user)}
                          >
                            <div className="user-info">
                              <img
                                src={user.image}
                                alt="User"
                                className="user-avatar"
                              />
                              <div className="text-user">
                                <div className="head-message">
                                  <span className="name-user">{user.name}</span>
                                  <div className="date-message">
                                    {moment(chat?.created_at)
                                      .locale("ar")
                                      .format("YYYY-MM-DD hh:mm A")}
                                  </div>
                                </div>
                                <p className="text-user-message">
                                  {chat.last_message.message}
                                </p>
                              </div>
                            </div>
                            <div className="badge-num">{chat.unread_count}</div>
                          </div>
                        );
                      })
                    ) : (
                      <div>{t("noChats")}</div>
                    )}

                    {/* ========= END MESSAGE ONE ======= */}
                    {/* ========= START MESSAGE ONE ======= */}

                    {/* ========= END MESSAGE ONE ======= */}
                  </div>
                  {/* ========= END INFO MESSAGES CONTENT ============ */}
                </div>
                {/* ========= END FILTER MESSAGES ============= */}
              </div>
              {/* ========= END COL =========== */}
              {/* ========= START COL =========== */}
              <div className="col-12 col-md-6 col-xl-8">
                {/* ============ START CHATE CONTAINER ============== */}
                {chats.length > 0 || activeUser ? (
                  <ChatPanel
                    activeUser={activeUser}
                    newChat={location.state?.newChat}
                    chats={chats}
                    refetch={refetch}
                  />
                ) : (
                  <div className="chat-container">{t("noChats")}</div>
                )}

                {/* ============ END CHATE CONTAINER ============== */}
              </div>
            </>
          ) : (
            <div className="chat-container">
              {" "}
              <NoResults text={"noChats"} />
            </div>
          )}
          {/* ========= END COL =========== */}
        </div>
        {/* ========= END ROW ========= */}
      </div>
    </>
  );
};

export default Chat;
