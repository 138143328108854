import CustomModal from "Components/CustomModal/CustomModal";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import FormEditWork from "./FormEditWork";
import SetLocationTab from "./SetLocationTab";
import ModalSaveLocation from "./ModalSaveLocation";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import { toast } from "react-toastify";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const ModalEditWorkAccount = ({
  showModalEditWork,
  handleCloseModalEditWork,
  data,
  refetch,
}) => {
  const [loading, setLoaidng] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log(data, "datadata");
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [selectedFiles3, setSelectedFiles3] = useState([]);
  const { t } = useTranslation();
  const [showModalSaveLocation, setShowModalSaveLocation] = useState(false);

  const handleCloseModalSaveLocation = () => {
    setShowModalSaveLocation(false);
  };

  const handleShowModalWork = (e) => {
    e.preventDefault();
    setShowModalSaveLocation(true);
    handleCloseModalEditWork();
  };

  const [files, setFiles] = useState([]);

  const toastId = React.useRef(null);

  const notify = () =>
    (toastId.current = toast(t("removing is pending"), { autoClose: false }));

  const update = () =>
    toast.update(toastId.current, {
      render: t("image deleted succesfully"),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
    });
  const SubmitFiles = () => {
    console.log(refetch);
    setLoaidng(true);
    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`work_profile[${index}]`, file.file);
      });

      axiosInstance
        .post("/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          // Handle success
          setLoaidng(false);
          setFiles([]);
          refetch();
        })
        .catch((error) => {
          // Handle error
          setLoaidng(false);
          toast.error(t("error"));
        });
    } else {
      toast.error(t("pleaseUploadImages"));
    }
  };

  return (
    <>
      <ModalSaveLocation
        showModalSaveLocation={showModalSaveLocation}
        handleCloseModalSaveLocation={handleCloseModalSaveLocation}
      />
      <CustomModal
        show={showModalEditWork}
        onHide={handleCloseModalEditWork}
        title={t("editWorkInfoTitle")}
        newClass={"modal-account modal-account-tabs"}
      >
        <div className="all-work-edit">
          <p className="text">{t("enterWorkInfo")}</p>
          <div className="main-tabs-info">
            <div className="tabs-content tabs-content-cards">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="profile" title={t("basicData")}>
                  <FormEditWork
                    setSelectedFiles={setSelectedFiles}
                    setSelectedFiles2={setSelectedFiles}
                    setSelectedFiles3={setSelectedFiles3}
                    selectedFiles={selectedFiles}
                    selectedFiles2={selectedFiles2}
                    selectedFiles3={selectedFiles3}
                    data={data}
                    refetch={refetch}
                  />
                </Tab>
                {/* <Tab eventKey="profile-2" title={t("workGallery")}>
                  <div className="form-upload">
                    <FileUpload
                      title={t("workGallery")}
                      text={"PNG / JPJ"}
                      showText={t("maxTenImages")}
                      SelectedData={setFiles}
                      profileEdit={true}
                      dataUpdate={files}
                    />
               
                    <button
                      className="submit-upload w-100 btn-main"
                      onClick={() => {
                        SubmitFiles();
                      }}
                    >
                      {loading ? <span className="loader"></span>  :t("save")}
                    </button>
                  </div>
                </Tab> */}
                <Tab eventKey="profile-3" title={t("contactData")}>
                  <SetLocationTab
                    refetch={refetch}
                    data={data}
                    handleCloseModalEditWork={handleCloseModalEditWork}
                    handleShowModalWork={handleShowModalWork}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ModalEditWorkAccount;
