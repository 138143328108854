import MainButton from "Components/Button/Button";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation } from "swiper/modules";
import imageBanner1 from "../../../assets/images/main/banner.png";
import imageBanner2 from "../../../assets/images/main/banner1.png";
import imageBanner3 from "../../../assets/images/main/banner2.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
const BannerSlide = ({ data }) => {
  return (
    <>
      <div
        className="banner-main-area"
        key={
          data.id === undefined ? Math.floor(Math.random() * 10) + 1 : data.id
        }
      >
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Autoplay]}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          className="mySwiper"
        >
          {data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {/* ========= START BANNER ONE ======== */}
                <div
                  className="banner-one section-padding bg-image"
                  key={index}
                  style={{
                    backgroundImage: `url(${item.image})`,
                    minHeight: "314px",
                    maxHeight: "314px",
                  }}
                >
                  {/* ======== START INFO BANNER ========= */}
                  <div className="info-banner position-relative z-1">
                    {/* ======= START CONTAINER =========== */}
                    <div className="container">
                      {/* =========== START ROW ======== */}
                      <div className="row g-3 align-items-center justify-content-between">
                        {/* ========== START COL ========== */}
                        <div className="col-12 col-md-12 col-lg-12">
                          {/* ========= START CONTENT SLIDE ========== */}
                          <div className="content-slide">
                            <h1 className="title animationRight">
                              {item?.title}
                            </h1>
                          </div>
                          {/* ========= END CONTENT SLIDE ============ */}
                        </div>
                        {/* ========== END COL ========== */}
                      </div>
                      {/* =========== END ROW ========== */}
                    </div>
                    {/* ======== END CONTAINER =========== */}
                  </div>
                  {/* ======== END INFO BANNER ========== */}
                </div>
                {/* ======== END BANNER ONE ========= */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default BannerSlide;
