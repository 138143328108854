import HeaderForm from "Components/Forms/InfoHeaderForm/HeaderForm";
import { useTranslation } from "react-i18next";


const HeaderSteps = () => {
  const {t,i18n}=useTranslation()
  return (
    <>
      {/* ========== START HEADER FORM LOG ========= */}
      <HeaderForm
        titleHeaderForm={t("Log in as")}
        textHeaderForm={t("Please,choose the appropriate means of entry for you")}
      />
      {/* ========== END HEADER FORM LOG ========= */}
    </>
  );
};

export default HeaderSteps;
