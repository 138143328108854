import { Link } from "react-router-dom";
import LogoLogin from "../../../assets/images/logo/logo.png"

const LogoImageForm = () => {
  return (
    <Link to={"/"} className="logo-login">
      <img
        src={LogoLogin}
        alt="logo login"
        className="w-100 h-100 object-fit-cover"
      />
    </Link>
  );
};

export default LogoImageForm;
