import "./SectionTitle.css"
const SectionTitle = ({titleSection, text, dataAnimationType}) => {
  return (
    <>
      {/* ======== START SECTION TITLE INFO =========== */}
      <div
        className="section-title-info center-section-head"
        data-aos={dataAnimationType}
      >
        <h2 className="title-head">{titleSection}</h2>
        <h2 className="title">{text}</h2>
      </div>
      {/* ======== END SECTION TITLE INFO =========== */}
    </>
  );
};

export default SectionTitle;
