import React, { useState } from "react";
import ModalEditInfoAccount from "./ModalAccount/ModalEditInfoAccount";
import ModalEditWorkAccount from "./ModalAccount/ModalEditWorkAccount";


const EditInfoAccount = ({
  userIcon,
  title,
  iconEdit,
  isAciveEditInfoAccountButton,
  isAciveEditInfoWorkButton,
  data,
  refetch
}) => {
  // OEPN ANDCLOSE MODAL EDIT INFO ACCOUNT
  const [showModalEdit, setShowModalEdit] = useState(false);
  // CLOSE MODAL
  const handleCloseModal = () => {
    setShowModalEdit(false);
  };
  // OPEN MODAL
  const handleShowModal = () => {
    setShowModalEdit(true);
  };
  // OEPN ANDCLOSE MODAL EDIT INFO WORK
  const [showModalEditWork, setShowModalEditWork] = useState(false);
  // CLOSE MODAL
  const handleCloseModalEditWork = () => {
    setShowModalEditWork(false);
  };
  // OPEN MODAL
  const handleShowModalWork = () => {
    setShowModalEditWork(true);
  };

  


  return (
    <>
      <ModalEditInfoAccount
        showModalEdit={showModalEdit}
        handleCloseModalEdit={handleCloseModal}
        data={data}
        refetch={refetch}
      />
      <ModalEditWorkAccount
        showModalEditWork={showModalEditWork}
        handleCloseModalEditWork={handleCloseModalEditWork}
        data={data}
        refetch={refetch}
      />

      <div className="edit-info">
        <div className="icon-info">
          <img src={userIcon} alt="icon edit" />
        </div>
        <div className="info-text">
          <h2 className="title">{title}</h2>
          {isAciveEditInfoAccountButton && (
            <div onClick={handleShowModal} className="icon-edit">
              <img src={iconEdit} alt="icon edit" />
            </div>
          )}
          {isAciveEditInfoWorkButton && (
            <div onClick={handleShowModalWork} className="icon-edit">
              <img src={iconEdit} alt="icon edit" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditInfoAccount;
