import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (builder) => ({
    getProductsByName: builder.query({
      query: (name) => ``
    })
  })
});

export const { useGetProductsByNameQuery } = productApi;
