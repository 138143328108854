import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "Components/CustomModal/CustomModal";
import PackagesCard from "Components/PackagesCard/PackagesCard";
import imgIcon from "../../../assets/images/icons/crown-2.svg";

const PackageInformationModal = ({ showModal, handleCloseModal }) => {
  const title = "معلومات الباقة";
  return (
    <>
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={title}
        newClass={"card-details-info modalAccount modal-packages"}
      >
        <PackagesCard
          isActiveRibbon={true}
          imgIcon={imgIcon}
          titlePackage={"الباقة الذهبية"}
          textPackage={
            "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق."
          }
          numPricing={"500"}
          dateType={"شهر"}
          moneyType={"درهم"}
          iconTypeCheck={faCheck}
          textIconPricing={"إظهار بالبداية عند البحث"}
          textButton={"إشتراك"}
          isActiveProgress={false}
          isActiveBottomInfo={false}
          dateInfoBottom={false}
          textContentBottom={false}
          isActiveButtonSubscription={true}
          isActiveButtonCancel={false}
          isActiveButtonReload={false}
          newClass={"package-details-modal"}
          ribbonText={"نرشحها لك"}
          textProgressDate={false}
          textManyDay={false}
          iconOne={undefined}
          iconOne2={undefined}
          dateInfoBottom2={undefined}
          textContentBottom2={undefined}
          iconOne3={undefined}
          dateInfoBottom3={undefined}
          textContentBottom3={undefined}
        />
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default PackageInformationModal;
