import "./WorkInformation.css";
import WorkComponent from "./WorkComponent/WorkComponent";
import WorkSteps from "./WorkSteps/WorkSteps";
import { useState } from "react";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import * as Yup from "yup";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import MainButton from "Components/Button/Button";
import FileUpload from "./FileUpload/FileUpload";
import HeaderTop from "./HeaderTopNav/HeaderTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const validationSchema = Yup.object().shape({
  textAreaBrief: Yup.string()
    .min(10, "المحتوى يجب ان يكون على الاقل 10 كلمات")
    .max(200, "المحتوى يجب ان يكون اقل من 200 كلمة")
    .required("الرجاء إدخال نبذة تعريفية."),
  textAreaDescription: Yup.string()
    .min(10, "المحتوى يجب ان يكون على الاقل 10 كلمات")
    .max(200, "المحتوى يجب ان يكون اقل من 200 كلمة")
    .required("الرجاء إدخال  وصف النشاط التجاري"),
  numberCurrentBusinesses: Yup.string().required(
    "الرجاء إدخال عدد الأعمال الحالية"
  ),
  numberpreviousBusinesses: Yup.string().required(
    "الرجاء إدخال عدد الأعمال السابقة"
  ),
  numberEmployees: Yup.string().required("الرجاء إدخال عدد  الموظفين"),
  commercialRegistrationNo: Yup.string().required(
    "الرجاء إدخال  رقم السجل التجاري"
  )
});

const initialValues = {
  textAreaBrief: "",
  textAreaDescription: "",
  numberCurrentBusinesses: "",
  numberpreviousBusinesses: "",
  numberEmployees: "",
  commercialRegistrationNo: ""
};

const WorkInformation = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  // UPLOAD IMAGE OR FILES
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const updatedFiles = [];
      for (let i = 0; i < files.length; i++) {
        updatedFiles.push({
          file: files[i],
          name: files[i].name,
          type: files[i].type,
          preview: URL.createObjectURL(files[i]) // Create preview URL for images
        });
      }
      setSelectedFiles([...selectedFiles, ...updatedFiles]);
    }
    // FILTER ARRAY IF THIS ELEMET IS DIFINED NOT ADD IN ARRAY
    const filesArray = Array.from(files).filter((file) => {
      const found = selectedFiles.find(
        (existingFile) => existingFile.name === file.name
      );
      return !found;
    });
    if (filesArray.length === 0) {
      setSelectedFiles(selectedFiles);
      toast.error("تم أضافة هذا العنصر سابقا", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000 // Auto-close the toast after 2 seconds
      });
      return;
    }

    toast.success("تم الأضافة بنجاح", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000 // Auto-close the toast after 2 seconds
    });
  };

  // REMOVE IMAGE OR FILE
  const handleDelete = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    toast.success("تم الحذف بنجاح", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000 // Auto-close the toast after 2 seconds
    });
  };
  // FORM SUBMIT
  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log(values);
    resetForm({ values: "" });
  };

  return (
    <>
      <HelmetInfo titlePage={"معلومات العمل"} />
      <div className="work-information">
        {/* ========= START CONTAINER ========= */}
        <div className="container-fluid container-md">
          {/* ========= START ALL WORK INFORATION ========= */}
          <div className="all-work-information">
            <HeaderTop />

            {/* ============ START MAIN ========== */}
            <main>
              {/* ============ START ALL MAIN ========== */}
              <div className="all-main">
                {/* ========== START HEADER TOP ======= */}
                <WorkComponent />
                {/* ========== END HEADER TOP ======= */}
                <WorkSteps
                  addActiveStep1={"active"}
                  addActiveStep2={"active"}
                  addActiveStep3={""}
                  activeText={""}
                />

                {/* ========= START UPLOAD IMAGE ========== */}
                <div className="image-uploader file-up">
                  <div className="file-upload-input">
                    <div className="all-info-upload d-flex align-items-center gap-3">
                      {/* ======= START ICON UPLOAD ========= */}
                      <div className="icon-upload">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M14.4398 7.24988C14.2498 7.24988 14.0598 7.17988 13.9098 7.02988L11.8798 4.99988L9.84977 7.02988C9.55977 7.31988 9.07977 7.31988 8.78977 7.02988C8.49977 6.73988 8.49977 6.25988 8.78977 5.96988L11.3498 3.40988C11.6398 3.11988 12.1198 3.11988 12.4098 3.40988L14.9698 5.96988C15.2598 6.25988 15.2598 6.73988 14.9698 7.02988C14.8198 7.17988 14.6298 7.24988 14.4398 7.24988Z"
                            fill="#002C63"
                          />
                          <path
                            d="M11.8799 14.9298C11.4699 14.9298 11.1299 14.5898 11.1299 14.1798V4.00977C11.1299 3.59977 11.4699 3.25977 11.8799 3.25977C12.2899 3.25977 12.6299 3.59977 12.6299 4.00977V14.1798C12.6299 14.5998 12.2899 14.9298 11.8799 14.9298Z"
                            fill="#002C63"
                          />
                          <path
                            d="M12 20.75C6.85 20.75 3.25 17.15 3.25 12C3.25 11.59 3.59 11.25 4 11.25C4.41 11.25 4.75 11.59 4.75 12C4.75 16.27 7.73 19.25 12 19.25C16.27 19.25 19.25 16.27 19.25 12C19.25 11.59 19.59 11.25 20 11.25C20.41 11.25 20.75 11.59 20.75 12C20.75 17.15 17.15 20.75 12 20.75Z"
                            fill="#002C63"
                          />
                        </svg>
                      </div>
                      {/* ======= END ICON UPLOAD ========= */}
                      <div className="text-upload">
                        <h2 className="text">اللوجو / الصورة</h2>
                      </div>
                    </div>
                    <input type="file" onChange={handleFileChange} />
                  </div>
                  {/* ========= START FILES UPLOADED ======== */}
                  <div className="all-files-up">
                    {selectedFiles.map((file, index) => (
                      <li key={index}>
                        {file.type.startsWith("image/") ? (
                          <div className="main-upload-info">
                            <img
                              src={file.preview}
                              alt={file.name}
                              style={{ width: "100px", height: "auto" }}
                            />
                            <button
                              className="button-remove-up"
                              onClick={() => handleDelete(index)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        ) : (
                          <div className="main-upload-info">
                            <span>{file.name}</span>
                            <button
                              className="button-remove-up"
                              onClick={() => handleDelete(index)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        )}
                      </li>
                    ))}
                  </div>

                  {/* ========= END FILES UPLOADED ======== */}
                </div>
                {/* ========= END UPLOAD IMAGE ========== */}
                {/* ========= START FORM INFO  ========== */}
                <div className="form-info">
                  <FormField
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    <div className="row g-3">
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-12">
                        <TextAreaInput
                          label=""
                          name="textAreaBrief"
                          type="text"
                          placeholder="نبذة تعريفية"
                          success
                        />
                      </div>
                      {/* ========= END COL ======== */}

                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-12">
                        <TextAreaInput
                          label=""
                          name="textAreaDescription"
                          type="text"
                          placeholder="وصف النشاط التجاري"
                          success
                        />
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-4">
                        <InputFiled
                          label="عدد الأعمال الحالية"
                          name="numberCurrentBusinesses"
                          type="number"
                          placeholder=""
                          success
                          iconInput={""}
                        />
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-4">
                        <InputFiled
                          label="عدد الأعمال السابقة"
                          name="numberpreviousBusinesses"
                          type="number"
                          placeholder=""
                          success
                          iconInput={""}
                        />
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-4">
                        <InputFiled
                          label="عدد الموظفين"
                          name="numberEmployees"
                          type="number"
                          placeholder=""
                          success
                          iconInput={""}
                        />
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-6">
                        <InputFiled
                          label="رقم السجل التجاري"
                          name="commercialRegistrationNo"
                          type="number"
                          placeholder=""
                          success
                          iconInput={""}
                        />
                      </div>
                      {/* ========= END COL ======== */}
                      {/* ========= START COL ======== */}
                      <div className="col-12 col-md-6">
                        <select className="form-select mt-3">
                          <option selected>فئة الرخصة</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      {/* ========= END COL ======== */}
                    </div>
                    <div className="files-up">
                      <FileUpload
                        title={"صورة الرخصة"}
                        text={"png/jpg"}
                        showText={false}
                      />
                      <FileUpload
                        title={"إرفق ملف الشركة"}
                        text={"pdf/doc"}
                        showText={false}
                      />
                      <FileUpload
                        title={"معرض الأعمال"}
                        text={"PNG/JPG"}
                        showText={true}
                      />
                    </div>

                    <MainButton
                      textButton={"إستمرار"}
                      newClass={"continuation-button mian-btn w-100"}
                      routeButton={"/contactInformation"}
                    />
                    <MainButton
                      textButton={"حفظ وإستكمال البيانات لاحقا"}
                      newClass={"save-button button-home w-100"}
                      routeButton={undefined}
                    />
                  </FormField>
                </div>
                {/* ========= END FORM INFO ========== */}
              </div>
              {/* ============ START ALL MAIN ========== */}
            </main>
            {/* ============ END MAIN ========== */}
          </div>
          {/* ========= END ALL WORK INFORATION ========= */}
        </div>
        {/* ========= END CONTAINER ========= */}
      </div>
    </>
  );
};

export default WorkInformation;
