import videoSrc from "../../../assets/LandingPageImages/video/01.mp4";
import mainImage from "../../../assets/LandingPageImages/main/mainImage.jpeg";

import iconDownload from "../../../assets/LandingPageImages/icons/import.svg";
import "./SliderHeader.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const SliderHeader = () => {
  const { t } = useTranslation();
const login = useSelector((state) => state.auth.isAuthenticated);
  return (
    <div className="header-slider">
      <div className="banner-main-area-01">
        <div className="banner-one-01 section-padding-01 bg-image-01">
          <div className="video-bg-01 overlay-background">
            <img src={mainImage} />
          </div>
          <div className="info-banner-01 position-relative z-1">
            <div className="container">
              <div className="row g-3 align-items-center justify-content-between">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="content-slide-01">
                    <h1 className="title" data-aos="fade-down">
                      {t("Build with build in ")}
                    </h1>
                    <p
                      className="text text-font-400-white font-18"
                      data-aos="fade-up"
                    >
                      {t("Where we concet consltant and contractor supplires and custmer in one platform")}
                    </p>
                    <div className="main-buttons-slider d-flex align-items-center gap-3 btn-nav mt-3">
                      <a
                        className="btn-main-01"
                        data-aos="fade-right"
                        href="#download-app"
                      >
                        {t("download app")}
                        <img
                          alt="icon"
                          height="24px"
                          src={iconDownload}
                          width="24px"
                        />
                      </a>
                      <Link
                        className="btn-main-01 btn-transparent-01"
                        data-aos="fade-right"
                      to={login?"/home":"/loginForm"}
                      >
                        {t("navbar.login")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderHeader;
