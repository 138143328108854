
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "./ButtonScroll.css";
const ButtonScroll = () => {
    const [scrollY, setScrollY] = useState(0);

    const handleScroll1 = () => {
      setScrollY(window.scrollY);
    };
  
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // THIS VALUE OF SCROLL TO ADD CLASS ACTIVE
        const scrollThreshold = 500;
  
        if (scrollPosition > scrollThreshold) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("scroll", handleScroll1);
  
      // Clean up the event listener
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("scroll", handleScroll1);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth" //  adds smooth scrolling behavior
      });
    };
  return (
    <>
    <div
    className="animtion-line"
    style={{
      position: "fixed",
      top: 0,
      right: 0,
      width: `${
        (scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
        100
      }%`,
      height: "5px",
      zIndex: 120,
      backgroundColor: "#e2a02e"
    }}
  ></div>
  <button
    onClick={scrollToTop}
    className={`btn-scroll ${isActive ? "active" : ""}`}
  >
    <FontAwesomeIcon icon={faArrowUp} />
  </button>
      
    </>
  )
}

export default ButtonScroll
