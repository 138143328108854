import PackagesCard from "Components/PackagesCard/PackagesCard";
import { Tab, Tabs } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import imgIcon from "../../assets/images/icons/diamond.svg";
import iconDollar from "../../assets/images/icons/dollar-circle.svg";
import iconTimer from "../../assets/images/icons/timer.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import moment from "moment";
import SubscribeModal from "Pages/MyAccount/ModalAccount/SubscribeModal";
import CustomModal from "Components/CustomModal/CustomModal";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "Pages/Wallet/Stripe";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { toast } from "react-toastify";

const Subscriptions = () => {
  const lastSub = [
    { id: 0, dateInfoBottom: "01/01/2025", textDate: "تاريخ الإشتراك" },
    { id: 1, dateInfoBottom: "01/01/2025", textDate: "تاريخ الإنتهاء" },
  ];
  
  const stripePromise = loadStripe(
    "pk_live_51P5XBHRozbqwhoPVH68Crl9g2kO2lLn8dWnD7zAtlDt05lqA2Why33vUdZdG85z4C6mz4rWz02Puo3vQZ1R4m2Xm00JT6fxYqC"
  );
  const [data, setData] = useState([]);
  const [subscriptions, setSubscriptions] = useState();
  const [percntage, setPercntage] = useState(70);
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [activePeriod, setActivePeriod] = useState("");
  const [amount, setAmount] = useState(0);
  const [ClientLoadingg, setClientLoading] = useState(false);
  const [price, setPrice] = useState();
  const [clientSecret, setclientSecret] = useState("");
const [paymentModal, setPaymentModal] = useState(false);
const [ActiveId, setActiveId] = useState(0);
  const handleAfterPay = (id,setIsProcessing) => {
    setIsProcessing(true)
    axiosInstance
    .post("/subscription", {
      package_id: ActiveId,
      duration: activePeriod,
      payment_method: "stripe",
      transaction_id: id,
      
    })
    .then((res) => {
      toast.success(t("Subscribed successfully"));
      setIsProcessing(false)
    
      setPaymentModal(false)
    })
    .catch((error) => {
      toast.error(t("Failed to subscribe"));
      setIsProcessing(false)
      setPaymentModal(false)


    });
  };
  const HandleAmount = (num,setLoaidng) => {
    setAmount(num);
    if (num !=null) {
      console.log("hello");
      setClientLoading(true);
      axios
        .post(
          "https://api.stripe.com/v1/payment_intents",
          {
            amount: num * 100,
            currency: "aed",
            "payment_method_types[]": "card",
          },
          {
            headers: {
              Authorization:
                "Bearer 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",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
       
          console.log(res);
          setclientSecret(res.data.client_secret);
          console.log(res.data.client_secret);
          setTimeout(() => {
            setClientLoading(false);
            setPaymentModal(true);
      setShowModal(false)
      setLoaidng(false);
          }, 500);
        })
        .catch((err) => {
          toast.error(t("Failed to subscribe"));
          setLoaidng(false);
        });
    }
  };
  useEffect(() => {
    axiosInstance
      .get("/packages", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setData(res.data.packages);
        setSubscriptions(res.data?.current_subscription[0]);

        if (res.data?.current_subscription[0]) {
          const startDate = moment(
            res.data?.current_subscription[0].start_date
          );
          const endDate = moment(res.data?.current_subscription[0].end_date);

          // Define the current date (or any other specific date)
          const currentDate = moment();

          // Ensure the current date is within the range
          if (currentDate.isBefore(startDate) || currentDate.isAfter(endDate)) {
            console.log("Current date is outside the range.");
          } else {
            // Calculate the total duration in milliseconds
            const totalDuration = endDate.diff(startDate);

            // Calculate the duration from the start date to the current date in milliseconds
            const spentDuration = currentDate.diff(startDate);

            // Calculate the percentage of time spent
            const percentageSpent = (spentDuration / totalDuration) * 100;
            setPercntage(percentageSpent);
          }
        }
      });
  }, []);
  return (
    <>
      <HelmetInfo titlePage={"الإشتراكات"} />
      <CustomModal show={paymentModal} onHide={() => setPaymentModal(false)}>
        {ClientLoadingg ? (
          <div>Loading</div>
        ) : (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: clientSecret,
              appearance: { theme: "flat" },
            }}
          >
            <CheckoutForm amount={amount} handleAfterPay={handleAfterPay} />
          </Elements>
        )}
      </CustomModal>
      {activePeriod && (
        <SubscribeModal
          price={price}
          duration={activePeriod}
          handleCloseModalEditWork={() => setShowModal(null)}
          ShowModal={showModal}
          HandleAmount={HandleAmount}
        />
      )}
      <div className="all-offer-page margin-top-content">
        {/* ========= START ALL TABS OFFERS ========= */}
        <div className="all-taps-offer">
          <Tabs
            defaultActiveKey="now"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="now" title="الاشتراك الحالي ">
              <div className="all-subscriptions">
                <div className="row g-3">
                  <div className="col-12 col-md-6">
                    {subscriptions ? (
                      <PackagesCard
                        imgIcon={imgIcon}
                        titlePackage={subscriptions?.package?.title}
                        // textPackage={
                        //   "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق."
                        // }
                        numPricing={
                          subscriptions?.package[subscriptions?.duration]
                        }
                        // dateType={subscriptions?.duration}
                        moneyType={"درهم"}
                        iconTypeCheck={faCheck}
                        textIconPricing={"إظهار بالبداية عند البحث"}
                        textButton={"إلغاء الإشتراك"}
                        newClass={"package-details-new"}
                        isActiveBottomInfo={false}
                        dateInfoBottom={false}
                        textContentBottom={false}
                        isActiveButtonSubscription={false}
                        isActiveButtonCancel={false}
                        isActiveButtonReload={false}
                        isActiveRibbon={false}
                        ribbonText={"نرشحها لك"}
                        isActiveProgress={true}
                        progressCount={percntage}
                        textProgressDate={`${t("subscribed Since")} ${
                          subscriptions?.start_date
                        }`}
                        textManyDay={`${t("There are")} ${moment(
                          subscriptions?.end_date
                        ).diff(moment(subscriptions?.start_date), "days")} ${t(
                          "days left until renewal"
                        )}`}
                        iconOne={undefined}
                        iconOne2={undefined}
                        dateInfoBottom2={undefined}
                        textContentBottom2={undefined}
                        iconOne3={undefined}
                        dateInfoBottom3={undefined}
                        textContentBottom3={undefined}
                        show_in_home={subscriptions?.package.show_in_home}
                        show_first={subscriptions?.package.show_first}
                        projects_apply={subscriptions?.package.projects_apply}
                        work_count={subscriptions?.package.work_count}
                        offers_first={subscriptions?.package.offers_first}
                      />
                    ) : (
                      <NoResults text={t("noSubsicretopins")} />
                    )}
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>

         
        </div>
        {/* ========= END ALL TABS OFFERS ========= */}
      </div>
    </>
  );
};

export default Subscriptions;
