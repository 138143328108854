import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconLocation from "../../../assets/images/icons/location.svg";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import OfferModal from "../OfferModal/OfferModal";
import { useEffect, useState } from "react";
import ModalAcceptOffer from "Components/ModalAcceptOffer/ModalAcceptOffer";
import axiosInstance from "../../../axios";
import { Link, useNavigate, useNavigation } from "react-router-dom";
const CardOffer = ({
  numDay,
  image,
  imageCardAlt,
  titleCard,
  textCard,
  textCardTrue,
  cardTwoInfo,
  iconCurrancy,
  iconAlt,
  priceNum,
  typePrice,
  textPrice,
  textButtonOne,
  textButtonTwo,
  buttonTwoActive,
  buttonThreeActive,
  rateNum,
  place,
  showBtns,
  offerId,
  refetch,
  ChatProfile,
  offerPages,
  projectId,
  expertId
}) => {
  // OEPN ANDCLOSE MODAL
  const navigate=useNavigate()
  const [showModal, setShowModal] = useState(false);
  // CLOSE MODAL
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // OPEN MODAL
  const handleShowModal = () => {
    setShowModal(true);
  };
  // Show Modal Contact
  const [showModalContact, setShowModalContact] = useState(false);
  const handleCloseModalContact = () => {
    setShowModalContact(false);
  };
useEffect(()=>{
console.log(rateNum)
},[])
  const addActiveContactModal = () => {
    setShowModalContact(true);
  };

  // OEPN ANDCLOSE MODAL ACCEPT OFFER
  const [showModalAcceptOffer, setShowModalAcceptOffer] = useState(false);
  // CLOSE MODAL
  const handleCloseAcceptOffer = () => {
    setShowModalAcceptOffer(false);
  };
  // OPEN MODAL
  const handleShowModalAccept = (e) => {
    e.preventDefault();
    axiosInstance
      .post("/offeraccept", {
        offer_id: offerId,
      })
      .then(() => {
        setShowModalAcceptOffer(true);
        if (refetch) {
          refetch();
        }
      });
  };

  return (
    <>
      {/* ======= OFFER MODAL ======== */}
      <OfferModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        showModalContact={showModalContact}
        handleCloseModalContact={handleCloseModalContact}
      />
      <ModalAcceptOffer
        showModalAcceptOffer={showModalAcceptOffer}
        handleCloseAcceptOffer={handleCloseAcceptOffer}
        titleCard={titleCard}
        image={image}
        priceNum={priceNum}
        ChatProfile={ChatProfile}
      />

      {/* ======== START CARD DETAILS INFO ========== */}

      <div style={{cursor:offerPages ? "pointer":""}} onClick={()=>{
        if(offerPages)
          {
            navigate(`/productDetailsPage/${projectId}`)
          }
      }} className="card-details-info">
        {numDay && (
          <div className="times-details">
            <div className="icon-clock">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
              >
                <path
                  d="M9.5 17.0625C5.0525 17.0625 1.4375 13.4475 1.4375 9C1.4375 4.5525 5.0525 0.9375 9.5 0.9375C13.9475 0.9375 17.5625 4.5525 17.5625 9C17.5625 13.4475 13.9475 17.0625 9.5 17.0625ZM9.5 2.0625C5.675 2.0625 2.5625 5.175 2.5625 9C2.5625 12.825 5.675 15.9375 9.5 15.9375C13.325 15.9375 16.4375 12.825 16.4375 9C16.4375 5.175 13.325 2.0625 9.5 2.0625Z"
                  fill="#377DFF"
                />
                <path
                  d="M12.283 11.9476C12.1855 11.9476 12.088 11.9251 11.998 11.8651L9.67297 10.4776C9.09547 10.1326 8.66797 9.37507 8.66797 8.70757V5.63257C8.66797 5.32507 8.92297 5.07007 9.23047 5.07007C9.53797 5.07007 9.79297 5.32507 9.79297 5.63257V8.70757C9.79297 8.97757 10.018 9.37507 10.2505 9.51007L12.5755 10.8976C12.8455 11.0551 12.928 11.4001 12.7705 11.6701C12.658 11.8501 12.4705 11.9476 12.283 11.9476Z"
                  fill="#377DFF"
                />
              </svg>
            </div>
            <span className="text">متبقي {numDay} أيام</span>
          </div>
        )}
        <div onClick={ ()=>{

          if(!offerPages)
            {
              navigate(`/theExpert/${expertId}`,{state:{ id: expertId }}  )
            }
            else {
              navigate(`/productDetailsPage/${projectId}`)
            }
        }} className="info-content-card d-flex  align-items-center  gap-3">
          <div className="img">
            <img src={image} alt={imageCardAlt} />
          </div>
          <div className="text-info">
            <h2 className="title">{titleCard}</h2>
            {textCardTrue && <p className="text">{textCard}</p>}
            {cardTwoInfo && (
              <div className="all-info-card-two d-flex  flex-column gap-2 pt-1">
                <div className="main-addresss d-flex  align-items-center  gap-1">
                  <div className="icon-location">
                    <img src={iconLocation} alt="location" />
                  </div>
                  <p className="text text-location">{place}</p>
                </div>
                {
                  rateNum &&    <div className="rate-info d-flex  align-items-center  gap-2">
                  <div className="icon-star">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                  <div className="num-rate">{rateNum}</div>
                </div>
                }
            
              </div>
            )}
          </div>
        </div>
        <div className="currancy-info d-flex  align-items-center  gap-4">
          <div className="icon">
            <img src={iconCurrancy} alt={iconAlt} />{" "}
          </div>
          <div className="info-money d-flex flex-column">
            <p className="num-money">
              {priceNum} {typePrice}
            </p>
            <div className="text">{textPrice}</div>
          </div>
        </div>
        {showBtns && (
          <div className="button-bottom d-flex w-100 gap-2 mt-3">
            <button onClick={handleShowModalAccept} className="btn-main">
              {textButtonOne}
            </button>
            {buttonTwoActive && (
              <Link
                to={`/chat`}
                state={ChatProfile}
                className="btn-main button-home"
              >
                {textButtonTwo}
              </Link>
            )}
            {buttonThreeActive && (
              <Link
                to={`/chat`}
                state={ChatProfile}
                className="btn-main button-home"
              >
                {textButtonTwo}
              </Link>
            )}
          </div>
        )}
      </div>
      {/* ======== END CARD DETAILS INFO ========== */}
    </>
  );
};

export default CardOffer;
