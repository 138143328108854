import { useTranslation } from "react-i18next";
import imageSuccess from "../../assets/images/gif/successDocument.gif";

import ModalSuccess from "Components/Success/ModalSuccess";

const ModalRequestAndAddProject = ({ showModal, handleCloseModal }) => {
  const {t}=useTranslation();
  return (
    <>
      <ModalSuccess
        showModalSuccess={showModal}
        handleCloseModalSuccess={handleCloseModal}
        titleInfo={t("titleInfo")}
        isAddText={true}
        textInfo={
t("textInfo")
        }
        titleButton={t("titleButton")}
        buttonRoute={true}
        routeDir={"/home"}
        successIcon={imageSuccess}
        isActiveMainButton={false}
      />
    </>
  );
};

export default ModalRequestAndAddProject;
