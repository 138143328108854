import { useTranslation } from "react-i18next";
import imgFooter from "../../../assets/LandingPageImages/main/footer.jpeg";
import logoFooter from "../../../assets/LandingPageImages/logo/logo.png";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import iconPhone from "../../../assets/LandingPageImages/footer/call.svg";
import iconEmail from "../../../assets/LandingPageImages/footer/sms-tracking.svg";
import iconWhatsApp from "../../../assets/LandingPageImages/footer/whatsapp.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* ================== START FOOTER ============= */}
      <footer>
        {/* ============= START SECTION FOOTER =============== */}
        <section
          className="footer position-relative"
          style={{ backgroundImage: `url(${imgFooter})` }}
          id="contact-us"
        >
          <div className="overlay-background" />
          {/* ============= START CONTAINER ============= */}
          <div className="container">
            {/* ============ START ALL FOOTER ============ */}
            <div className="all-footer">
              {/* ======== START SECTION TITLE INFO =========== */}
              <div
                className="section-title-info center-section-head"
                data-aos="fade-left"
              >
                <h2 className="title-head">{t("Contact Us")}</h2>
                <h2 className="title">
                  {t(
                    "If you have any inquiries or suggestions, we would be happy to communicate with you"
                  )}
                </h2>
              </div>
              {/* ======== END SECTION TITLE INFO =========== */}
              {/* ======== START INFO CONTACT ========== */}
              <div className="info-contact" data-aos="fade-up">
                {/* ========= START ROW ========== */}
                <div className="row g-3 gy-4">
                  {/* ========= START COL =========== */}
                  <div className="col-12 col-md-5 col-lg-4 col-contact">
                    {/* ======== START INFO CONTACT ONE ========= */}
                    <a
                      href="tel:+1234567890"
                      className="info-contact-one d-flex align-items-center gap-3"
                    >
                      {/* ======== START ICON =========== */}
                      <div className="icon">
                        <img src={iconPhone} alt="icon phone" />
                      </div>
                      {/* ======== END ICON =========== */}
                      {/* ======== START CONTENT INFO ======== */}
                      <div className="content-info">
                        <h2 className="title">{t("Phone Numbers")}</h2>
                        <p className="text-info-contact">+971581541366</p>
                      </div>
                      {/* ======== END CONTENT INFO ======== */}
                    </a>
                    {/* ======== END INFO CONTACT ONE ========= */}
                  </div>
                  {/* ========= END COL =========== */}
                  {/* ========= START COL =========== */}
                  <div className="col-12 col-md-5 col-lg-4 col-contact">
                    {/* ======== START INFO CONTACT ONE ========= */}
                    <a
                      href="mailto:example@example.com"
                      className="info-contact-one d-flex align-items-center gap-3"
                    >
                      {/* ======== START ICON =========== */}
                      <div className="icon">
                        <img src={iconEmail} alt="icon email" />
                      </div>
                      {/* ======== END ICON =========== */}
                      {/* ======== START CONTENT INFO ======== */}
                      <div className="content-info">
                        <h2 className="title">{t("Email")}</h2>
                        <p className="text-info-contact">info@buildin.ae</p>
                      </div>
                      {/* ======== END CONTENT INFO ======== */}
                    </a>
                    {/* ======== END INFO CONTACT ONE ========= */}
                  </div>
                  {/* ========= END COL =========== */}
                  {/* ========= START COL =========== */}
                  <div className="col-12 col-md-5 col-lg-4 col-contact">
                    {/* ======== START INFO CONTACT ONE ========= */}
                    <a
                      href="https://wa.me/+971581541366"
                      target="_blank"
                      className="info-contact-one d-flex align-items-center gap-3"
                      rel="noreferrer"
                    >
                      {/* ======== START ICON =========== */}
                      <div className="icon">
                        <img src={iconWhatsApp} alt="icon whatsapp" />
                      </div>
                      {/* ======== END ICON =========== */}
                      {/* ======== START CONTENT INFO ======== */}
                      <div className="content-info">
                        <h2 className="title">{t("Contact via WhatsApp")}</h2>
                        <p className="text-info-contact">+971581541366</p>
                      </div>
                      {/* ======== END CONTENT INFO ======== */}
                    </a>
                    {/* ======== END INFO CONTACT ONE ========= */}
                  </div>
                  {/* ========= END COL =========== */}
                </div>
                {/* ========= END ROW ========== */}
              </div>
              {/* ======== END INFO CONTACT ========== */}
            </div>
            {/* ============ END ALL FOOTER ============ */}
          </div>
          {/* ============= END CONTAINER ============= */}
          {/* ======== START BOTTOM FOOTER ========= */}
          <div className="bottom-footer">
            {/* ============ START CONTAINER =========== */}
            <div className="container">
              <div className="all-bottom-footer d-flex flex-column justify-content-center align-items-center gap-2">
                <div className="logo">
                  <img
                    src={logoFooter}
                    width="250px"
                    height="57px"
                    alt="logo"
                  />
                </div>
                {/* ======== START ICONS SOCIAL ========= */}
                <div className="icon-social">
                  <ul className="list-social p-0 m-0 pb-3 d-flex justify-content-center align-items-center gap-3">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61559327609048"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://x.com/Buildinccc"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/@buildinccc"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faTiktok} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/buildinccc/"
                        target="_blank"
                        className="social-one-link"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* ======== END ICONS SOCIAL ========= */}
                <div className="end-text">
                  <p className="text d-flex  justify-content-center  align-items-center  gap-2">
                    {t("All rights reserved. Designed and developed by")}{" "}
                    <a
                      target="_blank"
                      href="https://syntecheg.com/"
                      className="link-text"
                    >
                      syntech
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* =========== END CONTAINER  ============ */}
          </div>
          {/* ======== END BOTTOM FOOTER ========= */}
        </section>
        {/* ============= END SECTION FOOTER =============== */}
      </footer>
      {/* ================== END FOOTER ============= */}
    </>
  );
};

export default Footer;
