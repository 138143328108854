import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./NotificationSideBar.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import image from "../../assets/images/main/image1.png";
import CardOffer from "Pages/Home/OffersPackages/CardOffer";
import iconCurrancy from "../../assets/images/icons/dollar-circle.svg";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import iconNotification from "../../assets/images/iconsSideBar/notification-bing.svg";
import NoResults from "Components/NotFound/noResults";
const NotificationSideBar = ({ showSidebar, closeNot }) => {
  const [notication, setNotication] = useState([]);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    axiosInstance
      .get("/notifications", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data);
        setNotication(res.data);
      });
  }, [showSidebar]);

  window.addEventListener("keydown", (e) => {
    closeNot();
  });

  return (
    <>
      <div className={`sidebar-notification ${showSidebar ? "active" : ""}`}>
        <div className="overlay-bg" onClick={closeNot}></div>
        <div className={`sidebar-1 ${showSidebar ? "active" : ""}`}>
          <div className="header-sidebar">
            <button className="close-button" onClick={closeNot}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2 className="title">{t("latestNotifcation")} </h2>
          </div>
          <div className="sidebar-content-notification">
            <div className="all-info-sidebar">
              {/* ======== START NOTIFCATION ONE ========= */}

              {notication?.length > 0 ? (
                notication.map((item, index) => {
                  return (
                    <div className="notification-one" key={index}>
                      {/* ========= START NOTIFICATION HEAD ============ */}
                      <div className="notification-head mb-3 d-flex align-items-center gap-2">
                        <div className="notifiction-badge"></div>
                        {/* ======== START IMAGE NOTIFICATION ========= */}
                        <div className="image-notification">
                          <img
                            src={iconNotification}
                            alt="imagNotification"
                            className="object-fit-cover"
                          />
                        </div>
                        {/* ======== END IMAGE NOTIFICATION ========= */}

                        <div className="info-notification-head" key={index}>
                          <h2 className="title">{item.data.title}</h2>
                          <p className="text">{item.data.body}</p>
                          <div className="date-notification">{item.date}</div>
                        </div>
                      </div>
                      {/* ========= END NOTIFICATION HEAD ============ */}

                      {/* ======== START CARD DETAILS INFO ========== */}

                      {/* ======== END CARD DETAILS INFO ========== */}
                    </div>
                  );
                })
              ) : (
                <NoResults text={"notAvailable"} />
              )}

              {/* ======== END NOTIFCATION ONE ========= */}
              {/* ======== START NOTIFCATION ONE ========= */}

              {/* ======== END NOTIFCATION ONE ========= */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSideBar;
