// import { toast } from "react-toastify";
import iconOtp from "../../assets/images/gif/04.gif";
import "./Otp.css";
import { useEffect, useRef, useState } from "react";
import ModalConfirmNumber from "Pages/MyAccount/ModalAccount/ModalConfirmNumber";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Otp = ({
  openPopupSuccess,
  isAcriveButtonModal,
  showModalConfirmNumber,
  handleCloseModalConfirmNumber,
}) => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [loading,setLoading]=useState(false)
  const [value, setValue] = useState([null, null, null, null]);
  const [error, setError] = useState(false);
  useEffect(() => {
    console.log(location.state.email);
  }, [location]);
  useEffect(() => {
    if (inputRefs[3] && inputRefs[3].current) {
      inputRefs[3].current.focus();
    }
  }, []);

  const focusInput = (index) => {
    console.log(inputRefs[index]);
    if (inputRefs[index] && inputRefs[index].current) {
      inputRefs[index].current.focus();
    }
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    const nextIndex = index - 1;

    if (value.length === 1 && nextIndex <= 3) {
      focusInput(nextIndex);
    }
  };

  const handleKeyDown = (index, e) => {
    const value = e.target.value;
    const prevIndex = index + 1;
    console.log(prevIndex);
    if (e.key === "Backspace" && index > 0 && value.length === 0) {
      focusInput(prevIndex);
    }
  };
  

  const navigate = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true)
    let concatenatedString = `${value[0]}${value[1]}${value[2]}${value[3]}`;
    console.log(concatenatedString.length);
    if (concatenatedString.length === 4) {
      axiosInstance
        .post("/verifyweb", {
          otp: concatenatedString,
          email: location.state?.email,
        })
        .then((res) => {
          console.log(res);
          navigate("/NewPassword",{
            state:{
              email:location.state.email,
              otp:concatenatedString
            }
          })
          setLoading(false)
        }).catch((err)=>{
if(err.response.status == 404){
  toast.error(t("The code is incorrect"));
}
setLoading(false)
        });
    }
  };

  return (
    <>
      <HelmetInfo titlePage={"تفعيل رمز الأمان"} />

      <main>
        <ModalConfirmNumber
          showModalConfirmNumber={showModalConfirmNumber}
          handleCloseModalConfirmNumber={handleCloseModalConfirmNumber}
        />
        <div className="main">
          {/* ========= START OTP SECTION =========== */}
          <div className="otp-section  center-section">
            {/* ========= START CONTAINER ========== */}
            <div className="container-fluid">
              {/* ========= START ALL OTP ========== */}
              <div className="all-otp width-content">
                {/* ======== START HEADER OTP ======== */}
                <div className="header-otp">
                  <img src={iconOtp} alt="icon otp" loading="lazy" />
                </div>
                {/* ======== END HEADER OTP ======== */}
                {/* ======== START INFO OTP ========= */}
                <div className="info-otp">
                  {/* ======= START TOP INFO ======== */}
                  <div className="top-info">
                    <h1 className="title">
                      {t("Please enter the security code")}
                    </h1>
                    <p className="text">
                      {t(
                        "Please enter the security code that was sent to your email"
                      )}

                      <span className="num-phone">{location.state.email}</span>
                    </p>
                  </div>
                  {/* ======= END TOP INFO ======== */}
                  {/* ======== START OTP FORM ========= */}
                  <div className="otp-form">
                    <form onSubmit={submitHandler} action="">
                      <div
                        className="all-input-otp d-flex align-items-center justify-content-center gap-2 mt-4"
                        style={{ direction: "rtl" }}
                      >
                        <input
                          type="text"
                          name="digit1"
                          className="form-control"
                          maxLength={1}
                          onChange={(e) => {
                            handleInputChange(0, e);
                            let valueinput = [...value];
                            valueinput[3] = e.target.value;
                            setValue(valueinput);
                          }}
                          onKeyDown={(e) => handleKeyDown(0, e)}
                          ref={inputRefs[0]}
                        />
                        <input
                          type="text"
                          name="digit2"
                          className="form-control"
                          maxLength={1}
                          onChange={(e) => {
                            handleInputChange(1, e);
                            let valueinput = [...value];
                            valueinput[2] = e.target.value;
                            setValue(valueinput);
                          }}
                          onKeyDown={(e) => handleKeyDown(1, e)}
                          ref={inputRefs[1]}
                        />
                        <input
                          type="text"
                          name="digit3"
                          className="form-control"
                          maxLength={1}
                          onChange={(e) => {
                            handleInputChange(2, e);
                            let valueinput = [...value];
                            valueinput[1] = e.target.value;
                            setValue(valueinput);
                          }}
                          onKeyDown={(e) => handleKeyDown(2, e)}
                          ref={inputRefs[2]}
                        />
                        <input
                          type="text"
                          name="digit4"
                          className="form-control"
                          maxLength={1}
                          onChange={(e) => {
                            handleInputChange(3, e);
                            let valueinput = [...value];
                            valueinput[0] = e.target.value;
                            setValue(valueinput);
                          }}
                          onKeyDown={(e) => handleKeyDown(3, e)}
                          ref={inputRefs[3]}
                        />
                      </div>
                      {isAcriveButtonModal && (
                        <button
                          className="btn-main w-100"
                          onClick={openPopupSuccess}
                        >
                          حفظ
                        </button>
                      )}
                      <button
                        className="btn-main btn-page-otp w-100"
                        onClick={() => {
                          // navigate("/successData2");
                        }}
                        disabled={loading}
                      >
                     {
                   loading ? <div className="loader"></div> :  t("Save")
                     }
                      </button>
                    </form>
                  </div>
                  {/* ======== END OTP FORM ========= */}
                </div>
                {/* ======== END INFO OTP ========= */}
              </div>
              {/* ========= END ALL OTP ========== */}
            </div>
            {/* ========= START CONTAINER  ========== */}
          </div>
          {/* ========= END OTP SECTION =========== */}
        </div>
      </main>
    </>
  );
};

export default Otp;
