import React, { useRef, useState } from "react";
import ImageLogin from "../../../assets/images/gif/01.gif";
import "../Login.css";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import ImageLoginForm from "../ImageLogin/ImageLogin";
import HeaderForm from "../InfoHeaderForm/HeaderForm";
import LoginBottomInfo from "./LoginBottomInfo";
import IconHeaderForm from "../IconHeaderFormRoute/IconHeaderForm";
import LogoImageForm from "../LogoForm/LogoImageForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../../axios";
import { useDispatch } from "react-redux";
import { login } from "../../../Redux/auth";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import audio from "../../../assets/WELCOME.mp3";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: "",
};

const NewPassword = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const soundEffect = useRef();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("EnterPassword"))
      .min(8, t("PasswordMinError")),
  });
  const initialValues = {
    password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [password3, setPassword3] = useState("");

  const togglePasswordVisibility = (setShowPasswordparm) => {
    setShowPasswordparm(!showPassword);
  };

  const handlePasswordChange = (e, setPasswordparam) => {
    setPasswordparam(e.target.value);
  };

  const handleSubmit = (values) => {
    // Handle form submission
    setLoading(true);
    console.log(values);
    axiosInstance
      .post("/forgetpassword", {
        email: location.state.email,
        password: values.password,
        otp: location.state.otp,
      })
      .then((res) => {
        console.log(res);

        setLoading(false);
        toast.success("تم ارسال الكود الى البريد الالكتروني");
        router("/loginForm");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          toast.error(" البريد الالكتروني  خطأ");

          setLoading(false);
        } else {
          toast.error("حدث خطأ ما");

          setLoading(false);
        }
      });
  };

  // SHOW PASSWORD INPUT

  return (
    <>
      <HelmetInfo titlePage={"تسجيل الدخول"} />
      <audio ref={soundEffect} src={audio}></audio>
      <div className="login-form">
        {/* ========= START LOGO LOGIN ======== */}
        <div className="logo-top">
          <LogoImageForm />
        </div>
        {/* ========= END LOGO LOGIN ======== */}
        {/* ========= START CONTAINER =========== */}
        <div className="container-fluid">
          {/* ======== START ALL LOGIN FORM =========== */}
          <div className="all-Login-form">
            {/* ========== START ROW =========== */}
            <div className="row g-3 align-items-center">
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO FORM LOGIN =========== */}
                <div className="info-form-login position-relative">
                  {/* ========= START ICON CLOSE ========= */}

                  <IconHeaderForm routerPage={"/"} iconHeader={faTimes} />
                  {/* ========= END ICON CLOSE ========= */}
                  {/* ========= START ALL FORM LOG ======== */}
                  <div className="all-form-log">
                    {/* ========== START HEADER FORM LOG ========= */}
                    <HeaderForm
                      titleHeaderForm={"   تغير كلمة السر!"}
                      textHeaderForm={"من فضلك , ادخل بريدك الالكتروني"}
                    />
                    {/* ========== END HEADER FORM LOG ========= */}

                    {/* ========== START FORM FIELD ========= */}
                    <FormField
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      <div className="row g-3">
                        <div className="col-12 col-md-12">
                          <div className="input-pass position-relative">
                            <InputFiled
                              label={t("NewPasswordLabel")}
                              name="password"
                              type={showPassword ? "text" : "password"}
                              placeholder=""
                              success
                              value={password}
                              onChange={(e) =>
                                handlePasswordChange(e, setPassword)
                              }
                            />

                            <span
                              className="icon-eye-button"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                togglePasswordVisibility(setShowPassword)
                              }
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              ) : (
                                <FontAwesomeIcon icon={faEye} />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn-main w-100 mt-3"
                        disabled={loading}
                      >
                        {loading ? <span className="loader"></span> : t("Edit")}
                      </button>
                    </FormField>
                    {/* ========== END FORM FIELD ========= */}
                    {/* ========== START BOTTOM FORM ========= */}
                    <LoginBottomInfo />
                    {/* ========== END BOTTOM FORM ========= */}
                  </div>
                  {/* ========= END ALL FORM LOG ======== */}
                </div>
                {/* ========== END INFO FORM LOGIN =========== */}
              </div>
              {/* =========== END COL ======== */}
              {/* =========== START COL ======== */}
              <div className="col-12 col-md-6">
                {/* ========== START INFO LOGIN ========== */}
                <div className="info-login position-relative">
                  {/* ========= START IMAGE LOGIN ======= */}
                  <ImageLoginForm ImageLoginPic={ImageLogin} />
                  {/* ========= END IMAGE LOGIN ======= */}
                  {/* ========= START LOGO LOGIN ======== *
                 <div className="logo-login-bottom">
                   <LogoImageForm />
                 </div>
                 {/* ========= END LOGO LOGIN ======== */}
                </div>
                {/* ========== END INFO LOGIN ========== */}
              </div>
              {/* =========== END COL ======== */}
            </div>
            {/* ========== END ROW =========== */}
          </div>
          {/* ======== END ALL LOGIN FORM =========== */}
        </div>
        {/* ========= END CONTAINER =========== */}
      </div>
    </>
  );
};

export default NewPassword;
