import React from "react";
import ImageLoginForm from "./ImageLogin";
import ImageLogin from "../../../assets/images/main/01.png";

const ImageComponent = () => {
  return (
    <>
      <ImageLoginForm ImageLoginPic={ImageLogin} />
    </>
  );
};

export default ImageComponent;
