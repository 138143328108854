import { Skeleton } from "@mui/material";

const CardSkelaton = () => {
  return (
    <div className="mt-3">
      <Skeleton variant="rectangular" width={300} height={150} />
      <Skeleton variant="text" />
      <Skeleton variant="text" />
      <Skeleton variant="text" width={300} />
    </div>
  );
};

export default CardSkelaton;
