import { useEffect, useState } from "react";
import uploadImage from "../../../assets/images/icons/upload.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const FileUpload = ({
  title,
  text,
  showText,
  SelectedData,
  data,
  enableReset,
  dataUpdate,
  profileEdit,
  accept="",
  value=[],
}) => {
  const [selectedFiles, setSelectedFiles] = useState([...value]);
const {t}=useTranslation();
  // UPLOAD IMAGE OR FILES
  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files?.length > 0) {
      const updatedFiles = [];
      for (let i = 0; i < files?.length; i++) {
        updatedFiles.push({
          file: files[i],
          name: files[i].name,
          type: files[i].type,
          preview: URL.createObjectURL(files[i]), // Create preview URL for images
        });
      }
      console.log(updatedFiles);
      SelectedData(updatedFiles);
      setSelectedFiles([...selectedFiles, ...updatedFiles]);
    }
    // FILTER ARRAY IF THIS ELEMENT IS DEFINED NOT ADD IN ARRAY
    const filesArray = Array.from(files).filter((file) => {
      const found = selectedFiles.find(
        (existingFile) => existingFile.name === file.name
      );
      return !found;
    });
    if (filesArray.length === 0) {
      setSelectedFiles(selectedFiles);
      toast.error(t("FileAlreadyAdded"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Auto-close the toast after 2 seconds
      });
      return;
    }

    toast.success(t("FileAddedSuccessfully"), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Auto-close the toast after 2 seconds
    });
  };

  // REMOVE IMAGE OR FILE
  const handleDelete = (index) => {
    const updatedFiles = selectedFiles?.filter((_, i) => i !== index);

    setSelectedFiles(updatedFiles);
    toast.success(t("FileDeletedSuccessfully"), {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Auto-close the toast after 2 seconds
    });
  };

  useEffect(() => {
    if (!data && enableReset) {
      setSelectedFiles([]);
    }
  }, [data]);
  useEffect(() => {
 console.log(dataUpdate?.length == 0);
    if(dataUpdate?.length == 0 && profileEdit)
    {
      setSelectedFiles([]);
    }
  }, [dataUpdate]);
  return (
    <div className="all-file-uploaded">
      <div className="file-upload-info file-up">
        <div className="image-upload">
          <img src={uploadImage}  alt="upload icon" loading="lazy" />
        </div>
        <div className="info-content">
          <h2 className="title">{t(title)}</h2>
          <p className="text">{t(text)}</p>
        </div>

        <input type="file" multiple accept={accept}  onChange={handleFileChange} />
      </div>
      {showText && <p className="text text-2">{t("MaximumImagesSelection")}</p>}

      {/* ========= START FILES UPLOADED ======== */}
      <div className="all-files-up">
        {selectedFiles.length > 0 && (
          <div>
            <h2 className="title-upload">{t("SelectedFiles")}:</h2>
            <ul className="files-uploaded">
              {selectedFiles.map((file, index) => (
                <li key={index}>
                  {file.type.startsWith("image/") ? (
                    <div className="main-upload-info">
                      <img
                        src={file.preview}
                        alt={file.name}
                        style={{ width: "100px", height: "auto" }}
                      />
                      <button
                        className="button-remove-up"
                        onClick={() => handleDelete(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  ) : (
                    <div className="main-upload-info">
                      <span>{file.name}</span>
                      <button
                        className="button-remove-up"
                        onClick={() => handleDelete(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* ========= END FILES UPLOADED ======== */}
    </div>
  );
};

export default FileUpload;
