import logo from "../../assets/LandingPageImages/logo/logoWhite.png";
import langAr from "../../assets/LandingPageImages/lang/ar.png";
import iconDownload from "../../assets/LandingPageImages/icons/import.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LanguageSwitcher from "Components/Languages/LanguageSwitcher";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import appStore from "../../assets/LandingPageImages/icons/appstore.svg";
import googlePlay from "../../assets/LandingPageImages/icons/googleplay.svg";
const NavBar = () => {
  // FIXED TOP NAVBAR
  const { t } = useTranslation();
  const [isMenuFixed, setMenuFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      const scrollTop = window.scrollY;
      const shouldBeFixed = scrollTop > 100; // Change 100 to the scroll position where you want the menu to become fixed
      // Update state to trigger the fixed or unfixed state of the menu
      setMenuFixed(shouldBeFixed);
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
  }, []); // Runs only once on mount
  const token = useSelector((state) => state.auth.token);

  const [downloadApps, setdownloadApps] = useState(false);
  const downloadAppsDiv = () => {
    setdownloadApps(true);
  };

  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setdownloadApps(false);
    }
  });

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg position-fixed HomeNav top-0   z-3 w-100  ${
          isMenuFixed ? "menu-fixed" : ""
        }`}
      >
        <div className="nav-menu-bar container-fluid container-xl">
          <a className="navbar-brand" href="/">
            <img alt="logo" height="57px" src={logo} width="160px" />
          </a>
          <div className="main-left-nav-toggle d-flex align-items-center gap-3">
            <button
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-bs-target="#navbarSupportedContent"
              data-bs-toggle="collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="lang">
              <LanguageSwitcher />
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav gap-3 mb-lg-0 me-auto"></ul>
            <div className="main-nav-left d-flex align-items-center gap-3 btn-nav">
              <button className="btn-main-01" onClick={() => downloadAppsDiv()}>
                {t("download app")}
                <img alt="icon" height="24px" src={iconDownload} width="24px" />
              </button>
              <Link
                className="btn-main-01 btn-transparent-01"
                to={token ? "/home" : "/loginForm"}
              >
                {token ? t("control_panel") : t("login")}
              </Link>
              <div className="lang">
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </nav>
      {(() => {
        if (downloadApps) {
          return (
            <div className="downloadAppspopup">
              <button
                className="close-button"
                onClick={() => {
                  setdownloadApps(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <div>
                <h2>{t("Download the App")}</h2>
                <h3>
                  {t(
                    "Download the app now and take a step towards realizing your dream project"
                  )}
                </h3>
                <p className="text">
                  {t(
                    "Download the app now and benefit from all the advantages and services it offers. You will have the opportunity to access a wide range of professional contractors, experienced consultants, and trusted suppliers in the field of contracting and construction."
                  )}
                </p>

                <div className="download-app-images d-flex align-items-center gap-2 flex-wrap justify-content-center  mt-3">
                  <a
                    target="_blank"
                    className="btn-image-app"
                    href="https://apps.apple.com/us/app/build-in/id6499343075"
                  >
                    <img src={appStore} alt="img app" />
                  </a>
                  <a
                    target="_blank"
                    className="btn-image-app"
                    href="https://play.google.com/store/apps/details?id=com.syntech.buildin"
                  >
                    <img src={googlePlay} alt="img app" />
                  </a>
                </div>
              </div>
            </div>
          );
        }
      })()}
    </>
  );
};

export default NavBar;
