import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import iconHead from "../../assets/images/icons/note.svg";
import "./Terms.css";
import MainButton from "Components/Button/Button";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios";
import { useTranslation } from "react-i18next";
const TermsConditions = () => {
  const [content, setContent] = useState("");
  const {t,i18n}=useTranslation()
  useEffect(() => {

    axiosInstance.get("/privacy").then((res)=>{
      setContent(res.data.data.content)
    })
  }, [i18n.language]);
  return (
    <>
      <HelmetInfo titlePage={"Terms And Condtions"} />
      <main dir="ltr">
        <div className="terms-conditions padding-50">
          {/* ======= START CONTAINER ======= */}
          <div className="container">
            {/* ======= START HEADER TOP TERMS ======= */}
            <div className="header-top-terms d-flex align-items-center gap-3">
              {/* ======= START ICON ======= */}
              <div className="icon-header">
                <img
                  src={iconHead}
                  alt="icon"
                  className="object-fit-cover"
                  loading="lazy"
                />
              </div>
              {/* ======= END ICON ======= */}

              {/* ======= START INFO HEADER ICON ======= */}
              <div className="info-header-icon">
                <h1 className="title"> Terms And Condtions</h1>
                {/* <p className="text">آخر تحديث ..12 يناير 2024</p> */}
              </div>
              {/* ======= END INFO HEADER ICON ======= */}
            </div>
            {/* ======= END HEADER TOP TERMS ======= */}

            {/* ======= START CONTENT INFO  ======= */}
            <div className="content-info">
              {/* <h2 className="title">01 .الشروط</h2> */}
              <div className="info-text" dangerouslySetInnerHTML={{ __html: content }}>
            
              </div>
              {/* <MainButton
                textButton={"أوافق على الشروط والأحكام"}
                newClass={"btn-terms"}
                routeButton={"/otp"}
              /> */}
            </div>
            {/* ======= END CONTENT INFO  ========= */}
          </div>
          {/* ======= END CONTAINER ======= */}
        </div>
      </main>
    </>
  );
};

export default TermsConditions;
