import HeaderTop from "./HeaderTopNav/HeaderTop";
import WorkHeaderInfo from "./WorkHeaderInfo/WorkHeaderInfo";
import iconSearch from "../../assets/images/icons/search.svg";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useNavigate } from "react-router-dom";
const MapWithSearch = () => {
  const navigate = useNavigate();
  return (
    <>
      <HelmetInfo titlePage={"حدد موقع الشركة"} />

      <div className="work-information">
        {/* ========= START CONTAINER ========= */}
        <div className="container-fluid container-md">
          {/* ========= START ALL WORK INFORATION ========= */}
          <div className="all-work-information">
            <HeaderTop />

            {/* ============ START MAIN ========== */}
            <main>
              {/* ============ START ALL MAIN ========== */}
              <div className="all-main">
                {/* ========== START HEADER TOP ======= */}
                <WorkHeaderInfo
                  title={"حدد موقع الشركة"}
                  text={"من فضلك , قم بتحديد موقع الشركة لإضافة بيانات التواصل"}
                />
                {/* ========== END HEADER TOP ======= */}
                {/* ========== START SEARCH LOCATION ======= */}
                <div className="search-location my-5">
                  <form action="">
                    <div className="input-pass position-relative">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="إبحث عن موقعك"
                        required
                      />
                      <div className="icon-svg-local">
                        <img src={iconSearch} alt="icon location" />
                      </div>
                    </div>
                    <div className="map-location mt-5 mb-1">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.1222073449103!2d106.77590781537452!3d-6.2476228629146675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f11b12c11ab7%3A0xcd48f5c775249316!2sHumanity%20First%20Indonesia!5e0!3m2!1sid!2sid!4v1605684563677!5m2!1sid!2sid"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                        title="Humanity First Indonesia"
                      />
                    </div>
                    <button
                      className="btn-main w-100"
                      onClick={() => {
                        navigate("/finalCompleteData");
                      }}
                    >
                      حفظ الموقع
                    </button>
                  </form>
                </div>
                {/* ========== END SEARCH LOCATION ======= */}
              </div>
              {/* ============ START ALL MAIN ========== */}
            </main>
            {/* ============ END MAIN ========== */}
          </div>
          {/* ========= END ALL WORK INFORATION ========= */}
        </div>
        {/* ========= END CONTAINER ========= */}
      </div>
    </>
  );
};

export default MapWithSearch;
