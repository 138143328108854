import Logo from 'Components/Logo/Logo'
import { Link } from 'react-router-dom'
import LangAr from "../../../assets/images/lang/ar.png";

const HeaderTop = () => {
  return (
    <>
        {/* ============ START HEADER ========== */}
        <header>
        {/* ======== START HEADER TOP ======== */}
        <div className="header-top d-flex  justify-content-between  align-items-center  gap-2 py-3">
          <Logo />
          <div className="lang">
            <Link
              to={""}
              className="link-lang-flag d-flex  align-items-center  gap-2 "
            >
              <img
                src={LangAr}
                alt="lang ar"
                width={"32px"}
                height={"32px"}
                className="object-fit-cover"
              />
              <span className="text-lang">العربية</span>
            </Link>
          </div>
        </div>
        {/* ======== END HEADER TOP ======== */}
      </header>
      {/* ============ END HEADER ========== */}
    </>
  )
}

export default HeaderTop