import React from "react";

const WorkHeaderInfo = ({title,text}) => {
  return (
    <>
      <div className="work-header-info">
        <h2 className="title">{title}</h2>
        <p className="text">
        {text}
        </p>
      </div>
    </>
  );
};

export default WorkHeaderInfo;
