import iconDiam from "../../../assets/images/icons/diamond.svg";
import { ProgressBar } from "react-bootstrap";
import PackageInformationModal from "../PackageInformationModal/PackageInformationModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLottie } from "lottie-react";
import NoData from "../../../assets/NoDataFound.json";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import moment from "moment";
const PackageInformation = ({ subscription }) => {
  const progressCount = 70;

  // OEPN ANDCLOSE MODAL
  const [showModal, setShowModal] = useState(false);
  // CLOSE MODAL
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // OPEN MODAL
  const handleShowModal = () => {
    setShowModal(true);
  };

  const options = {
    animationData: NoData,
    loop: true,
    style: { width: "300px", height: "300px" },
  };
  const { View } = useLottie(options);
  const { t, i18n } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  const [difffDays, setDifffDays] = useState(0);
  useEffect(() => {
    const diffMilliseconds = moment().diff(moment(subscription?.start_date));

    // Convert the difference to days
    const diffDays = moment.duration(diffMilliseconds).asDays();
    setDifffDays(moment(subscription?.end_date).diff(moment(), "days"));
    // Total days in the period
    const totalDays = moment(subscription?.end_date).diff(
      moment(subscription?.start_date),
      "days"
    );
    console.log(totalDays, diffDays);
    // Calculate the percentage
    setPercentage((diffDays / totalDays) * 100);
  }, [subscription]);

  return (
    <>
      <PackageInformationModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      {/* =========== START CARD ONE OFFER ============ */}
      <div className="card-one-offer card-style">
        {/* ======== START HEADER CARD ========= */}
        <div className="header-card d-flex align-items-center  justify-content-between flex-wrap gap-3">
          <h2 className="title">{t("CurrentPackage")}</h2>
          {/* {subscription && (
            <div
              onClick={handleShowModal}
              className="btn-main btn-package margin-btn-top"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M7.30078 18.9801H16.7008C17.1208 18.9801 17.5908 18.6501 17.7308 18.2501L21.8708 6.67011C22.4608 5.01011 21.7708 4.50011 20.3508 5.52011L16.4508 8.31011C15.8008 8.76011 15.0608 8.53011 14.7808 7.80011L13.0208 3.11011C12.4608 1.61011 11.5308 1.61011 10.9708 3.11011L9.21078 7.80011C8.93078 8.53011 8.19078 8.76011 7.55078 8.31011L3.89078 5.70011C2.33078 4.58011 1.58078 5.15011 2.22078 6.96011L6.26078 18.2701C6.41078 18.6501 6.88078 18.9801 7.30078 18.9801Z"
                  stroke="#EDA600"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 22H6.5"
                  stroke="#EDA600"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.5 14H9.5"
                  stroke="#EDA600"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              قم بالإرتقاء للباقة الذهبية
            </div>
          )} */}
        </div>
        {/* ======== END HEADER CARD ========= */}
        {/* ======== START CARD DETAILS INFO ========== */}
        {subscription ? (
          <div className="card-details-info">
            <div className="info-content-card info-content-card-two d-flex  align-items-center  gap-3">
              <div className="img-diam">
                <img src={iconDiam} alt="imageCard" />
              </div>
              <div className="text-info">
                <h2 className="title"> {subscription?.package?.title}</h2>
              </div>
            </div>
            <div className="info-progress">
              <ProgressBar striped variant="success" now={percentage} />
              <div className="info-text-progress d-flex justify-content-between align-itmes-center">
                <h2 className="title"> {t("Subscribed since")} {subscription?.start_date}</h2>
                <h2 className="title">{difffDays} {t("left to renew ")}</h2>
              </div>
            </div>

            <div className="button-bottom d-flex w-100 gap-2">
              <Link to={"/subscriptions"} className="btn-main button-home">
              {t("Package details")} 
              </Link>
            </div>
          </div>
        ) : (
          <NoResults text={t("noSubsicretopins")}>
            <Link
              to={"/packagesCards"}
              className="btn-main"
              style={{ backgroundColor: "#002c63", color: "white" }}
            >
              {t("SubscribeNow")}
            </Link>
          </NoResults>
        )}
        {/* ======== END CARD DETAILS INFO ========== */}
      </div>
      {/* =========== END CARD ONE OFFER ============ */}
    </>
  );
};

export default PackageInformation;
