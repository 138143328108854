import ReactSelect from "react-select";

const CustomReactSelect = ({
  options,
  changeValue,
  placeholder,
  defaultValue,
  isClearable,
  isMulti
}) => {
  const customStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      maxHeight: "50px",
      minHeight: "50px !important",
    }),

    input: (styles) => ({
      ...styles,
      maxHeight: "38px",
      minHeight: "38px !important",
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({
      ...styles,
      maxHeight: "38px",
      minHeight: "38px !important",
      display: "flex",
      alignItems: "center",
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: "38px",
      minHeight: "38px !important",
    }),
  };
  return (
    <ReactSelect
      placeholder={placeholder}
      options={options}
      onChange={(e) => {
        changeValue(e);
        console.log(e);
      }}
      isMulti={isMulti}
      defaultValue={defaultValue}
      styles={customStyle}
      isClearable={isClearable}
      className=" mt-3"
    />
  );
};

export default CustomReactSelect;
