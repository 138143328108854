import imgDownload from "../../../assets/LandingPageImages/download/download01.gif";
import appStore from "../../../assets/LandingPageImages/icons/appstore.svg";
import googlePlay from "../../../assets/LandingPageImages/icons/googleplay.svg";

import "./ModalDownloadApp.css";
const ModalDownloadApp = () => {
  return (
    <>
      <>
        {/* ================== START MODAL DOWNLOAD APP ================ */}
        <div
          className="modal modal-download-app fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title" id="exampleModalLabel">
                  حمل التطبيق أو سجل الدخول
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                <div className="icon-download mb-3">
                  <img
                    src={imgDownload}
                    alt="icon download"
                    width="100px"
                    height="100px"
                  />
                </div>
                <h2 className="title">
                  برجاء تحميل التطبيق أو تسجيل الدخول لمزيد من التفاصيل
                </h2>
              </div>
              <div className="modal-footer justify-content-center">
                {/* ======= START APPS ====== */}
                <div className="apps d-flex align-items-center justify-content-center gap-3 flex-wrap">
                  {/* ====== START APP ONE ======= */}
                  <div className="app-one">
                    <a href="##">
                      <img src={appStore} alt="app store" />
                    </a>
                  </div>
                  {/* ====== END APP ONE ======= */}
                  {/* ====== START APP ONE ======= */}
                  <div className="app-one">
                    <a href="##">
                      <img src={googlePlay} alt="google play" />
                    </a>
                  </div>
                  {/* ====== END APP ONE ======= */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ================== END MODAL DOWNLOAD APP ================ */}
      </>
    </>
  );
};

export default ModalDownloadApp;
