import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const IconHeaderForm = ({routerPage, iconHeader}) => {
  return (
    <>
       {/* ========= START ICON CLOSE ========= */}
       <Link to={routerPage} className="icon-close">
       <Link to={routerPage} className="icon-close-page">
         <FontAwesomeIcon icon={iconHeader} />
       </Link>
     </Link>
     {/* ========= END ICON CLOSE ========= */}
    </>
  )
}

export default IconHeaderForm