import bannerImageAccount from "../../assets/images/main/bannerAccount.png";
import imageUser from "../../assets/images/user/userImage.png";
import iconEdit from "../../assets/images/icons/edit-2.svg";
import imageBannerUser from "../../assets/images/main/cardAccount.png";
import { Link } from "react-router-dom";
import { useRef } from "react";
import axiosInstance from "../../axios";
import { toast } from "react-toastify";
const BannerAccount = ({ Data, refetch }) => {
  const IconRef = useRef();
  const inputRef = useRef();
  const handleEdit = () => {
    inputRef.current.click();
  };

  const handleImage = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const updatedFiles = [];
      for (let i = 0; i < files.length; i++) {
        updatedFiles.push({
          file: files[i],
          name: files[i].name,
          type: files[i].type,
          preview: URL.createObjectURL(files[i]), // Create preview URL for images
        });
      }
      const formData = new FormData();
      formData.append("image", files[0]);

      axiosInstance
        .post("/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          toast.success("تم تغيير الصورة بنجاح");
          refetch();
        });
    }
  };
  return (
    <>
      {/* ========== START BANNER ACCOUNT =========== */}
      <div className="banner-account">
        <div className="banner-main-area">
          {/* ========= START BANNER ONE ======== */}
          <div
            className="banner-one section-padding bg-image"
            style={{ backgroundImage: `url(${bannerImageAccount})` }}
          >
            {/* ======== START INFO BANNER ========= */}
            <div className="info-banner position-relative z-1">
              {/* ======= START CONTAINER =========== */}
              <div className="container">
                {/* =========== START ROW ======== */}
                <div className="row g-3 align-items-center justify-content-between">
                  {/* ========== START COL ========== */}
                  <div className="col-12 col-md-4 col-lg-5">
                    {/* ========= START CONTENT SLIDE ========== */}
                    <div className="content-slide">
                      <div className="user-info">
                        {/* ======== START HEADER USER ========= */}
                        <div className="header-image">
                          <div className="img-user">
                            <img
                              src={Data?.image}
                              alt="img user"
                              className="object-fit-cover"
                            />
                          </div>
                          <div
                            className="icon-edit"
                            ref={IconRef}
                            onClick={handleEdit}
                          >
                            <img src={iconEdit} alt="icon edit" />
                            <input
                              type="file"
                              onChange={(e) => handleImage(e)}
                              ref={inputRef}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                        {/* ======== START HEADER USER ========= */}
                        <div className="name-user"> {Data?.name}</div>
                      </div>
                    </div>
                    {/* ========= END CONTENT SLIDE ============ */}
                  </div>
                  {/* ========== END COL ========== */}
                  {/* ========== START COL ========== */}
                  <div className="col-12 col-md-8 col-lg-7">
                    {/* ========= START CONTENT SLIDE ========== */}
                    <div className="content-slide">
                      {/* ========== START INFO LEFT ======== */}
                      {/* <div
                        className="info-left"
                        style={{ backgroundImage: `url(${imageBannerUser})` }}
                      >
                        <h2 className="title-head">إشترك في باقات BUILD IN</h2>
                        <h2 className="title-head">
                          إستفيد من جميع المميزات على المنصة
                        </h2>
                        <Link to={"/packagesCards"} className="btn-main">
                          تفاصيل الباقات
                        </Link>
                      </div> */}
                      {/* ========== END INFO LEFT ======== */}
                    </div>
                    {/* ========= END CONTENT SLIDE ============ */}
                  </div>
                  {/* ========== END COL ========== */}
                </div>
                {/* =========== END ROW ========== */}
              </div>
              {/* ======== END CONTAINER =========== */}
            </div>
            {/* ======== END INFO BANNER ========== */}
          </div>
          {/* ======== END BANNER ONE ========= */}
        </div>
      </div>
      {/* ========== END BANNER ACCOUNT =========== */}
    </>
  );
};

export default BannerAccount;
