import videoBg from "../../../assets/LandingPageImages/video/02.mp4";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

import "./VideoBannerCounter.css";
import { useTranslation } from "react-i18next";
const VideoBannerCounter = () => {
  const {t}=useTranslation()
  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px 200px 0px"
  });
  return (
    <>
      {/* ========= START SECTION BANNER COUNTER ========== */}
      <section className="banner-counter padding-50 position-relative">
        <div className="video-bg">
          <video
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            className="video-src"
            src={videoBg}
          ></video>
        </div>
        {/* =========== START CONTAINER ========== */}
        <div className="container">
          <div className="main-banner-counter">
            {/* ============ START ALL BANNER COUNTER =========== */}
            <div className="all-banner-counter position-relative  z-3">
              {/* =========== START ROW =========== */}
              <div className="row g-3">
                {/* ============= START COL ============= */}
                <div className="col-12 col-sm-6 col-lg-3 col-ele">
                  {/* ======== START COUNTER ONE ========= */}
                  <div className="counter-one d-flex justify-content-center align-items-center flex-column gap-1">
                    <div className="main-counter d-flex align-items-center flex-row-reverse gap-1">
                      <div className="text fw-bold " ref={ref}>
                        {inView && <CountUp end={12} duration={2} />}K
                      </div>
                    </div>
                    <p className="text-counter">{t("Contractor")}</p>
                  </div>
                  {/* ======== END COUNTER ONE ========= */}
                </div>
                {/* ============= END COL ============= */}
                {/* ============= START COL ============= */}
                <div className="col-12 col-sm-6 col-lg-3 col-ele">
                  {/* ======== START COUNTER ONE ========= */}
                  <div className="counter-one d-flex justify-content-center align-items-center flex-column gap-1">
                    <div className="main-counter d-flex align-items-center flex-row-reverse gap-1">
                      <div className="text fw-bold " ref={ref}>
                        {inView && <CountUp end={5} duration={2} />}K
                      </div>
                    </div>
                    <p className="text-counter"> {t("Completed Projects")}</p>
                  </div>
                  {/* ======== END COUNTER ONE ========= */}
                </div>
                {/* ============= END COL ============= */}
                {/* ============= START COL ============= */}
                <div className="col-12 col-sm-6 col-lg-3 col-ele">
                  {/* ======== START COUNTER ONE ========= */}
                  <div className="counter-one d-flex justify-content-center align-items-center flex-column gap-1">
                    <div className="main-counter d-flex align-items-center flex-row-reverse gap-1">
                      <div className="text fw-bold " ref={ref}>
                        {inView && <CountUp end={15} duration={2} />}+
                      </div>
                    </div>
                    <p className="text-counter">{t("Respected Clients")}</p>
                  </div>
                  {/* ======== END COUNTER ONE ========= */}
                </div>
                {/* ============= END COL ============= */}
                {/* ============= START COL ============= */}
                <div className="col-12 col-sm-6 col-lg-3 col-ele">
                  {/* ======== START COUNTER ONE ========= */}
                  <div className="counter-one d-flex justify-content-center align-items-center flex-column gap-1">
                    <div className="main-counter d-flex align-items-center flex-row-reverse gap-1">
                      <div className="text fw-bold " ref={ref}>
                        {inView && <CountUp end={20} duration={2} />}+
                      </div>
                    </div>
                    <p className="text-counter">{t("Consulting Company")}</p>
                  </div>
                  {/* ======== END COUNTER ONE ========= */}
                </div>
                {/* ============= END COL ============= */}
              </div>
              {/* =========== END ROW =========== */}
            </div>
            {/* ============ END ALL BANNER COUNTER =========== */}
          </div>
        </div>
        {/* =========== END CONTAINER ========== */}
      </section>
      {/* ========= END SECTION BANNER COUNTER ========== */}
    </>
  );
};

export default VideoBannerCounter;
