import React, { useEffect } from "react";
import * as Yup from "yup";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import { useState } from "react";
import ModalRequestAndAddProject from "Components/ModalRequestAndAddProject/ModalRequestAndAddProject";
import WorkHeaderInfo from "Pages/WorkInformation/WorkHeaderInfo/WorkHeaderInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCheckDouble,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
import CustomReactSelect from "Components/Forms/ReactSelect/ReactSelect";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import { useNavigate } from "react-router-dom";

const initialValues = {
  titleProject: "",
  numQuantity: "",
  textAreaDetails: "",
  textAreaRequiredProject: "",
  titleRegion: "",
};

const AddNewProject = () => {
  const { t, i18n } = useTranslation(); // Initialize the useTranslation hook
  const validationSchema = Yup.object().shape({
    titleProject: Yup.string().required(t("projectNameRequired")), // Translated validation message
    numQuantity: Yup.string().required(t("expectedValueRequired")), // Translated validation message
    textAreaDetails: Yup.string().required(t("projectDetailsRequired")), // Translated validation message
    textAreaRequiredProject: Yup.string().required(
      t("projectRequirementsRequired")
    ), // Translated validation message
  });
  // FORM SUBMIT
  const [date1Error, setDate1Error] = useState(false);
  const [date2Error, setDate2Error] = useState(false);
  const [date3Error, setDate3Error] = useState(false);

  const [category, setCategory] = useState();
  const [City, setCity] = useState();
  const [loading, setloading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [startDate2, setStartDate2] = useState();
  const [startDate3, setStartDate3] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesError, setSelectedFilesError] = useState(false);
  const [selectedFiles2, setSelectedFiles2] = useState([]);
  const [selectedFilesError2, setSelectedFilesError2] = useState(false);
  const [SelectedCategory, setSelectedCategory] = useState();
  const [SelectedCity, setSelectedCity] = useState();
  const [SelectedCategoryError, setSelectedCategoryError] = useState();
  const [SelectedCityError, setSelectedCityError] = useState();
  const [items, setItems] = useState(["contractor", "advisor", "provider"]); // Translated options
  const type = useSelector((state) => state.auth.type);

  useEffect(() => {
    axiosInstance
      .get("/category", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        const Options = res.data.data.map((item) => {
          return { value: item.id, label: item.title };
        });

        setCategory(Options);
      })
      .catch((error) => {
        toast.error(t("somethingWentWrong")); // Translated error message
      });
  }, [i18n.language]);

  useEffect(() => {
    axiosInstance
      .get("/city", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        const Options = res.data.data.map((item) => {
          return { value: item.id, label: item.title };
        });

        setCity(Options);
      })
      .catch((error) => {
        toast.error(t("somethingWentWrong")); // Translated error message
      });
  }, []);

  // OEPN AND CLOSE MODAL
  const [showModal, setShowModalSuccess] = useState(false);
  // CLOSE MODAL
  const router = useNavigate();
  const handleCloseModal = () => {
    setShowModalSuccess(false);
    router("/home");
  };
  // OPEN MODAL
  const handleOpenModalSuccess = () => {
    setShowModalSuccess(true);
  };

  // ADD CLASS ACTIVE
  const [activeItem, setActiveItem] = useState(0);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  useEffect(() => {
    if (type === "contractor") {
      setItems(["contractor", "advisor", "provider"]); // Translated options // add the three types
      setActiveItem("advisor"); // Translated default active item
    } else if (type === "advisor") {
      setItems(["contractor", "advisor", "provider"]); // Translated options
      setActiveItem("contractor"); // Translated default active item
    } else if (type === "provider") {
      setItems(["contractor", "advisor", "provider"]); // Translated options
      setActiveItem("contractor"); // Translated default active item
    } else {
      setItems(["contractor", "advisor", "provider"]); // Translated options
      setActiveItem("contractor"); // Translated default active item
    }
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    if (!SelectedCity) {
      setSelectedCityError(!SelectedCity);
      if (!SelectedCategory && activeItem == "provider") {
        setSelectedCategoryError(!SelectedCategory);
      }
      return;
    }

    const isEmpty = !values.titleProject && !values.textAreaDetails;

    if (isEmpty) {
    } else {
      const formData = new FormData();
      formData.append("title", values.titleProject);
      if (activeItem == "provider") {
        formData.append("category_id", SelectedCategory.value);
      }
      if (values.numQuantity !== "" || !values.numQuantity.includes("NaN")) {
        formData.append("price", values.numQuantity);
      }

      formData.append("content", values.textAreaDetails);
      formData.append("requirments", values.textAreaRequiredProject);

      formData.append("city_id", SelectedCity.value);
      formData.append("region", values.titleRegion);
      if (selectedFiles[0]?.file) {
        formData.append("images[0]", selectedFiles[0].file);
      }
      if (selectedFiles2[0]?.file) {
        formData.append("file", selectedFiles2[0].file);
      }

      formData.append(
        "type",
        activeItem === "contractor"
          ? "contractor"
          : activeItem === "advisor"
          ? "advisor"
          : "provider"
      );
      setloading(true);
      axiosInstance
        .post("/project", formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
        })
        .then((res) => {
          console.log(res.data);
          handleOpenModalSuccess();
          setloading(false);
          resetForm();
          setSelectedFiles(null);
          setSelectedFiles2(null);
        })
        .catch((error) => {
          let messages = [];
          console.log(error.response.data?.errors);
          for (let key in error.response.data?.errors) {
            messages.push(error.response.data?.errors[key][0]);
          }
          console.log(messages);
          setloading(false);

          messages.map((item) => {
            toast.error(t(item));
          });
          // Translated error message
        });
    }
  };

  return (
    <>
      <HelmetInfo titlePage={t("addNewProject")} />{" "}
      {/* Translated page title */}
      <ModalRequestAndAddProject
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="project-management-request all-main">
        <WorkHeaderInfo
          title={t("addNewProject")} // Translated section title
          text={t("addNewProjectDescription")} // Translated section description
        />
        {/* ========== START FORM PROJECT MANAGEMENT ========== */}
        <div className="form-project-management">
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <div className="row g-3">
              {/* ========= START COL ======== */}
              <div className="col-12 col-md-6">
                <InputFiled
                  label={t("projectName")} // Translated label
                  name="titleProject"
                  type="text"
                  placeholder=""
                  success
                />
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12 col-md-6">
                <InputFiled
                  label={t("expectedValue")} // Translated label
                  name="numQuantity"
                  price
                  type="text"
                  placeholder=""
                  success
                />
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}

              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12">
                <div className="all-users">
                  <h2 className="title-form-users">
                    {t("requiredJob")} <span className="error-star">*</span>
                  </h2>
                  <div className="row g-3">
                    {items.map((item, index) => (
                      <div
                        className={`col-12 ${
                          items.length == 3 ? "col-md-4" : "col-md-6"
                        }`}
                        key={index}
                      >
                        <div
                          className={`user-info-one ${
                            item == activeItem ? "active" : ""
                          }`}
                          onClick={() => handleItemClick(item)}
                        >
                          {t(item)}
                          {item === activeItem && (
                            <FontAwesomeIcon
                              icon={faCheckDouble}
                              className="check-icon"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {activeItem == "provider" && (
                <div className="col-12">
                  <CustomReactSelect
                    options={category}
                    placeholder={t("selectActivity")} // Translated placeholder
                    changeValue={setSelectedCategory}
                  />
                </div>
              )}
              {/* ========= END COL ======== */}

              {/* ========= START COL ======== */}
              <div className="col-12">
                <TextAreaInput
                  label=""
                  name="textAreaDetails"
                  type="text"
                  placeholder={t("projectDetails")} // Translated placeholder
                  success
                />
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12">
                <TextAreaInput
                  label=""
                  name="textAreaRequiredProject"
                  type="text"
                  placeholder={t("projectRequirements")} // Translated placeholder
                  success
                />
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12">
                <div className="all-calender mt-3">
                  <div className="row g-3"></div>
                </div>
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12">
                <CustomReactSelect
                  options={City}
                  placeholder={t("emirate")} // Translated placeholder
                  changeValue={setSelectedCity}
                />
              </div>
              {/* ========= END COL ======== */}
              {/* ========= START COL ======== */}
              <div className="col-12">
                <InputFiled
                  label={t("region")} // Translated label
                  name="titleRegion"
                  type="text"
                  placeholder=""
                  success
                />
              </div>
              {/* ========= END COL ======== */}

              {/* ========= START COL ======== */}
              <div className="col-12">
                <div className="file-upload-manage">
                  <FileUpload
                    title={t("projectImage")} // Translated title
                    text={"png/jpg"}
                    showText={false}
                    SelectedData={setSelectedFiles}
                    data={selectedFiles2}
                    enableReset={true}
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                {selectedFilesError && (
                  <div className="error">{t("projectImageRequired")}</div> // Translated error message
                )}
              </div>
              {/* ========= END COL ======== */}

              {/* ========= START COL ======== */}
              <div className="col-12">
                <div className="file-upload-manage ">
                  <FileUpload
                    title={t("attachProjectFile")} // Translated title
                    text={"pdf/doc/autocad"}
                    showText={false}
                    data={selectedFiles2}
                    enableReset={true}
                    SelectedData={setSelectedFiles2}
                  />
                </div>
                {selectedFilesError2 && (
                  <div className="error">{t("projectFileRequired")}</div> // Translated error message
                )}
              </div>
              {/* ========= END COL ======== */}

              <button className="btn-main w-100" type={"submit"}>
                {loading ? (
                  <span className="loader"></span>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faPlus} />
                    {t("addProject")}{" "}
                  </>
                )}

                {/* Translated button text */}
              </button>
            </div>
          </FormField>
        </div>
        {/* ========== END FORM PROJECT MANAGEMENT ========== */}
      </div>
    </>
  );
};

export default AddNewProject;
