// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./Home.css";
import HeaderTopHome from "./HeaderTop/HeaderTopHome";
import CardsInfo from "./CardsInfo/CardsInfo";
import BannerSlide from "./BannerSlide/BannerSlide";
import OffersPackages from "./OffersPackages/OffersPackages";
import Experts from "./Experts/Experts";
import RecentProjects from "./RecentProjects/RecentProjects";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import axiosInstance from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { setuseId } from "../../Redux/auth";
import { useTranslation } from "react-i18next";
const Home = () => {
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [projects, setProjects] = useState([]);
  const [Offers, setOffers] = useState([]);
  const [Slider, setSlider] = useState([]);

  const [percent, setPercent] = useState(0);
  const type = useSelector((state) => state.auth.type);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    axiosInstance
      .get("/web/home", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res.data.projects.length);
        // setLoading(false);
        setProviders(res.data.providers);
        setAdvisor(res.data.advisors);
        setContractor(res.data.contractors);
        setProjects(res.data.projects);
        setOffers(res.data.latest_offers);
        setSlider(res.data.slider);
        setData(res.data);
      });
  }, [i18n.language]);
  useEffect(() => {
    axiosInstance
      .get("/profile", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setPercent(res.data.data.percent);
        dispatch(setuseId(res.data.data.id));
      });
  }, []);
  return (
    <div>
      <HelmetInfo titlePage={"الصفحة الرئيسية"} />
      <main className="position-relative z-0 pt-3">
        {/* <HeaderTopHome percent={percent} /> */}
        <CardsInfo
          projectNumb={data?.mytotalProjects}
          offersnum={data?.mytotalOffers}
          views={data?.mytotalviews}
        />
        <BannerSlide data={Slider} />
        <OffersPackages data={Offers} subscription={data?.subscription} />
        <Experts
          advisor={advisor}
          providers={providers}
          contractor={contractor}
        />
        {
          type!="customer" && (        <RecentProjects projects={projects} />)
        }

      </main>
    </div>
  );
};

export default Home;
