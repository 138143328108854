import CustomModal from "Components/CustomModal/CustomModal";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import ReactFlagsSelect from "react-flags-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormField from "Components/Forms/FormComponent/FormFiled";
import InputFiled from "Components/Forms/InputForm/InputField";
import "./ModalAccount.css";
import ModalConfirmNumber from "./ModalConfirmNumber";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import CustomReactSelect from "Components/Forms/ReactSelect/ReactSelect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ChangePasswordModal = ({ showModalEdit, handleCloseModalEdit }) => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const type = useSelector((state) => state.auth.type);
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("EnterPassword"))
      .min(8, t("PasswordMinError")),
    password_confirmation: Yup.string()
      .required(t("EnterPassword"))
      .min(8, t("PasswordMinError")),
    old_password: Yup.string()
      .required(t("EnterPassword"))
      .min(8, t("PasswordMinError")),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    console.log(values);
    axiosInstance
      .post("/password", { ...values },{
        headers:{
            "Accept-Language": i18n.language
        }
      })
      .then((res) => {
        toast.success(t("ProfileUpdated"));

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.errors?.password[0]);
        toast.error(err.response?.data?.message);
        setLoading(false);
      });
  };

  const initialValues = {
    password: "",
    password_confirmation: "",

    old_password: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [password3, setPassword3] = useState("");

  const togglePasswordVisibility = (setShowPasswordparm) => {
    setShowPasswordparm(!showPassword);
  };

  const handlePasswordChange = (e, setPasswordparam) => {
    setPasswordparam(e.target.value);
  };

  const [showModalConfirmNumber, setShowModalConfirmNumber] = useState(false);

  const handleCloseModalConfirmNumber = () => {
    setShowModalConfirmNumber(false);
  };

  const handleShowModalConfirmNumber = () => {
    setShowModalConfirmNumber(true);
  };

  return (
    <>
      <ModalConfirmNumber
        showModalConfirmNumber={showModalConfirmNumber}
        handleCloseModalConfirmNumber={handleCloseModalConfirmNumber}
      />
      <CustomModal
        show={showModalEdit}
        onHide={handleCloseModalEdit}
        title={t("EditAccountInfo")}
        newClass={"modal-account"}
      >
        <div className="row g-3 align-items-center">
          <div className="col-12 col-md-12">
            <div className="info-form-login position-relative">
              <div className="all-form-log">
                <p className="text">{t("EnterAccountInfo")}</p>
                <FormField
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <div className="row g-3">
                    <div className="col-12 col-md-12">
                      <div className="input-pass position-relative">
                        <InputFiled
                          label={t("NewPasswordLabel")}
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder=""
                          success
                          value={password}
                          onChange={(e) => handlePasswordChange(e, setPassword)}
                        />

                        <span
                          className="icon-eye-button"
                          onClick={() =>
                            togglePasswordVisibility(setShowPassword)
                          }
                        >
                          {showPassword ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="input-pass position-relative">
                        <InputFiled
                          label={t("password_confirmation")}
                          name="password_confirmation"
                          type={showPassword2 ? "text" : "password"}
                          placeholder=""
                          success
                          value={password2}
                          onChange={(e) =>
                            handlePasswordChange(e, setPassword2)
                          }
                        />

                        <span
                          className="icon-eye-button"
                          onClick={() =>
                            togglePasswordVisibility(setShowPassword2)
                          }
                        >
                          {showPassword2 ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="input-pass position-relative">
                        <InputFiled
                          label={t("old_password")}
                          name="old_password"
                          type={showPassword3 ? "text" : "password"}
                          placeholder=""
                          success
                          value={password3}
                          onChange={(e) =>
                            handlePasswordChange(e, setPassword3)
                          }
                        />

                        <span
                          className="icon-eye-button"
                          onClick={() =>
                            togglePasswordVisibility(setShowPassword3)
                          }
                        >
                          {showPassword3 ? (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          ) : (
                            <FontAwesomeIcon icon={faEye} />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn-main w-100"
                    disabled={loading}
                  >
                    {loading ? <span className="loader"></span> : t("Edit")}
                  </button>
                </FormField>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default ChangePasswordModal;
