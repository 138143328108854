import React from "react";

const WorkSteps = ({addActiveStep1, addActiveStep2, addActiveStep3, activeText}) => {
  return (
    <>
      <div className="steps">
        <button className="step-one">
          <span className={`step-num ${addActiveStep1}`}>1</span>
          <span className="step-text step-text-one"> المعلومات الأساسية</span>
        </button>
        <div></div>
        <button className="step-one">
          <span className={`step-num ${addActiveStep2}`}>2</span>
          <span className="step-text step-text-one">معلومات العمل</span>
        </button>
        <div></div>
        <button className="step-one">
          <span className={`step-num ${addActiveStep3}`}>3</span>
          <span className={`step-text step-text-one ${activeText}`}>معلومات التواصل</span>
        </button>
      </div>
    </>
  );
};

export default WorkSteps;
