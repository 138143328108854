import NavBar from "../NavBar/NavBar";
import SliderHeader from "../SliderHeader/SliderHeader";

const Header = () => {
  return (
    <>
      <>
        {/* ================== START HEADER =============== */}
        <header id="home">
          {/* =============== START SECTION HEADER ============= */}
          <div className="header">
            {/* ========= START ALL HEADER =========== */}
            <div className="all-header">
              <NavBar />
              <SliderHeader />
            </div>
            {/* ========= END ALL HEADER =========== */}
          </div>
          {/* =============== END SECTION HEADER ============= */}
        </header>
        {/* ================== END HEADER =============== */}
      </>
    </>
  );
};

export default Header;
