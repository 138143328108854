import { RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/react-fontawesome";
import { HelmetProvider } from "react-helmet-async";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import "./assets/googleFont/tajawal/tajawal.css";
import routers from "Routes/Route";
import WindowLoader from "Components/WindowLoader/WindowLoader";
import ToastContainerApp from "Components/ToastContainerApp/ToastContainerApp";
import "./index.css";
import { LanguageProvider } from "Components/Languages/LanguageContext";
import { useEffect } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase";
import { toast } from "react-toastify";
import 'rsuite/dist/rsuite.min.css';
import "leaflet/dist/leaflet.css";
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet-defaulticon-compatibility';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
function App() {

  return (
    <div className={`app-container`}>
      <LanguageProvider>
        <Provider store={store}>
          <WindowLoader />
          <ToastContainerApp />
          <HelmetProvider>
            <RouterProvider router={routers} />
          </HelmetProvider>
        </Provider>

      </LanguageProvider>
    </div>
  );
}

export default App;
