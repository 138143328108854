import React from "react";
import { useField } from "formik";

const InputFiled =React.forwardRef(({ label, success, price, ...props },ref) => {
  const [field, meta, helpers] = useField(props);
  const isError = meta.touched && meta.error;
  const isSuccess = success && meta.touched && !meta.error;

  // Function to format the input value with commas
  const formatValueWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    let newValue = e.target.value;
    console.log(newValue.includes("NaN"),newValue)
 
    if (price) {

   
      if (e.target.value.length >= 4) {

        newValue = newValue.replace(/,/g, "");
        newValue = Number(newValue).toLocaleString();
      } else {
        newValue = Number(e.target.value).toLocaleString();
      }
      console.log(e.target.value);
      // Remove non-numeric characters except commas
      // helpers.setValue(newValue);
      // const options = { maximumFractionDigits: 2 };

      // const newValue = Intl.NumberFormat("en-US", options).format(e.target.value); // Remove non-numeric characters except commas
      console.log(newValue);
      if(newValue.includes("NaN"))
      {
       return  helpers.setValue(0);
      }
      // const newValue = e.target.value.replace(/[^\d,]/g, ""); // Remove non-numeric characters except commas
      helpers.setValue(newValue);
    } else helpers.setValue(newValue);
  };

  // Function to handle input blur (apply formatting)
  const handleInputBlur = (e) => {
    let newValue = e.target.value;
    if (price) {
      // Remove commas before setting the value
      newValue = newValue.replace(/,/g, "");
      newValue = formatValueWithCommas(newValue);
    }
    helpers.setValue(newValue);
  };

  return (
    <>
      <div
        className={`form-group input-field-info d-flex   mt-3 position-relative form-one  ${
          meta.touched && meta.error ? "has-error" : ""
        }`}
      >
        {props.name === "phone" ? (
          <div className="PhonePlaceHolder">+971</div>
        ) : null}

        <input
        ref={ref}
          {...field}
          {...props}
          value={field.value || ""} // Ensure value is set or use an empty string
          // ref={number}
          onChange={handleInputChange}
          style={{
            borderColor: isError ? "#dc3545" : isSuccess ? "green" : "",
          }}
          className={`input-field form-control ${
            meta.touched && meta.error ? "is-invalid" : ""
          } ${props.name === "phone" ? "phoneNumberInput" : ""}`}
          required
        />
        <label
          htmlFor={props.id || props.name}
          className={`form-label ${props.name==="phone"?"phoneNumberLabel":""}`}
        >
          {label}
        </label>
      </div>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
}
)


export default InputFiled;
