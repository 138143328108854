import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

import SliderHeader from "Components/LandingPageComponent/SliderHeader/SliderHeader";
import React from "react";
import { Header } from "rsuite";
import NavBar from "./SharingNavBar";
import InfoOwnerDetails from "Pages/ProductDetailsPage/InfoOwnerDetails";
import InfoSharingDetails from "./InfoSharingDetails";
import Footer from "./Footer";

function SharingProjectDetails() {
  return (
    <>
      <HelmetInfo titlePage={"BUILDIN"} />

      {/* ================== START HEADER =============== */}
      <header id="home">
        {/* =============== START SECTION HEADER ============= */}
        <div className="header">
          {/* ========= START ALL HEADER =========== */}
          <div className="all-header">
            <NavBar />
          </div>
          {/* ========= END ALL HEADER =========== */}
        </div>
        {/* =============== END SECTION HEADER ============= */}
      </header>
      {/* ================== END HEADER =============== */}
      <div style={{ padding: "100px 0" }} className="ttt">
        <InfoSharingDetails />
      </div>
      <Footer />
    </>
  );
}

export default SharingProjectDetails;
// <Footer />
