import {
  faChevronDown,
  faChevronUp,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import "./SideBarFilter.css";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios";
import CustomReactSelect from "Components/Forms/ReactSelect/ReactSelect";
const SideBarFilter = ({
  isActive,
  setTypefilter,
  typefilter,
  searchContractorsByName,
  setSelectedCityes,
  setTimeFilter,
  timeFitler,
  showProviders,
  setSelectedCategory
}) => {
  // DROPMENU FILTER
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // IS OPEN TWO
  const [isOpenTwo, setIsOpenTwo] = useState(false);
  const toggleDropdownTwo = () => {
    setIsOpenTwo(!isOpenTwo);
  };

  //   IS OPEN THREE
  const [isOpenThree, setIsOpenThree] = useState(false);

  const toggleDropdownThree = () => {
    setIsOpenThree(!isOpenThree);
  };

  //   IS OPEN Four
  const [isOpenFour, setIsOpenFour] = useState(false);

  const toggleDropdownFour = () => {
    setIsOpenFour(!isOpenFour);
  };
  const [cityes, setCityes] = useState([]);
  const [category, setCategory] = useState([]);
  const { t, i18n } = useTranslation();
  const textCheckBox = [
    { id: "def1", text: t("building") },
    { id: "def2", text: t("civilEngineering") },
    { id: "def3", text: t("siteBeautification") },
  ];

  const dateTimeCheckBox = [
    { id: "defDate1", text: t("lastDay") },
    { id: "defDate2", text: t("last3Days") },
    { id: "defDate3", text: t("last7Days") },
  ];

  const typeExpert = [
    { id: "contractors", text: t("contractor") },
    { id: "advisors", text: t("advisor") },
    { id: "providers", text: t("provider") },
  ];
  useEffect(() => {
    axiosInstance
      .get("/city", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        const data = res.data.data.map((item, index) => {
          return { value: item.id, label: item.title };
        });
        setCityes(data);
      });

    axiosInstance
      .get("/category",{headers: {

        "Accept-Language": i18n.language,
      }})
      .then((res) => {
        const data = res.data.data.map((item, index) => {
          return { value: item.id, label: item.title };
        });
        setCategory(data);
      })
      .catch((err) => {});
  }, [ i18n.language]);
  return (
    <>
      <div className="all-filter-products">
        {/* ======== START  SEARCH INPUT ========= */}
        <div className="search-filter">
          <input
            type="search"
            className="form-control"
            placeholder={t("searchHere")}
            onChange={(e) => searchContractorsByName(e.target.value)}
          />
          <div className="icon-search">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                fill="#B0B0B0"
              />
              <path
                d="M21.9995 22.7499C21.8095 22.7499 21.6195 22.6799 21.4695 22.5299L19.4695 20.5299C19.1795 20.2399 19.1795 19.7599 19.4695 19.4699C19.7595 19.1799 20.2395 19.1799 20.5295 19.4699L22.5295 21.4699C22.8195 21.7599 22.8195 22.2399 22.5295 22.5299C22.3795 22.6799 22.1895 22.7499 21.9995 22.7499Z"
                fill="#B0B0B0"
              />
            </svg>
          </div>
        </div>
        {/* ======== END SEARCH INPUT ========= */}
        {/* ======== START SELECT FILTER ========= */}
        <div className="select-filter mt-3">
          <CustomReactSelect
            placeholder={t("emirate")}
            changeValue={setSelectedCityes}
            options={cityes}
            isClearable
          />
        </div>
        {/* ======== END SELECT FILTER ========= */}
        {isActive && (
          <div>
            {" "}
            {/* ======== START DROPMENU ========= */}
            <div className="dropdown-container">
              <button className="dropdown-btn" onClick={toggleDropdownThree}>
                {t("expertType")}
                {isOpenThree === false ? (
                  <div className="icon-arrow">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                ) : (
                  <div className="icon-arrow">
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                )}
              </button>

              <ul className={`dropdown-menu-1 ${isOpenThree ? "active" : ""}`}>
                {typeExpert.map((typeExpert) => {
                  return (
                    <li className="list-check-filter" key={typeExpert.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => {
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              setTypefilter([...typefilter, typeExpert.id]);
                            } else {
                              setTypefilter(
                                typefilter.filter(
                                  (item) => item !== typeExpert.id
                                )
                              );
                            }
                          }}
                          checked={typefilter.includes(typeExpert.id)}
                          id={typeExpert.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={typeExpert.id}
                        >
                          {typeExpert.text}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* ======== END DROPMENU ========= */}
          </div>
        )}

        {showProviders && <CustomReactSelect 
        changeValue={setSelectedCategory}
        placeholder={t("activity")}
isClearable={true}
        options={category} />}
        {/* ======== START DROPMENU ========= */}
        {/* <div className="dropdown-container">
          <button className="dropdown-btn" onClick={toggleDropdown}>
            {t("category")}
            {isOpen === false ? (
              <div className="icon-arrow">
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            ) : (
              <div className="icon-arrow">
                <FontAwesomeIcon icon={faChevronUp} />
              </div>
            )}
          </button>

          <ul className={`dropdown-menu-1 ${isOpen ? "active" : ""}`}>
            {textCheckBox.map((itemCheck) => {
              return (
                <li className="list-check-filter" key={itemCheck.id}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id={itemCheck.id}
                    />
                    <label className="form-check-label" htmlFor={itemCheck.id}>
                      {itemCheck.text}
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </div> */}
        {/* ======== END DROPMENU ========= */}

        {/* ======== START DROPMENU ========= */}
        {timeFitler && (
          <CustomReactSelect
            changeValue={setTimeFilter}
            isClearable
            placeholder={t("time")}
            options={[
              { value: "lastDay", label: t("lastDay") },
              { value: "last3Days", label: t("last3Days") },
              { value: "last7Days", label: t("last7Days") },
            ]}
          />
        )}
        {/* ======== END DROPMENU ========= */}
        {/* {isActive && (
          <div>
            {" "}
       
            <div className="dropdown-container">
              <button className="dropdown-btn" onClick={toggleDropdownFour}>
                {t("time")}
                {isOpenFour === false ? (
                  <div className="icon-arrow">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                ) : (
                  <div className="icon-arrow">
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                )}
              </button>

              <ul className={`dropdown-menu-1 ${isOpenFour ? "active" : ""}`}>
                <li className="list-check-filter">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="rate-1"
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-1"
                      htmlFor="rate-1"
                    >
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    </label>
                  </div>
                </li>
                <li className="list-check-filter">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="rate-2"
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-1"
                      htmlFor="rate-2"
                    >
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    </label>
                  </div>
                </li>
                <li className="list-check-filter">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="rate-3"
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-1"
                      htmlFor="rate-3"
                    >
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    </label>
                  </div>
                </li>
                <li className="list-check-filter">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="rate-4"
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-1"
                      htmlFor="rate-4"
                    >
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    </label>
                  </div>
                </li>
                <li className="list-check-filter">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="rate-5"
                    />
                    <label
                      className="form-check-label d-flex align-items-center gap-1"
                      htmlFor="rate-5"
                    >
                      <div className="icon-rate">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>{" "}
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                      <div className="icon-rate icon-rate-none">
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
   
          </div>
        )} */}
      </div>
    </>
  );
};

export default SideBarFilter;
