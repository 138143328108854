import React from "react";

const CardInfoDetails = ({
  image_1,
  newClass,
  titleCard,
  NumCounter,
  iconCounter,
  NumK,
  badgeInfo,
  numBadge,
  onClick
}) => {
  return (
    <>
      <div onClick={onClick} className={`card-info-one position-relative  ${newClass}`}>
        <div className="image-card">
          <img src={image_1} alt="icon" className="object-fit-cover" />
          {badgeInfo && <span className="badge-num">{numBadge}</span>}
        </div>
        <div className="info-card">
          <h2 className="title">{titleCard}</h2>
          <p className="num-counter">
            {NumCounter}
            {NumK && <span>k</span>}
            {iconCounter}
          </p>
        </div>
      </div>
    </>
  );
};

export default CardInfoDetails;
