import React from "react";
import WorkHeaderInfo from "../WorkHeaderInfo/WorkHeaderInfo";

const WorkComponent = () => {
  return (
    <>
      <WorkHeaderInfo
        title={"إستكمال البيانات"}
        text={"من فضلك , قم بإستكمال البيانات لتتكمن من إرسال وإستقبال العروض"}
      />
    </>
  );
};

export default WorkComponent;
