import React from "react";
import SuccessData from "./SuccessData";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";

const SuccessData2 = () => {
  return (
    <>
      <HelmetInfo titlePage={"تهانينا ! تم إنشاء حساب بنجاح !"} />
      <div className="all-success-data">
        <SuccessData showButton={true} />
      </div>
    </>
  );
};

export default SuccessData2;
