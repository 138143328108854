import { Link } from "react-router-dom";

import "react-circular-progressbar/dist/styles.css";
import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";
import image from "../../../assets/images/main/image1.png";
import { useEffect, useState } from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import { ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ModalEditInfoAccount from "Pages/MyAccount/ModalAccount/ModalEditInfoAccount";
import ModalEditWorkAccount from "Pages/MyAccount/ModalAccount/ModalEditWorkAccount";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ChangePasswordModal from "Pages/MyAccount/ModalAccount/ChangePasswordModal";
import ProtfolioEditModal from "Pages/MyAccount/ModalAccount/ProtoflioEdit";

const HeaderTopHome = ({ percent, data, refetch, editPassword }) => {
  // const props = {
  //   // is require
  //   colorSlice: "#002c63",
  //   colorCircle: "#002c63",
  //   fontColor: "#002c63",
  //   fontSize: "1.6rem",
  //   fontWeight: 400,
  //   size: 100,
  //   stroke: 10,
  //   strokeBottom: 5,
  //   speed: 60,
  //   cut: 0,
  //   rotation: -90,
  //   fill: "#fff",
  //   unit: "%",
  //   textPosition: "0.35em",
  //   animationOff: false,
  //   strokeDasharray: "10,1",
  //   inverse: false,
  //   round: false,
  //   number: false,
  //   styles: {
  //     borderRadius: "50%",
  //     boxShadow: "inset 0 0 25px 10px #a2caff",
  //     backGround: "#002c63",
  //   },
  //   value: 30,
  //   percent: 30,
  // };

  // OEPN ANDCLOSE MODAL
  const [showModal, setShowModal] = useState(false);
  const [showModalPR, setShowModalPR] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const type = useSelector((state) => state.auth.type);

  const progressCount = 70;

  // OEPN ANDCLOSE MODAL EDIT INFO ACCOUNT
  const [showModalEdit, setShowModalEdit] = useState(false);
  // CLOSE MODAL
  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };
  // OPEN MODAL
  const handleShowModalEdit = () => {
    setShowModalEdit(true);
    handleCloseModal();
  };

  // OEPN ANDCLOSE MODAL EDIT INFO WORK
  const [showModalEditWork, setShowModalEditWork] = useState(false);
  // CLOSE MODAL
  const handleCloseModalEditWork = () => {
    setShowModalEditWork(false);
  };
  // OPEN MODAL
  const handleShowModalWork = () => {
    setShowModalEditWork(true);
    handleCloseModal();
  };
  const [passowrdModal, setPassowrdModal] = useState(false);
  const handleShowModalPassword = () => {
    setPassowrdModal(false);
  };
  const { t, i18n } = useTranslation();

  return (
    <>
      <ModalEditInfoAccount
        showModalEdit={showModalEdit}
        data={data}
        refetch={refetch}
        handleCloseModalEdit={handleCloseModalEdit}
      />
      <ModalEditWorkAccount
        showModalEditWork={showModalEditWork}
        handleCloseModalEditWork={handleCloseModalEditWork}
        data={data}
      />
      <ChangePasswordModal
        showModalEdit={passowrdModal}
        handleCloseModalEdit={handleShowModalPassword}
      />

      <div className="header-top-home">
        <div className="all-info-header d-flex justify-content-between flex-wrap gap-3 align-items-center ">
          <div className="info-content d-flex  align-items-center  gap-3">
            <div className="progress-info position-relative ">
              <CircularProgressBar
                size={100}
                stroke={10}
                colorCircle={"#D6D6D6"}
                colorSlice={"#002c63"}
                strokeDasharray={"10,1"}
                percent={data?.percent}
              >
                <div className="image-progress">
                  <img
                    src={data?.image}
                    style={{
                      width: "80px",
                      borderRadius: "50%",
                    }}
                    className="object-fit-cover"
                    alt="Random"
                  />
                </div>
              </CircularProgressBar>
            </div>
            <div className="info-text">
              <h2 className="text">
                {i18n.language == "ar"
                  ? data?.company_name_ar
                  : data?.company_name_en}
              </h2>
              <h4 className="text">{t(`${data?.type}`)}</h4>
              <div className="text d-flex">
                {data?.type == "provider"
                  ? data?.categories.map((e, i) => {
                      return (
                        <>
                          <h4 className="text p-1" key={i}>
                            {i18n.language == "ar"
                              ? e.translations[0].title
                              : e.translations[1].title}
                          </h4>
                        </>
                      );
                    })
                  : null}
              </div>
              <h2 className="title">
                {t("completPercent")} {data?.percent}%
              </h2>
            </div>
          </div>

          <div className="d-flex gap-2">
            <Link
              to=""
              onClick={type != "customer" ? handleShowModal : setShowModalEdit}
              className="btn-main button-home margin-btn-top"
            >
              {t("account_information")}
            </Link>

            {editPassword && (
              <Link
                to=""
                onClick={() => setPassowrdModal(true)}
                className="btn-main button-home margin-btn-top"
              >
                {t("changePassword")}
              </Link>
            )}

            {/* <Link
                to=""
                onClick={() => setShowModalPR(true)}
                className="btn-main button-home margin-btn-top"
              >
                {t("Portfolio")}
              </Link> */}
          </div>
        </div>
      </div>
      {/* -========== START MODAL ============ */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={t("complete_profile_title")}
        newClass={"card-details-info modalAccount"}
      >
        <p className="text">
          {t(
            "Complete your file information at least 95% to be able to submit an offer"
          )}
        </p>
        <div className="info-progress">
          <ProgressBar striped variant="success" now={data?.percent} />
          <div className="info-text-progress">
            <h2 className="num-per">{data?.percent}%</h2>
          </div>
        </div>
        <div className="main-info-links mt-3 d-flex flex-column  gap-3">
          <div
            onClick={handleShowModalEdit}
            className="link-info-one d-flex  justify-content-between  align-items-center "
          >
            <div className="info-link-one d-flex  align-items-center gap-2">
              <div className="icon-check-link">
                <FontAwesomeIcon icon={faCheckDouble} />
              </div>
              <h2 className="text">{t("basic_information")} </h2>
            </div>
            <div className="arrow-ch">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </div>
          <div
            onClick={handleShowModalWork}
            className="link-info-one d-flex  justify-content-between  align-items-center "
          >
            <div className="info-link-one d-flex  align-items-center gap-2">
              <div className="icon-check-link">
                <FontAwesomeIcon icon={faCheckDouble} />
              </div>
              <h2 className="text">{t("work_information")} </h2>
            </div>
            <div className="arrow-ch">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </div>
        </div>
      </CustomModal>
      {/* -========== END MODAL ============ */}
    </>
  );
};

export default HeaderTopHome;
