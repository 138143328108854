import CustomModal from "Components/CustomModal/CustomModal";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import FormEditWork from "./FormEditWork";
import SetLocationTab from "./SetLocationTab";
import ModalSaveLocation from "./ModalSaveLocation";
import FileUpload from "Pages/WorkInformation/FileUpload/FileUpload";
import { toast } from "react-toastify";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const SubscribeModal = ({ ShowModal, handleCloseModalEditWork, duration ,price,HandleAmount}) => {
  const [loading, setLoaidng] = useState(false);

  const { t,i18n } = useTranslation();
  const [showModalSaveLocation, setShowModalSaveLocation] = useState(false);
  const [activeTab, setActiveTab] = useState("wallet");
  // const [CUrrentPrice, setCUrrentPrice] = useState(ShowModal[duration])
  const handleSubscribe =  () => {

    setLoaidng(true);
    if(
      activeTab=="wallet"
    )
    {
      axiosInstance
      .post("/subscription", {
        package_id: ShowModal.id,
        duration: duration,
        payment_method: activeTab
      },
    {
      headers:{
        "Accept-Language": i18n.language,
      }
    })
      .then((res) => {
        toast.success(t("Subscribed successfully"));
        setLoaidng(false);
        handleCloseModalEditWork();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoaidng(false);
      });
    }
    else {
      HandleAmount(price,setLoaidng)
    
    }

  }

  return (
    <CustomModal
      show={ShowModal}
      onHide={handleCloseModalEditWork}
      title={t("Package Subscription")}
      newClass={"modal-account modal-account-tabs"}
    >
      <div className="form-upload">
      <h2 style={{fontSize:'24px',paddingInlineStart:'10px'}}>
  {t("Payment Method")}
</h2>
<div className="row mb-3">


<div className="col-6">
  <div onClick={()=>setActiveTab("wallet")} className={`PaymentMethod ${activeTab=="wallet" && "active"}`}>
{t("Wallet")}
  </div>
</div>
<div className="col-6">
<div onClick={()=>setActiveTab("stripe")} className={`PaymentMethod ${activeTab=="stripe" && "active"}`}>
{t("Visa")}
  </div>
</div>


</div>
        <div>
          <div className="d-flex gap-2 dataRowSub">
            <label>{t("package Name")}</label>: {ShowModal?.title}
          </div>
          <div className="d-flex gap-2 dataRowSub">
            <label>{t("package duration")}</label>: {duration}
          </div>
          <div className="d-flex gap-2 dataRowSub">
            <label>{t("package Price")}</label>: {price}
          </div>
        </div>

        <button onClick={handleSubscribe} disabled={loading} className="submit-upload w-100 btn-main">
          {loading ? <span className="loader"></span> : t("next")}
        </button>
      </div>
    </CustomModal>
  );
};

export default SubscribeModal;
