import { Link } from "react-router-dom";
import image from "../../../assets/images/main/image1.png";
import iconCurrancy from "../../../assets/images/icons/dollar-circle.svg";
import CardOffer from "./CardOffer";
import PackageInformation from "./PackageInformation";
import { useTranslation } from "react-i18next";
import NoResults from "Components/NotFound/noResults";
import { useSelector } from "react-redux";
function addCommas(number) {
  // Convert the number to a string
  let numStr = number?.toString();

  // Use a regular expression to insert commas every three digits
  numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numStr;
}
const OffersPackages = ({ Offers, subscription }) => {
  const { t } = useTranslation();
const userType = useSelector((state) => state.auth.type);
  return (
    <div className="offers-packages">
      {/* ============== START ROW ========= */}
      <div className="row g-3">
        {/* ========== START COL =========== */}
        <div className={userType=="customer" ? "col-12 col-md-12" :"col-12 col-md-6"}>
          {/* =========== START CARD ONE OFFER ============ */}
          <div className="card-one-offer card-offer-01 card-style">
            {/* ======== START HEADER CARD ========= */}
            <div className="header-card d-flex align-items-center  justify-content-between flex-wrap">
              <h2 className="title">{t("latestOffersTitle")}</h2>
              {Offers?.length > 0 && (
                <Link to={"/Offers"} className="text">
                  {t("viewAll")}
                </Link>
              )}
            </div>
            {/* ======== END HEADER CARD ========= */}
            {/* ======== START CARD DETAILS INFO ========== */}
            {Offers?.length > 0 ? (
              <CardOffer
                image={image}
                imageCardAlt={t("imageCardAlt")}
                titleCard={"سينتك للمقاولات والبناء"}
                textCard={"مبنى سكني مكون من 10 أدوار"}
                textCardTrue={true}
                cardTwoInfo={false}
                iconCurrancy={iconCurrancy}
                iconAlt={t("iconCurrancyAlt")}
                priceNum={Offers[0]?.price}
                typePrice={t("currency")}
                textPrice={t("price")}
                textButtonOne={t("accept")}
                textButtonTwo={t("offerDetails")}
                buttonTwoActive={true}
                buttonThreeActive={undefined}
                rateNum={"4.8"}
              />
            ) : (
              <NoResults text={"noOffers found"}>
                <Link to={"/addNewProject"} className="btn-main">
                  {t("addNewProject")}
                </Link>
              </NoResults>
            )}{" "}
            {/* ======== END CARD DETAILS INFO ========== */}
          </div>
          {/* =========== END CARD ONE OFFER ============ */}
        </div>
        {/* ========== END COL =========== */}
        {/* ========== START COL =========== */}
        {
          userType!="customer" ?   <div className="col-12 col-md-6">
          <PackageInformation subscription={subscription} />
        </div>:
        null
        }
     
        {/* ========== END COL =========== */}
      </div>
      {/* ============== END ROW ========= */}
    </div>
  );
};

export default OffersPackages;
