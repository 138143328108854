import React from 'react'

const HeaderForm = ({titleHeaderForm,textHeaderForm}) => {
  return (
    <>
       {/* ========== START HEADER FORM LOG ========= */}
       <div className="header-form-log">
       <h2 className="title">{titleHeaderForm}</h2>
       <p className="text">{textHeaderForm}</p>
     </div>
     {/* ========== END HEADER FORM LOG ========= */}
    </>
  )
}

export default HeaderForm