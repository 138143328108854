import React from "react";
import BottomForm from "../InfoBottomForm/BottomForm";
import { useTranslation } from "react-i18next";

const LoginBottomInfo = () => {
  const {t}=useTranslation()
  return (
    <div>
      <BottomForm
        textBottomForm={t("bottomFormText")}
        routePage={"/loginStepOne"}
        linkTextBottomForm={t("linkText")}
      />
    </div>
  );
};

export default LoginBottomInfo;
