// LoginInput.js
import React from "react";
import { useField } from "formik";

const TextAreaInput = ({ label, success, ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;
  const isSuccess = success && meta.touched && !meta.error;

  return (
    <div
      className={`input-field-info d-flex flex-column gap-1 mt-3 form-one  ${meta.touched &&
      meta.error
        ? "is-invalid"
        : ""}`}
    >
      <label htmlFor={props.id || props.name} className="form-label">
        {label}
      </label>
      <textarea
        {...field}
        {...props}
        style={{
          borderColor: isError ? "#dc3545" : isSuccess ? "green" : "",
          height: "160px"
          // Add other styles as needed
        }}
        className={`input-field form-control ${meta.touched && meta.error
          ? "is-invalid"
          : ""}`}
      />

      {meta.touched && meta.error
        ? <div className="error">
            {meta.error}
          </div>
        : null}
    </div>
  );
};

export default TextAreaInput;
