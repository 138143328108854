import CustomModal from "Components/CustomModal/CustomModal";
import "./ModalStopOffer.css";
import { Rate } from "rsuite";
import TextAreaInput from "Components/Forms/TextAreaForm/TextArea";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axios";
import { useState } from "react";
import { toast } from "react-toastify";

const ModalRate = ({
  showModalStopOffer,
  handleCloseStopOffer,
  titleHeaderModal,
  textModalStop,
  titleButtonCancel,
  titleButtonBack,
  onHide,
  projectId,
  refetch,
}) => {
  const [rate, setRate] = useState(1);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const { t, i18n } = useTranslation();
  const handleSendRate = () => {
    setLoading(true);
    axiosInstance
      .post(`/rate`, {
        rate: rate,
        comment: comment,
        project_id: projectId,
      })
      .then((res) => {
        onHide(false);
        setLoading(false);
        refetch();
        toast.success(t("Rate has been sent successfully"));
      });
  };

  return (
    <>
      {/* ======== START MODAL ======== */}
      <CustomModal
        show={showModalStopOffer}
        onHide={() => onHide(false)}
        title={titleHeaderModal}
        newClass={"modal-stop"}
      >
        <div className="info-modal-stop">
          <div className="owner-project-details">
            {" "}
            <h2 className="title">{t("rateDetails")}</h2>
          </div>

          <Rate defaultValue={1} color="yellow" onChange={(e) => setRate(e)} />
          <div
            className={`input-field-info d-flex flex-column gap-1 mt-3 form-one  `}
          >
            <textarea
              placeholder={t("comment")}
              style={{
                height: "160px",
                // Add other styles as needed
              }}
              onChange={(e) => setComment(e.target.value)}
              className={`input-field form-control`}
            />
          </div>
          <div className="buttons-data rate d-flex align-items-center gap-2">
            <button
              onClick={handleSendRate}
              className="btn-main"
              style={{ background: "#002c63 !important" }}
            >
              {loading ? <span className="loader"></span> : t("RateHeader")}
            </button>

            <button
              onClick={handleCloseStopOffer}
              className="btn-main btn-back"
            >
              {titleButtonBack}
            </button>
          </div>
        </div>
      </CustomModal>
      {/* ======== END MODAL ======== */}
    </>
  );
};

export default ModalRate;
