import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./HeaderPages.css";
import { useTranslation } from "react-i18next";
const HeaderPages = ({
  titlePage,
  routePage,
  mainTitlePage,
  showMainTitlePage
}) => {
  const {t}=useTranslation();
  return (
    <div className="header-pages">
      {/* ======== START HEADER PAGE INFO ========= */}
      <div className="header-page-info d-flex  align-items-center">
        <Link to={"/"} className="link-page">
        {
          t("home")
        }
        </Link>
        <span className="icon-arrow">
          <FontAwesomeIcon icon={faChevronLeft} />
        </span>
        {showMainTitlePage && (
          <div className="mainTitle-page d-flex align-items-center ">
            <Link to={`${routePage}`} className="link-page">
              {mainTitlePage}
            </Link>

            <span className="icon-arrow">
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
          </div>
        )}

        <h2 className="title-page">{titlePage}</h2>
      </div>
      {/* ======== END HEADER PAGE INFO ========= */}
    </div>
  );
};

export default HeaderPages;
